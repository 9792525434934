import React, { FC, useState, Fragment, KeyboardEvent } from "react"
import { useQuery } from "react-query"

import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import CloseIcon from "@mui/icons-material/Close"
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart"
import TableViewIcon from "@mui/icons-material/TableView"
import { Grid, Paper, Card, Dialog, Button } from "@mui/material"

import { Skeleton, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import moment from "moment"

import { VisionAPI } from "../../../API/VisionAPI"
import ButtonSwitch from "../../../components/Buttons/ButtonSwitch"
import PaginatedBarGraph from "../../../components/graphs/PaginatedBarGraph"
import { definitions } from "../../../types/Generated/apiTypes"
import { processCarVisitData } from "../../../utils/carsUtils"
import CarsDetailsTable from "./CarsDetailsTable"

import styles from "./CarsDetails.module.scss"

type CarVisit = definitions["CarVisit"]
type CarLogsResults = definitions["CarCounterLogRetrieve"]

interface Props {
  car?: CarLogsResults
  open: boolean
  logsCount?: number
  selectedIndex: number
  handleClose: () => void
  handleChangeSelectedIndex: (index: number) => void
  handleFetchDetailsPage: (direction?: "forward" | "backward") => void
  hasMore?: boolean
  hasPrevious?: boolean
  isLoadingPage?: boolean
}

const CarsDetails: FC<Props> = ({
  car,
  open,
  logsCount,
  selectedIndex,
  hasMore,
  hasPrevious,
  isLoadingPage,
  handleChangeSelectedIndex,
  handleFetchDetailsPage,
  handleClose,
}) => {
  const [activeTab, setActiveTab] = useState(0)

  const { data: carData = [], isFetching: isCarDetailsLoading } = useQuery<CarVisit[], AxiosError>(
    ["fetchCarLicensePlate", car?.license_plate_data],
    ({ queryKey }) => VisionAPI.fetchCarLicensePlate({ license_plate: queryKey[1] as string }),
    {
      keepPreviousData: true,
      enabled: !!car?.license_plate_data,
    }
  )

  const isLoading = isCarDetailsLoading || isLoadingPage
  const barGraphData = processCarVisitData(carData)

  const handleNavigateForward = () => {
    if (selectedIndex + 1 === logsCount) {
      handleFetchDetailsPage("forward")
    } else {
      handleChangeSelectedIndex(selectedIndex + 1)
    }
  }

  const handleNavigateBackward = () => {
    if (selectedIndex === 0) {
      handleFetchDetailsPage("backward")
    } else {
      handleChangeSelectedIndex(selectedIndex - 1)
    }
  }

  const handleKeyboardNavigation = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "ArrowRight" && (selectedIndex + 1 !== logsCount || hasMore)) {
      handleNavigateForward()
    } else if (e.key === "ArrowLeft" && (hasPrevious || selectedIndex !== 0)) {
      handleNavigateBackward()
    }
  }

  return (
    <Dialog maxWidth="lg" open={open} onClose={handleClose} fullWidth onKeyDown={handleKeyboardNavigation}>
      <Paper elevation={0} className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>
            <Typography variant="h2-bold">
              Car log <span style={{ color: "var(--blue-blue-20)" }}>{car?.license_plate_data!}</span>
            </Typography>
            <Typography variant="h3-bold" variantColor={2}>
              {moment(car?.timestamp).format("MMM D, YYYY  - hh:mm A")}
            </Typography>
          </div>
          <CloseIcon className={styles.close} onClick={handleClose} />
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {isLoading ? (
              <Skeleton variant="rectangular" width="100%" height="425px" />
            ) : (
              <img className={styles.image} src={car?.car_image!} alt="carImage" />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className={styles.cardWrapper}>
              <Fragment>
                <div className={styles.header}>
                  <Typography variant="h2-bold" className={styles.cardTitle}>
                    {activeTab === 0 ? "Stats - Time spent" : "Stats"}
                  </Typography>
                  <ButtonSwitch
                    activePage={activeTab}
                    pages={["Graph", "Table"]}
                    handleSelectButton={setActiveTab}
                    disabled={isCarDetailsLoading}
                    pagesIcons={[<StackedBarChartIcon />, <TableViewIcon />]}
                  />
                </div>
                {activeTab === 0 ? (
                  <PaginatedBarGraph
                    isDurationGraph
                    customBarTitle="- Duration"
                    barGraphData={barGraphData}
                    isLoading={isLoading}
                  />
                ) : (
                  <CarsDetailsTable data={carData!} isLoading={isLoading} />
                )}
              </Fragment>
            </Card>
          </Grid>
        </Grid>
        <div className={styles.paginationWrapper}>
          <Button
            className={styles.rootPaginationItem}
            onClick={handleNavigateBackward}
            disabled={!hasPrevious && selectedIndex === 0}
          >
            <ArrowBackIcon fontSize="small" />
            Previous
          </Button>
          <Typography variant="p" variantColor={2}>
            You can use keyboard arrows ( ⬅️ / ➡️ ) to navigate
          </Typography>
          <Button
            className={styles.rootPaginationItem}
            onClick={handleNavigateForward}
            disabled={selectedIndex + 1 === logsCount && !hasMore}
          >
            Next
            <ArrowForwardIcon fontSize="small" />
          </Button>
        </div>
      </Paper>
    </Dialog>
  )
}

export default CarsDetails
