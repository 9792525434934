import { useContext, useState, useEffect, useMemo } from "react"
import { useQuery } from "react-query"

import { Box, Grid, CircularProgress } from "@mui/material"

import { Button, Checkbox, InputText, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../../../../API/VisionAPI"
import NoEntities from "../../../../../assets/noListData.svg?react"
import Search from "../../../../../components/Search"
import { useBranchesStore } from "../../../../../store"
import { IMissingData, EntitiesTypes, SelectedEntity } from "../../../../../types/Custom/Interfaces"
import { definitions } from "../../../../../types/Generated/apiTypes"
import { MissingDataContext } from "../../MissingDataContext/MissingDataContext"
import EntityBox from "../components/EntityBox"
import EntityCheckbox from "./EntityCheckbox"

import styles from "./FirstStep.module.scss"

type Corridor = definitions["Corridor"]
type Shop = definitions["Shop"]
type Gate = definitions["Gate"]

const entitiesTypes: Array<EntitiesTypes> = ["Gates", "Tenants", "Corridors"]

const FirstStep = () => {
  const {
    formik,
    selectedEntities,
    countSelectionsOfType,
    clearAllSelectedEntities,
    handleSelectAll,
    clearEntitiesOfType,
  } = useContext<IMissingData>(MissingDataContext)
  const [showSelected, setShowSelected] = useState(false)
  const [selectedEntityType, setSelectedEntityType] = useState<EntitiesTypes>("Gates")
  const [isAllSelected, setIsAllSelected] = useState<{ [key in EntitiesTypes]: boolean }>({
    Gates: false,
    Tenants: false,
    Corridors: false,
  })

  const [shownEntities, setShownEntities] = useState<SelectedEntity[]>([])
  const [searchValue, setSearchValue] = useState("")

  const [selectedBranch] = useBranchesStore(
    (state: { selectedBranch: number | null }) => [state.selectedBranch],
    shallow
  )

  const { data: entranceEntities, isLoading: entranceEntitiesLoading } = useQuery<Gate[], AxiosError>(
    ["fetchGates", selectedBranch],
    ({ queryKey }) => VisionAPI.fetchEntrances({ branch: queryKey[1] as number }),
    {
      enabled: selectedEntityType === "Gates" && !!selectedBranch,
    }
  )

  const { data: shopEntities, isLoading: shopEntitiesLoading } = useQuery<Shop[] | undefined, AxiosError>(
    ["fetchShops", selectedBranch],
    ({ queryKey }) => VisionAPI.fetchShops({ branch: queryKey[1] as number }),
    {
      enabled: selectedEntityType === "Tenants" && !!selectedBranch,
    }
  )

  const { data: corridorEntities, isLoading: corridorEntitiesLoading } = useQuery<Corridor[], AxiosError>(
    ["fetchCorridors", selectedBranch],
    ({ queryKey }) => VisionAPI.fetchCorridors({ branch: queryKey[1] as number }),
    {
      enabled: selectedEntityType === "Corridors" && !!selectedBranch,
    }
  )

  const handleShowSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowSelected(event?.target.checked)
  }
  const handleSelectEntityType = (type: EntitiesTypes) => {
    setSelectedEntityType(type)
  }
  const isLoading = [corridorEntitiesLoading, shopEntitiesLoading, entranceEntitiesLoading].some(
    (result) => result === true
  )
  const handleSearch = (value: string) => {
    setSearchValue(value)
  }

  useEffect(() => {
    if (showSelected) {
      setShownEntities(selectedEntities)
    } else if (selectedEntityType === "Gates" && !entranceEntitiesLoading && entranceEntities) {
      setShownEntities(entranceEntities)
    } else if (selectedEntityType === "Tenants" && !shopEntitiesLoading && shopEntities) {
      setShownEntities(shopEntities)
    } else if (selectedEntityType === "Corridors" && !corridorEntitiesLoading && corridorEntities) {
      setShownEntities(corridorEntities)
    } else {
      setShownEntities([])
    }
  }, [
    corridorEntities,
    corridorEntitiesLoading,
    entranceEntities,
    entranceEntitiesLoading,
    selectedEntities,
    selectedEntityType,
    shopEntities,
    shopEntitiesLoading,
    showSelected,
  ])

  const filteredEntities = useMemo(
    () =>
      shownEntities && shownEntities.length > 0 && searchValue
        ? shownEntities?.filter((entity) => {
            return entity?.name!.toLowerCase().includes(searchValue.toLowerCase())
          })
        : shownEntities,
    [searchValue, shownEntities]
  )

  // negate state of is all selected of a specific type
  const handleSelectAllState = (isSelected: boolean) => {
    setIsAllSelected((prevValue) => {
      return {
        ...prevValue,
        [selectedEntityType]: isSelected,
      }
    })
  }
  const handleSelectOrClearAll = () => {
    if (showSelected) {
      clearAllSelectedEntities()
      setShowSelected(false)
    } else if (isAllSelected[selectedEntityType]) {
      clearEntitiesOfType(selectedEntityType)
      handleSelectAllState(false)
    } else if (selectedEntityType === "Gates" && !entranceEntitiesLoading && entranceEntities) {
      handleSelectAll("Gates", entranceEntities)
      handleSelectAllState(true)
    } else if (selectedEntityType === "Tenants" && !shopEntitiesLoading && shopEntities) {
      handleSelectAll("Tenants", shopEntities)
      handleSelectAllState(true)
    } else if (selectedEntityType === "Corridors" && !corridorEntitiesLoading && corridorEntities) {
      handleSelectAll("Corridors", corridorEntities)
      handleSelectAllState(true)
    }
  }

  return (
    <div className={styles.wrapper}>
      {/* Missing Data File Name */}
      <Box mb={2}>
        <Typography variant="a">Missing Data File Name</Typography>
        <InputText
          id="name"
          placeholder="E.g. Missing Data of Nov"
          description="Name should be from 11 to 27 Characters"
          value={formik?.values.name}
          handleChange={formik?.handleChange}
          handleBlur={formik?.handleBlur}
          error={formik?.errors.name && formik.touched?.name && formik?.errors.name}
          width={300}
        />
      </Box>
      {/* Select Entities */}
      <Box>
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Typography variant="a">Select Entities</Typography>
          <Button onClick={handleSelectOrClearAll}>
            {showSelected || isAllSelected[selectedEntityType] ? "Clear All" : "Select all"}
          </Button>
        </Box>
        <Grid container spacing={1}>
          {entitiesTypes.map((type, i) => (
            <Grid item xs={4} key={i}>
              <EntityBox
                entityType={type}
                isTypeSelection
                isSelected={!showSelected && selectedEntityType === type}
                handleSelectType={handleSelectEntityType}
                selectionsCount={countSelectionsOfType(type)}
              />
            </Grid>
          ))}
        </Grid>
        <Box my={2.5} display="flex" justifyContent="space-between" alignItems="center">
          <Search
            topHeader={false}
            placeholder="Search by entity name"
            handleSearch={handleSearch}
            loading={isLoading}
            searchValue={searchValue}
          />
          <Checkbox onChange={handleShowSelected} name="show-selected" checked={showSelected} label="Show Selected" />
        </Box>
        <Grid container className={styles.entitiesSelection} spacing={1.5}>
          {isLoading || (!filteredEntities && !showSelected) ? (
            <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Grid>
          ) : filteredEntities && filteredEntities.length > 0 ? (
            filteredEntities?.map(
              (entity, index) =>
                entity && (
                  <Grid item key={index}>
                    <EntityCheckbox entity={entity} selectedType={selectedEntityType} onlySelected={showSelected} />
                  </Grid>
                )
            )
          ) : (
            <Grid item xs={12} className={styles.emptyStateWrapper}>
              <NoEntities />
              <Typography variant="a" variantColor={2} style={{ marginTop: 4 }}>
                No Entities Found.
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </div>
  )
}
export default FirstStep
