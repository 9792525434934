/**
 * Finds missing data entry for "Count In" or "Count Out" in the provided data points.
 * If no entry is found, an empty string is returned.
 *
 * @param points - Array of data points, each containing a serieId and associated data.
 * @returns - Missing information from the corresponding data entry, or an empty string if not found.
 */
export function findMissingDataEntry(
  points: { serieId: string; data: { missingInfo: string[] } }[]
): string | string[] {
  let entry = points.find((point) => point.serieId === "Count In")

  if (!entry) {
    entry = points.find((point) => point.serieId === "Count Out")
  }

  return entry && entry.data && entry.data.missingInfo && entry.data.missingInfo.length > 0
    ? entry.data.missingInfo
    : ""
}

/**
 * Retrieves the last data entry for the specified id.
 * If no data is found or the data array is empty, returns undefined.
 *
 * @param entries - Array of data entries, each containing an id and a data array.
 * @param id - The id of the entry to retrieve the last data for.
 * @returns - The last data entry or undefined if not found.
 */
export function getLastDataForId(entries: { id: string; data: any[] }[], id: string): any | undefined {
  const foundEntry = entries.find((entry) => entry.id === id)
  return foundEntry && foundEntry.data && foundEntry.data.length > 0
    ? foundEntry.data[foundEntry.data.length - 1]
    : undefined
}

export const missingIds = { countIn: "Count In - missing data", countOut: "Count Out - missing data" }

export const Areas = ({
  series,
  areaGenerator,
  xScale,
  yScale,
}: {
  series: any[]
  areaGenerator: (data: any[]) => string
  xScale: (value: number) => number
  yScale: (value: number) => number
}) =>
  series.map(({ id, data }) => {
    return (
      <path
        key={id}
        d={areaGenerator(
          data.map((d: { data: { missingInfo: string | any[]; x: number; y: number } }) =>
            d.data.missingInfo && d.data.missingInfo.length > 0
              ? {
                  x: xScale(d.data.x),
                  y: yScale(d.data.y),
                }
              : {
                  x: null,
                  y: null,
                }
          )
        )}
        fill="#FFE5E5"
        fillOpacity={0.4}
      />
    )
  })

export const lineGraphLayers = [
  "grid",
  "markers",
  "axes",
  Areas,
  "crosshair",
  "lines",
  "points",
  "slices",
  "mesh",
  "legends",
]

export const graphColors = {
  "Count In": "var(--blue-text-2)",
  "Count Out": "var(--pink-background-1)",
  Dwelling: "var(--blue-background-1)",
  Occupancy: "var(--green-background-1)",
  "Dwelling counts": "var(--blue-text-2)",
  "Dwelling time": "var(--blue-text-2)",
}

export const AverageBarColors = { Weekdays: "var(--blue-text-2)", Weekends: "var(--pink-background-1)" }

/**
 * Generates an array of marker objects based on the input data and missing count information.
 *
 * @param slicedData - Array of sliced data entries containing x and y values.
 * @param missingIds - Object containing ids for "Count In" and "Count Out" missing data.
 * @param lastMissingCountIn - The last known data point for "Count In" missing data, if available.
 * @param lastMissingCountOut - The last known data point for "Count Out" missing data, if available.
 * @returns - An array of marker objects to be used in the chart.
 */
export const generateMarkers = (
  slicedData: any[],
  missingIds: { countIn: string; countOut: string },
  lastMissingCountIn: { x: number } | undefined,
  lastMissingCountOut: { x: number } | undefined
) => {
  const createMarker = (axisValue: number | false, textStyleFill: string) => ({
    axis: "x",
    value: axisValue,
    lineStyle: {
      stroke: "#EA9280",
    },
    textStyle: {
      fill: textStyleFill,
    },
  })

  const countInMarker = createMarker(
    slicedData.find((item) => item.id === missingIds.countIn)?.data[0]?.x || false,
    "red"
  )

  const lastCountInMarker = createMarker(!!lastMissingCountIn && lastMissingCountIn.x, "red")

  const countOutMarker = createMarker(
    slicedData.find((item) => item.id === missingIds.countOut)?.data[0]?.x || false,
    "red"
  )

  const lastCountOutMarker = createMarker(!!lastMissingCountOut && lastMissingCountOut.x, "red")

  return [countInMarker, lastCountInMarker, countOutMarker, lastCountOutMarker]
}

/**
 * Determines the color for a given bar title based on specific keywords.
 *
 * The function checks for keywords like "female", "male", "adult", "child", and others,
 * returning a color associated with each keyword. If no match is found, a default color is returned.
 *
 * @param barTitle - The title of the bar to determine the color for.
 * @returns - The color corresponding to the bar title.
 */
export const getBarColor = (barTitle: string): string => {
  if (barTitle.includes("female")) return "var(--pink-background-2)"
  if (barTitle.includes("male")) return "var(--surface-background-blue-light-purple)"
  if (barTitle.includes("adult")) return "var(--purple-background-2)"
  if (barTitle.includes("child")) return "var(--teal-background-2)"
  if (barTitle.includes("Missing Count In")) return "var(--red-background-1)"
  if (barTitle.includes("Count In")) return "var(--indigo-background-2)"
  if (barTitle.includes("unknown")) return "#EAEEF2"
  return "var(--indigo-background-2)"
}
