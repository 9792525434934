import { FC, Fragment, RefObject, useMemo } from "react"
import { useQuery } from "react-query"

import { Grid } from "@mui/material"

import { InputOptionValue, InputText, Typography } from "@synapse-analytics/synapse-ui"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../../../../../API/VisionAPI"
import Placeholder from "../../../../../../components/Placeholder"
import { useBranchesStore } from "../../../../../../store"
import { CamerasRegions } from "../../../../../../types/Custom/Interfaces"
import { definitions } from "../../../../../../types/Generated/apiTypes"
import { isCameraFulfilled } from "../../../../../../utils/EntitiesSetupUtils"
import CameraCard from "./CameraCard"

import styles from "./CamerasSidebar.module.scss"

type CamerasList = definitions["CameraList"]

interface Props {
  selectedCameras: number[]
  selectedDrawingCamera?: number | null
  setSelectedDrawingCamera: (camera: number | null) => void
  sideBarRef: RefObject<HTMLDivElement>
  allRegions?: CamerasRegions
  handleDeleteCamera: (camera: number, cameras?: number[]) => void
  type: string
  handleSelectCameras: (cameras: number[]) => void
}

const CameraSidebar: FC<Props> = ({
  selectedCameras,
  sideBarRef,
  selectedDrawingCamera,
  setSelectedDrawingCamera,
  allRegions,
  handleDeleteCamera,
  type,
  handleSelectCameras,
}) => {
  const [selectedBranch] = useBranchesStore(
    (state: { selectedBranch: number | null }) => [state.selectedBranch],
    shallow
  )

  const { data: camerasData = [], isLoading: isCamerasListLoading } = useQuery<CamerasList[]>(
    ["fetchCamerasShortList", selectedBranch, type === "Car Parking" ? "car" : "counter"],
    ({ queryKey }) =>
      VisionAPI.fetchCamerasShortList({ branch: queryKey[1] as number, services: queryKey[2] as string }),
    {
      enabled: !!selectedBranch,
    }
  )

  const formattedCameras: InputOptionValue[] = useMemo(() => {
    return camerasData?.map((camera: CamerasList) => ({
      label: camera.name,
      value: camera.id ?? "",
      disabled: !camera?.has_sample_frame,
      disabledReason: "This camera has no frame",
    }))
  }, [camerasData])

  return (
    <Grid item xs={2.5} className={styles.camerasSidebar} ref={sideBarRef}>
      <InputText
        id="SelectedCameras"
        value="Select cameras"
        optionsWithValues={formattedCameras}
        fullWidth
        isSelect
        loading={isCamerasListLoading}
        hideDescription
        multipleModeProps={{
          isActive: !!camerasData,
          selectedValues: selectedCameras,
          setSelectedValues: handleSelectCameras,
          hideChips: true,
          areRemovable: false,
        }}
        sort={{
          mode: "selected",
        }}
      />
      {selectedCameras.length > 0 ? (
        <Fragment>
          <Typography variant="label" variantColor={2} align="left">
            Selected cameras
          </Typography>
          {selectedCameras.map((camera, index) => (
            <CameraCard
              key={index}
              camera={camera}
              handleSelectCamera={setSelectedDrawingCamera}
              isSelected={selectedDrawingCamera === camera}
              isDone={isCameraFulfilled(type, camera, allRegions)}
              handleDeleteCamera={handleDeleteCamera}
            />
          ))}
        </Fragment>
      ) : (
        <Placeholder description="No cameras selected" selectionType="camera" svgWidth="73px" svgHeight="37px" />
      )}
    </Grid>
  )
}

export default CameraSidebar
