import { useEffect, useState, Fragment, useMemo } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"

import { CircularProgress } from "@mui/material"

import { DateRangePicker, Typography } from "@synapse-analytics/synapse-ui"
import { Tag } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import intervalToDuration from "date-fns/intervalToDuration"

import { VisionAPI } from "../../../API/VisionAPI"
import { useDateQuery } from "../../../hooks/useDateQuery"
import { definitions } from "../../../types/Generated/apiTypes"
import { getCategoryAndSubcategoryNames } from "../../../utils/shopUtils"
import ShopDetails from "./components/ShopDetails"

import styles from "./DetailsPage.module.scss"

type Params = {
  id?: number
}
type Category = definitions["Category"]
type Shop = definitions["Shop"]

const DetailsPage = () => {
  const [startDate, setStartDate, endDate, setEndDate] = useDateQuery()

  const [timeGrain, setTimeGrain] = useState<"hour" | "day" | null>(null)
  const [interval, setInterval] = useState<Duration>()
  const [isLoading, setIsLoading] = useState(false)
  const params: Params = useParams() //to extract tenant id from pathname

  /**
   * This useEffect hook is used to calculate the duration between the start and end dates and set the time grain accordingly.
   * It checks if both start and end dates are not null, calculates the duration using the intervalToDuration function,
   * and sets the interval state. Based on the duration, it sets the time grain to either "day" or "hour".
   * The hook is dependent on the start and end dates, as well as the time grain, so it will run whenever these values change.
   */
  useEffect(() => {
    if (endDate !== null && startDate !== null) {
      const interval = intervalToDuration({
        start: startDate?.toDate(),
        end: endDate?.toDate(),
      })
      setInterval(interval)
      if (interval.days! > 1 || interval.months! >= 1) {
        setTimeGrain("day")
      } else {
        setTimeGrain("hour")
      }
    }
  }, [startDate, endDate, timeGrain])

  const { data: categories, isLoading: categoriesLoading } = useQuery<Category[], AxiosError>(
    "fetchCategories",
    VisionAPI.fetchCategories
  )

  const { data: shop, isLoading: shopDataLoading } = useQuery<Shop, AxiosError>(
    ["fetchShop", params?.id],
    ({ queryKey }) => VisionAPI.fetchShop(queryKey[1] as number),
    {
      enabled: !categoriesLoading,
    }
  )

  const shopData = useMemo(() => {
    let shopObject: Omit<Shop, "category" | "subcategory"> & { category: string | number; subcategory: string | number }
    const [categoryName, subcategoryName] = getCategoryAndSubcategoryNames(
      categories,
      shop?.category,
      shop?.subcategory
    )
    shopObject = { ...shop, category: categoryName as string | number, subcategory: subcategoryName as string }
    return shopObject
  }, [shop, categories])

  return (
    <div>
      {shopDataLoading ? (
        <CircularProgress size={50} className={styles.loading} />
      ) : (
        <Fragment>
          <Typography
            variant="h2-regular"
            gutterBottom
            variantColor={2}
            tooltip={`${shopData?.name} shop analytics over selected date range`}
            tooltipPlacement="right"
            tooltipIconSize={22}
          >
            {shopData?.name}
          </Typography>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            disabled={isLoading}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            disableFuture
          />
          <div className={styles.infoTags}>
            {shopData?.category && (
              <div className={styles.tagWrapper}>
                <Typography variant="label" variantColor={2} gutterBottom>
                  Category
                </Typography>
                <Tag key={`${shopData?.category}`} variant="orange" size="regular">
                  {shopData?.category}
                </Tag>
              </div>
            )}
            {shopData?.subcategory && (
              <div className={styles.tagWrapper}>
                <Typography variant="label" variantColor={2} gutterBottom>
                  Sub-category
                </Typography>
                <Tag key={`${shopData?.subcategory}`} variant="pink" size="regular">
                  {shopData?.subcategory}
                </Tag>
              </div>
            )}
            {!!shopData?.area && (
              <div className={styles.tagWrapper}>
                <Typography variant="label" variantColor={2} gutterBottom>
                  Area
                </Typography>
                <Tag key={`${shopData?.area} m²`} variant="positive" size="regular">
                  {shopData?.area} m²
                </Tag>
              </div>
            )}
          </div>
          {/* start and end date and tenant id should be sent for fetching in shop details */}
          <ShopDetails
            startDate={startDate}
            endDate={endDate}
            timeGrain={timeGrain!}
            interval={interval!}
            setLoadingState={setIsLoading}
            tenantId={shopData?.id}
            tenantCategory={shopData?.category as number}
            categories={categories}
            categoriesLoading={categoriesLoading}
          />
        </Fragment>
      )}
    </div>
  )
}
export default DetailsPage
