import { FC } from "react"

import CameraEnhanceIcon from "@mui/icons-material/CameraEnhanceOutlined"
import QueryBuilderOutlinedIcon from "@mui/icons-material/QueryBuilderOutlined"
import { Card, CardMedia, CardContent } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"
import moment from "moment"

import { definitions } from "../../../types/Generated/apiTypes"
import EventTypeChip from "./EventTypeChip"

import styles from "./EventCard.module.scss"

type EventsPaginated = definitions["SecurityViolationList"]
/**
 * @interface Props
 */
interface Props {
  /**
   * The event log data.
   * @type {EventsPaginated}
   */
  eventLog: EventsPaginated
  /**
   * Indicates if the event is a live alert.
   * @type {boolean}
   */
  isLive: boolean
}

/**
 * Returns the border color based on the event type.
 *
 * @param type - The type of the event.
 * @returns The border color as a CSS variable.
 */
const getBorderColor = (type: number) => {
  switch (type) {
    case 0:
      return "var(--blue-text-1)"
    case 1: // Violence
      return "var(--red-background-1)"
    case 2: // Motion
      return "var(--warning-background-default)"
    case 3: // Intrusion
    case 5: // Blacklist Person
      return "var(--neutral-border-default)"
    case 4: // Blacklist Car
      return "var(--important-text-disabled)"
    case 6: // Stray Animal
      return "var(--orange-background-2)"
  }
}

const EventCard: FC<Props> = ({ eventLog, isLive }) => {
  return (
    <Card
      className={styles.eventCard}
      sx={{
        minWidth: isLive ? "300px" : 0,
        height: isLive ? "100%" : "auto",
        border: isLive ? `2px solid ${getBorderColor(eventLog.type ?? 0)}` : "0",
      }}
    >
      <div>
        {!eventLog?.image ? (
          <div className={styles.media}>
            <Typography variant="label" variantColor={2} className={styles.noFrame}>
              No Frame Captured
            </Typography>
          </div>
        ) : (
          <CardMedia className={styles.media} image={eventLog?.image!} title={eventLog?.camera_name!} />
        )}
      </div>
      <CardContent style={{ padding: 0 }}>
        <div className={styles.chipDateWrapper}>
          <EventTypeChip type={eventLog?.type!} />
        </div>
        <Typography variant="a" variantColor={2} className={styles.detail}>
          <QueryBuilderOutlinedIcon style={{ marginRight: 2 }} />
          {isLive ? moment(eventLog?.timestamp!).fromNow() : moment(eventLog?.timestamp!).format("DD/MM/YYYY")}
        </Typography>
        <Typography variant="a" variantColor={2} className={styles.detail} title={eventLog?.camera_name!}>
          <CameraEnhanceIcon style={{ marginRight: 2 }} />
          {eventLog?.camera_name! && eventLog?.camera_name!.length >= 17
            ? eventLog?.camera_name.slice(0, 17) + ".."
            : eventLog?.camera_name!}
        </Typography>
      </CardContent>
    </Card>
  )
}
export default EventCard
