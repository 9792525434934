import { useState, FC, useMemo } from "react"
import { useQuery } from "react-query"

import { Grid } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"

import { Chip, Skeleton, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import { Moment } from "moment"

import { VisionAPI } from "../../../API/VisionAPI"
import PaginatedBarGraph from "../../../components/GraphCards/PaginatedBarGraphCard"
import { TableColumn } from "../../../types/Custom/Types"
import { definitions } from "../../../types/Generated/apiTypes"
import AvgCountWeek from "../../EntranceGates/components/AvgCountWeek"
import Leaderboard from "./Leaderboard"

import styles from "./TenantsSubcategories.module.scss"

type CategoryCount = definitions["CategoryCount"]
type SubCategoryCount = definitions["SubCategoryCount"]
type HourlyAVGCounts = definitions["HourlyAVGCounts"]
type ShopsCategoryLogs = definitions["ShopsCategoryLogs"]
type CategoryDailyLogs = definitions["LogsPerDay"]
type Category = definitions["Category"]

interface Props {
  categoriesLogsData?: CategoryCount[]
  dailyCategoriesCountData?: ShopsCategoryLogs[]
  loading: boolean
  startDate?: Moment | null
  endDate?: Moment | null
  timeGrain: "hour" | "day"
  interval: Duration
  subcategoriesPerformanceRef: any
  hourlyAvgRef: any
  weekDayAvgRef: any
  shouldIncludeStaff: boolean
}
// find subcategories data of selected category from quick filters
const findSubcategories = (data: CategoryCount[], category: Category) =>
  data.find((searchedCategory) => searchedCategory.category === category.name)?.sub_categories

const loadingChips = new Array(6).fill(null).map((_r, i) => (
  <Grid item md={2} key={i}>
    <Skeleton variant="rectangular" height={26} width="auto" />
  </Grid>
))

const TenantsSubcategories: FC<Props> = ({
  categoriesLogsData,
  dailyCategoriesCountData,
  loading,
  startDate,
  endDate,
  timeGrain,
  subcategoriesPerformanceRef,
  hourlyAvgRef,
  weekDayAvgRef,
  shouldIncludeStaff,
  interval,
}) => {
  const xLargeScreen = useMediaQuery("(min-width:1900px)")
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null)

  const { data: categoriesAvgCounts, isLoading: categoriesAvgCountsLoading } = useQuery<HourlyAVGCounts[]>(
    [
      "fetchCategoriesHourlyAvg",
      startDate?.format("YYYY-MM-DD"),
      endDate?.format("YYYY-MM-DD"),
      shouldIncludeStaff,
      selectedCategory && selectedCategory.id,
    ],
    ({ queryKey }) =>
      VisionAPI.fetchCategoriesHourlyAvgCount({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        include_staff: queryKey[3] as boolean,
        category_id: queryKey[4] as number,
      }),
    {
      enabled: [!!endDate, !!selectedCategory?.id].every((check) => check === true),
    }
  )

  const { data: categories, isLoading: categoriesLoading } = useQuery<Category[], AxiosError>(
    "fetchCategories",
    VisionAPI.fetchCategories,
    {
      onSuccess: (data) => {
        if (data && Object.values(data)[0]) setSelectedCategory(Object.values(data)[0])
      },
    }
  )

  // when a category is selected from quick filters => set graphs/tables data to its subcategories
  const { subCategoryCounts, weekDayAverageData } = useMemo(() => {
    let subCategoryCounts: SubCategoryCount[] | undefined = []
    let weekDayAverageData: CategoryDailyLogs[] = []

    if (selectedCategory && !loading) {
      if (categoriesLogsData) {
        subCategoryCounts = findSubcategories(categoriesLogsData, selectedCategory)
      }

      if (dailyCategoriesCountData) {
        const dailyCategoryData = dailyCategoriesCountData.find((log) => log.category === selectedCategory.name)
        weekDayAverageData = dailyCategoryData?.logs ?? []
      }
    }

    return { subCategoryCounts, weekDayAverageData }
  }, [selectedCategory, categoriesLogsData, dailyCategoriesCountData, loading])

  // handling clicking on one of the categories filters
  const handleFilterByCategory = (category: Category) => {
    //checking whether clicking on another category option or just toggling (unselecting)
    if (category.name !== selectedCategory?.name) {
      setSelectedCategory(category)
    } else {
      setSelectedCategory(null)
    }
  }

  const tableColumns: TableColumn[] = [
    {
      title: "Subcategory",
      field: "sub_category",
      searchable: true,
    },
    {
      title: "Male Count",
      field: "male_count",
      searchable: false,
    },
    {
      title: "Female Count",
      field: "female_count",
      searchable: false,
    },
    {
      title: "Adult Count",
      field: "adult_count",
      searchable: false,
    },
    {
      title: "Child Count",
      field: "child_count",
      searchable: false,
    },
    {
      title: "Total Count",
      field: "total_count",
      searchable: false,
    },
  ]

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Typography variant="a" gutterBottom variantColor={2}>
          Categories Information
        </Typography>
      </div>
      {/* quick filters */}
      <Grid container spacing={1} className={styles.categoriesSelection}>
        {categoriesLoading
          ? loadingChips
          : categories?.map((category: any, index: number) => (
              <Grid item key={index}>
                <Chip
                  removable={selectedCategory && selectedCategory.name === category.name ? true : false}
                  clickable
                  onClick={() => handleFilterByCategory(category)}
                  onRemove={() => null}
                  isSelected={selectedCategory && selectedCategory.name === category.name ? true : false}
                >
                  {category.name}
                </Chip>
              </Grid>
            ))}
      </Grid>
      {/* Subcategory Performance Bar Graph and Leaderboard Table */}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={5} xl={xLargeScreen ? 7 : 6}>
          <Grid container spacing={1}>
            <Grid item md={12}>
              <AvgCountWeek
                logsData={weekDayAverageData!}
                loading={loading}
                interval={interval}
                reference={weekDayAvgRef}
                isTenants
                noCategorySelected={!selectedCategory}
              />
            </Grid>
            <Grid item md={12}>
              <AvgCountWeek
                logsData={categoriesAvgCounts!}
                loading={categoriesAvgCountsLoading}
                interval={interval}
                reference={hourlyAvgRef}
                hourlyAvg
                isTenants
                noCategorySelected={!selectedCategory}
              />
            </Grid>
            <Grid item md={12}>
              <PaginatedBarGraph
                data={subCategoryCounts}
                graphProps={{
                  indexBy: "sub_category",
                  shouldDisplayDistribution: true,
                }}
                tableProps={{
                  columns: tableColumns,
                }}
                isLoading={loading}
                startDate={startDate?.format("YYYY-MM-DD")}
                endDate={endDate?.format("YYYY-MM-DD")}
                reference={subcategoriesPerformanceRef}
                noCategorySelected={!selectedCategory}
                title="Subcategories performance"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={7} xl={xLargeScreen ? 5 : 6}>
          <Leaderboard
            startDate={startDate}
            endDate={endDate}
            selectedCategory={selectedCategory}
            timeGrain={timeGrain}
            categories={categories}
            categoriesLoading={categoriesLoading}
            shouldIncludeStaff={shouldIncludeStaff}
          />
        </Grid>
      </Grid>
    </div>
  )
}
export default TenantsSubcategories
