import React, { useEffect, createContext, ReactNode, useState, useRef } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"

import { useMediaQuery } from "@mui/material"

import { FormikProps, useFormik } from "formik"
import * as Yup from "yup"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../../API/VisionAPI"
import { useBranchesStore } from "../../../store"
import {
  ICameraMappingProperties,
  ICameraProperties,
  IFloors,
  IFloorsDrop,
  ISelectedCamera,
  ISelectedMappingCamera,
  OriginalImageSize,
} from "../../../types/Custom/Interfaces"
import { definitions } from "../../../types/Generated/apiTypes"

type CameraLocatingList = definitions["CameraOnFloor"]
type CameraFloorMapping = definitions["CameraFloorMapping"]
type CameraFloorCoordinate = definitions["CameraFloorCoordinate"]

export const FloorsContext = createContext({} as IFloors)
const FloorsContextProvider = ({ children }: { children: ReactNode }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null!)
  const canvasFloorRef = useRef<HTMLCanvasElement>(null!)
  const canvasCameraSrcRef = useRef<HTMLCanvasElement>(null!)
  const [pointCameraAlert, setPointCameraAlert] = useState(false)
  const [floorEditSuccuss, setFloorEditSuccuss] = useState(false)
  const [savedCamerasChanges, setSavedCamerasChanges] = useState(false)
  const [saveCameraChangesAlert, setSaveCameraChangesAlert] = useState(false)
  const [floorVersionId, setFloorVersionId] = useState(0)
  const [floorImageSizeLocating, setFloorImageSizeLocating] = useState<OriginalImageSize>({ width: 736, height: 416 })
  const [floorImageSizeMapping, setFloorImageSizeMapping] = useState<OriginalImageSize>({ width: 736, height: 416 })
  const [cameraImageSizeMapping, setCameraImageSizeMapping] = useState<OriginalImageSize>({ width: 736, height: 416 })
  const [activeStep, setActiveStep] = useState(0)
  const [camerasLocatingAlert, setCamerasLocatingAlert] = useState(false)
  const [cameraMappingAlert, setCameraMappingAlert] = useState(false)

  const [selectedBranch] = useBranchesStore(
    (state: { selectedBranch: number | null }) => [state.selectedBranch],
    shallow
  )

  const queryClient = useQueryClient()
  const smallScreens = useMediaQuery("(max-width:1610px)")
  const width = smallScreens ? 620 : 860
  const height = smallScreens ? 520 : 660
  const handleOpenCamerasLocatingAlert = () => {
    setCamerasLocatingAlert(true)
  }

  const handleOpenCameraMappingAlert = () => {
    setCameraMappingAlert(true)
  }

  const selectFloorVersion = (versionId: number) => {
    setFloorVersionId(versionId)
  }

  // step 2 => locating cameras to floor plan map
  const [cameraProperties, setCameraProperties] = useState<ICameraProperties[]>([])
  const [selectedCamera, setSelectedCamera] = useState<ISelectedCamera>({
    cameraId: 0,
    cameraName: "",
    colorCode: "",
    cameraSrc: "",
  })

  const [selectedMappingCamera, setSelectedMappingCamera] = useState<ISelectedMappingCamera>({
    cameraId: 0,
    colorCode: "",
    cameraSrc: "",
    cameraName: "",
  })

  // step 3 => mapping cameras to floor plan map
  const [cameraMappingProperties, setCameraMappingProperties] = useState<ICameraMappingProperties>({
    cameraId: 0,
    points: [],
  })

  const [savedMappedCameras, setSavedMappedCameras] = useState<ICameraMappingProperties[]>([])

  const resetMappings = () => {
    setCameraProperties([])
    setSelectedCamera({
      cameraId: 0,
      cameraName: "",
      colorCode: "",
      cameraSrc: "",
    })
    setSelectedMappingCamera({
      cameraId: 0,
      colorCode: "",
      cameraSrc: "",
      cameraName: "",
    })
    setCameraMappingProperties({
      cameraId: 0,
      points: [],
    })
    setSavedMappedCameras([{ cameraId: 0, points: [] }])
    formik.setFieldValue("SelectedCameras", [])
    setFloorVersionId(0)
    selectFloorVersion(0)
  }

  const resetAllData = () => {
    resetMappings()
    setPointCameraAlert(false)
    setFloorEditSuccuss(false)
    setSavedCamerasChanges(false)
    setSaveCameraChangesAlert(false)
    setFloorImageSizeLocating({ width: 736, height: 416 })
    setFloorImageSizeMapping({ width: 736, height: 416 })
    setCameraImageSizeMapping({ width: 736, height: 416 })
    setActiveStep(0)
    setCamerasLocatingAlert(false)
    setCameraMappingAlert(false)
    formik.resetForm()
  }

  // set first camera to selection by default [Mapping cameras selection]
  useEffect(() => {
    if (cameraProperties && cameraProperties?.length > 0 && cameraProperties[0]?.cameraSrc !== null) {
      setSelectedMappingCamera({
        cameraId: cameraProperties[0]?.camera!,
        colorCode: cameraProperties[0]?.colorCode!,
        cameraSrc: cameraProperties[0]?.cameraSrc!,
        cameraName: cameraProperties[0]?.name!,
      })
      selectedCameras(
        true,
        cameraProperties[0]?.camera!,
        cameraProperties[0]?.colorCode!,
        cameraProperties[0]?.name!,
        cameraProperties[0]?.cameraSrc!
      )
      setSaveCameraChangesAlert(false)
      setCameraMappingProperties({
        cameraId: cameraProperties[0]?.camera!,
        points: cameraMappingProperties?.points!,
      })
    } else {
      setSelectedMappingCamera({
        cameraId: cameraProperties[1]?.camera!,
        colorCode: cameraProperties[1]?.colorCode!,
        cameraSrc: cameraProperties[1]?.cameraSrc!,
        cameraName: cameraProperties[1]?.name!,
      })
      selectedCameras(
        true,
        cameraProperties[1]?.camera!,
        cameraProperties[1]?.colorCode!,
        cameraProperties[1]?.name!,
        cameraProperties[1]?.cameraSrc!
      )
      setSaveCameraChangesAlert(false)

      setCameraMappingProperties({
        cameraId: cameraProperties[1]?.camera!,
        points: cameraMappingProperties?.points!,
      })
    }
    // eslint-disable-next-line
  }, [cameraProperties])

  // validation for add floor plan fields
  const validationFormik = () => {
    return Yup.object({
      floorName: Yup.string().required("Floor name is required"),
    })
  }

  // add floor plan fields
  const formik: FormikProps<IFloorsDrop> = useFormik<IFloorsDrop>({
    initialValues: {
      floorName: "",
      floorId: 0,
      versionId: 0,
      floorPreview: "",
      floorEdit: false,
      SelectedCameras: [],
      ValidCameras: [],
      invalidCameras: [],
      tags: [],
      floorFile: new File([""], "filename"),
    },
    validationSchema: validationFormik,
    onSubmit: (values, actions) => {
      setTimeout(() => {
        actions.setSubmitting(false)
      }, 1000)
      return formik?.values?.floorEdit ? editFloor(values) : addFloor(values)
    },
  })

  // add floorPlan data and get [floor_id]
  const addFloorPlan = async (values: IFloorsDrop) => {
    let form_data = new FormData()
    form_data.append("name", values.floorName)

    form_data.append("image", values.floorFile!)

    form_data.append("branch", `${selectedBranch}`)

    // if there is floor tags
    if (values?.tags) {
      for (var i = 0; i < values.tags!.length; i++) {
        form_data.append("tags", values.tags![i])
      }
    }
    const data = await VisionAPI.addFloorPlan(form_data)
    if (data) {
      formik.setFieldValue("floorId", data?.floor_id)
      formik.setFieldValue("versionId", data?.version_id)
    }
  }

  const editFloorPlan = async (values: IFloorsDrop) => {
    const form_data = new FormData()
    form_data.append("floor", values.floorId?.toString() ?? "")

    if (values.floorFile && values.floorFile.size > 0) {
      form_data.append("image", values.floorFile)
    }

    if (values.floorPreview && values.floorFile && values.floorFile.size > 0) {
      const res = await VisionAPI.addFloorVersion(form_data)
      setFloorVersionId(res.id)
      return VisionAPI.editFloorPlan(values.floorId as number, {
        floor_name: values.floorName,
        tags: values.tags,
        branch: selectedBranch,
      })
    } else {
      return VisionAPI.editFloorPlan(values.floorId as number, {
        floor_name: values.floorName,
        tags: values.tags,
        branch: selectedBranch,
      })
    }
  }

  const { mutate: addFloor, isLoading: isSubmittingFloor } = useMutation(addFloorPlan, {
    onSuccess: () => {
      setActiveStep((prevValue) => prevValue + 1)
      activeStep === 3 && resetAllData()
    },
  })

  const { mutate: editFloor } = useMutation(editFloorPlan, {
    onMutate: async () => {
      setFloorEditSuccuss(false)
    },
    onSuccess: async () => {
      if (formik.values?.floorPreview || floorVersionId) {
        setActiveStep((prevValue) => prevValue + 1)
      }
    },
  })

  const handleDropFloor = (acceptedFiles: any) => {
    const preview = acceptedFiles.map((file: any) => {
      return Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    })
    formik.setFieldValue("floorPreview", preview[0]?.preview)
    formik.setFieldValue("floorFile", acceptedFiles[0])
    if (formik?.values?.floorEdit) {
      resetMappings()
    }
  }

  // get camera coordinates locating on floor version
  const { data: cameraCoorsList, isLoading: isLoadingCameraCoors } = useQuery<CameraLocatingList[]>(
    ["fetchCameraLocatingCoors", floorVersionId],
    () =>
      VisionAPI.fetchCameraLocatingCoors({
        floor_id: formik?.values?.floorId as number,
        version_id: floorVersionId,
      }),
    {
      enabled: formik?.values?.floorEdit && !!floorVersionId,
    }
  )

  const { data: mappedCameras } = useQuery<CameraFloorMapping[]>(
    ["fetchCameraMappingCoors", floorVersionId, selectedMappingCamera?.cameraId],
    ({ queryKey }) =>
      VisionAPI.fetchCameraMappingCoors({
        version_id: queryKey[1] as number,
        camera_id: queryKey[2] as number,
      }),
    {
      enabled: Boolean(
        formik?.values?.floorEdit &&
          floorVersionId &&
          Array.isArray(cameraCoorsList) &&
          cameraCoorsList.length > 0 &&
          selectedMappingCamera?.cameraId &&
          !saveCameraChangesAlert
      ),
    }
  )

  const { mutate: deleteCameraMapping } = useMutation((cameraId: number) =>
    VisionAPI.deleteCameraMappingCoors({
      camera_id: cameraId,
      version_id:
        formik?.values?.floorEdit && floorVersionId ? (floorVersionId as number) : (formik?.values.versionId as number),
    })
  )

  const setLocatingFloorImage = (originalImageSize: OriginalImageSize) => {
    setFloorImageSizeLocating(originalImageSize)
  }

  const setMappingFloorImage = (originalImageSize: OriginalImageSize) => {
    setFloorImageSizeMapping(originalImageSize)
  }

  const setMappingCameraImage = (originalImageSize: OriginalImageSize) => {
    setCameraImageSizeMapping(originalImageSize)
  }

  // add mapped camera to original state of mapped cameras
  useEffect(() => {
    if (
      formik?.values?.floorEdit &&
      floorVersionId &&
      mappedCameras &&
      cameraImageSizeMapping &&
      floorImageSizeMapping
    ) {
      const resizedMappedCoors = mappedCameras?.map((cameraProp) => {
        const x_camera_resized = Math.floor((cameraProp?.x_camera! / cameraImageSizeMapping.width) * width)
        const y_camera_resized = Math.floor((cameraProp?.y_camera! / cameraImageSizeMapping.height) * height)
        // handle image canvas for edit
        const x_floor_resized = Math.floor((cameraProp?.x_floor! / floorImageSizeMapping.width) * width)
        const y_floor_resized = Math.floor((cameraProp?.y_floor! / floorImageSizeMapping.height) * height)
        return {
          ...cameraProp,
          x_camera_resized,
          y_camera_resized,
          x_floor_resized,
          y_floor_resized,
        }
      })

      setCameraMappingProperties({
        cameraId: selectedMappingCamera?.cameraId,
        points: resizedMappedCoors!,
      })
    }
    // eslint-disable-next-line
  }, [
    mappedCameras,
    cameraCoorsList,
    floorImageSizeMapping,
    cameraImageSizeMapping,
    floorVersionId,
    selectedMappingCamera,
  ])
  useEffect(() => {
    if (cameraCoorsList) {
      setCameraProperties(cameraCoorsList)
      const mappedSelectedCameras = cameraCoorsList.map((coorElement) => coorElement.camera)

      formik.setFieldValue("SelectedCameras", mappedSelectedCameras)
    }
    // eslint-disable-next-line
  }, [cameraCoorsList])
  // fetch list of located cameras of floor version
  useEffect(() => {
    if (
      formik?.values?.floorEdit &&
      floorVersionId &&
      cameraCoorsList &&
      cameraCoorsList?.length > 0 &&
      floorImageSizeLocating
    ) {
      // compare located cameras list to original all cameras
      // eslint-disable-next-line array-callback-return
      formik.values.ValidCameras?.filter((camera) => {
        setCameraProperties((prevState) => {
          return prevState.map((cameraProps: ICameraProperties) => {
            if (cameraProps.camera === camera?.id!) {
              const x_cam_resized = Math.floor((cameraProps?.x_cam! / floorImageSizeLocating.width) * width)
              const y_cam_resized = Math.floor((cameraProps?.y_cam! / floorImageSizeLocating.height) * height)
              // handle image canvas for edit
              return {
                ...cameraProps,
                name: camera?.name,
                x_cam_resized,
                y_cam_resized,
                colorCode: camera?.colorCode,
                cameraSrc: camera?.sample_frame,
              }
            } else {
              return cameraProps
            }
          })
        })
      })
    }
    // eslint-disable-next-line
  }, [cameraCoorsList, formik.values.ValidCameras, floorVersionId, floorImageSizeLocating])

  const { mutate: deleteCameraLocatingCoors } = useMutation(
    (cameraId: number) =>
      VisionAPI.deleteCameraLocatingCoors({
        floor_id: formik?.values.floorId as number,
        version_id:
          formik?.values?.floorEdit && floorVersionId
            ? (floorVersionId as number)
            : (formik?.values.versionId as number),
        camera_id: cameraId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("fetchCameraLocatingCoors")
      },
    }
  )

  // step 2 => place camera on click to canvas
  const handlePlaceCamera = (
    e: React.MouseEvent<HTMLCanvasElement, MouseEvent>,
    originalFloorImageSize: OriginalImageSize
  ) => {
    e.persist()
    const rect = canvasRef?.current?.getBoundingClientRect()
    const x_cam_resized = Math.floor(e.clientX - rect.left)
    const y_cam_resized = Math.floor(e.clientY - rect.top)
    const x_cam = Math.floor((x_cam_resized / canvasRef?.current?.width) * originalFloorImageSize.width)
    const y_cam = Math.floor((y_cam_resized / canvasRef?.current?.height) * originalFloorImageSize.height)

    if (selectedCamera?.cameraId !== 0) {
      const selectedCameraFilter = cameraProperties?.filter(
        (cameraProp) => cameraProp?.camera === selectedCamera?.cameraId
      )
      if (selectedCameraFilter?.length > 0) {
        // edit selected camera
        setCameraProperties((prevState) => {
          return prevState.map((cameraProps: ICameraProperties) => {
            return cameraProps.camera === selectedCamera?.cameraId
              ? {
                  ...cameraProps,
                  x_cam,
                  y_cam,
                  x_cam_resized,
                  y_cam_resized,
                }
              : cameraProps
          })
        })
      } else {
        // add new selected camera
        setCameraProperties((prevState) => [
          ...prevState,
          {
            x_cam,
            y_cam,
            x_cam_resized,
            y_cam_resized,
            rotation_angle: 360,
            camera: selectedCamera?.cameraId,
            colorCode: selectedCamera?.colorCode,
            name: selectedCamera?.cameraName,
            cameraSrc: selectedCamera?.cameraSrc,
          },
        ])
      }
    }
  }
  const deleteLocatedCamera = (cameraId: number) => {
    const selectedCameraFilter = cameraProperties?.filter((cameraProp) => cameraProp?.camera === cameraId)
    if (selectedCameraFilter?.length > 0) {
      setCameraProperties(cameraProperties?.filter((cameraProp) => cameraProp?.camera !== cameraId))
      return deleteCameraLocatingCoors(cameraId)
    }
  }

  // if step 2 => Locating cameras to floor map
  const cameraList = (bool: boolean, cameraId: number, colorCode?: string, cameraName?: string, cameraSrc?: string) => {
    // if select camera to locate
    if (bool) {
      setSelectedCamera({
        ...selectedCamera,
        cameraId: cameraId,
        colorCode: colorCode!,
        cameraName: cameraName!,
        cameraSrc: cameraSrc!,
      })
    } else {
      // if delete camera locating position
      deleteLocatedCamera(cameraId)
    }
  }

  // if step 3 => Mapping cameras to floor
  const selectedCameras = (
    bool: boolean,
    cameraId: number,
    colorCode?: string,
    cameraName?: string,
    cameraSrc?: string
  ) => {
    // if select camera to map
    if (bool) {
      checkIfSelectedCameraAdded(cameraId, colorCode, cameraSrc, cameraName)
      // if delete camera map points
    } else {
      if (selectedMappingCamera?.cameraId === cameraId) {
        setPointCameraAlert(false)

        // delete camera from mapping
        setCameraMappingProperties({
          ...cameraMappingProperties,
          cameraId: 0,
          points: [],
        })

        // delete camera from saved
        deleteCameraMapping(cameraMappingProperties?.cameraId)
        setSavedMappedCameras((prevState) => {
          return prevState.map((cameras) => {
            return cameras?.cameraId === cameraMappingProperties?.cameraId ? { cameraId: 0, points: [] } : cameras
          })
        })
      }
    }
  }

  // check if selected camera is already SAVED [added]
  const checkIfSelectedCameraAdded = (
    cameraId: number,
    colorCode?: string,
    cameraSrc?: string,
    cameraName?: string
  ) => {
    // if mapped camera is SAVED !
    const savedChanges = savedMappedCameras?.some((camera) => {
      return (
        camera?.cameraId === cameraMappingProperties?.cameraId &&
        camera?.points.length === cameraMappingProperties?.points.length
      )
    })
    // is selected camera is saved
    const savedCamera = savedMappedCameras?.some((camera) => {
      return camera?.cameraId === cameraId
    })

    const savedCameraWithSelection = savedMappedCameras?.find((camera) => camera?.cameraId === cameraId)!
    const isSameCamera = cameraMappingProperties?.cameraId === cameraId
    const cameraAlert = !savedChanges && !isSameCamera && cameraMappingProperties?.points.length > 0

    // when select to camera not equal to current camera [ALERT]
    if (cameraAlert) {
      setSaveCameraChangesAlert(true)
    } else {
      setSelectedMappingCamera({
        colorCode: colorCode!,
        cameraSrc: cameraSrc!,
        cameraId: cameraId || 0,
        cameraName: cameraName!,
      })
      setTimeout(() => {
        queryClient.invalidateQueries("fetchCameraMappingCoors")
      }, 300)
    }

    // when save camera
    if (savedCamera && !cameraAlert) {
      setCameraMappingProperties(savedCameraWithSelection)
    } else {
      // when change to another camera
      if (cameraMappingProperties?.points.length < 0 || (!isSameCamera && !cameraAlert)) {
        setCameraMappingProperties({
          ...cameraMappingProperties,
          cameraId: cameraId,
          points: [],
        })
      }
    }
  }

  // handle on click to select camera to place on canvas
  const handleOnSelectCamera = (
    bool: boolean,
    cameraId?: number,
    colorCode?: string,
    cameraName?: string,
    cameraSrc?: string
  ) => {
    if (cameraId) {
      if (activeStep < 2) {
        return
      }
      // if step 2 => Locating cameras to floor map
      else if (activeStep === 2) {
        cameraList(bool, cameraId, colorCode, cameraName, cameraSrc)
        // if step 3 => Mapping cameras to floor
      } else {
        selectedCameras(bool, cameraId, colorCode, cameraName, cameraSrc)
      }
    }
  }

  // step 3 => handle mapping camera to the floor [Drawing points]
  const handleMapCamerasToFloor = (
    e: React.MouseEvent<HTMLCanvasElement, MouseEvent>,
    mappingType: string,
    originalFloorImageSize: OriginalImageSize
  ) => {
    e.persist()
    // calculating x and y coordinates of camera frame
    const cameraRect = canvasCameraSrcRef?.current?.getBoundingClientRect()
    const x_camera_resized = Math.floor(e.clientX - cameraRect.left)
    const y_camera_resized = Math.floor(e.clientY - cameraRect.top)
    // calculating x and y coordinates of floor plan
    const floorRect = canvasFloorRef?.current?.getBoundingClientRect()
    const x_floor_resized = Math.floor(e.clientX - floorRect.left)
    const y_floor_resized = Math.floor(e.clientY - floorRect.top)

    if (selectedMappingCamera?.cameraId !== 0) {
      if (mappingType === "camera") {
        const x_camera = Math.floor(
          (x_camera_resized / canvasCameraSrcRef?.current?.width) * originalFloorImageSize.width
        )
        const y_camera = Math.floor(
          (y_camera_resized / canvasCameraSrcRef?.current?.height) * originalFloorImageSize.height
        )
        const filterCameraMapping = cameraMappingProperties.points.every(
          (point) => point.x_camera_resized === undefined || point.x_floor_resized !== undefined
        )
        if (filterCameraMapping) {
          setPointCameraAlert(false)
          setCameraMappingProperties((prevState) => ({
            ...prevState,
            cameraId: selectedMappingCamera?.cameraId,
            points: [
              ...cameraMappingProperties.points,
              {
                x_camera,
                y_camera,
                x_camera_resized,
                y_camera_resized,
              },
            ],
          }))
        } else {
          setPointCameraAlert(true)
        }
      } else {
        const x_floor = Math.floor((x_floor_resized / canvasFloorRef?.current?.width) * originalFloorImageSize.width)
        const y_floor = Math.floor((y_floor_resized / canvasFloorRef?.current?.height) * originalFloorImageSize.height)
        setPointCameraAlert(false)
        setCameraMappingProperties((prevState) => ({
          ...prevState,
          points: prevState.points.map((point) => {
            return point.x_floor_resized === undefined && point.y_floor_resized === undefined
              ? {
                  ...point,
                  x_floor,
                  y_floor,
                  x_floor_resized,
                  y_floor_resized,
                }
              : point
          }),
        }))
      }
    }
  }

  // step 3 => undo camera mapping coordinated
  const handleUndoCamerasPoints = (cameraId: number) => {
    if (cameraMappingProperties.points.length > 0 && cameraMappingProperties?.cameraId === cameraId) {
      setPointCameraAlert(false)
      setCameraMappingProperties((prevState) => ({
        ...prevState,
        points: cameraMappingProperties.points.slice(0, cameraMappingProperties.points.length - 1),
      }))

      setSavedMappedCameras((prevState) => {
        return prevState.map((cameras) => {
          return cameras?.cameraId === cameraMappingProperties?.cameraId
            ? {
                cameraId: cameraMappingProperties?.cameraId,
                points: cameras.points.slice(0, cameras.points.length - 1),
              }
            : cameras
        })
      })
    }
  }

  // Save cameras locating points
  const { mutate: addUpdateCamerasLocating } = useMutation(
    () =>
      VisionAPI.addUpdateCameraLocatingCoors({
        floor_id: formik?.values.floorId as number,
        version_id:
          formik?.values?.floorEdit && floorVersionId
            ? (floorVersionId as number)
            : (formik?.values.versionId as number),
        values: cameraProperties as CameraFloorCoordinate[],
      }),
    {
      onSuccess: () => {
        handleOpenCamerasLocatingAlert()
        setTimeout(() => {
          setCamerasLocatingAlert(false)
        }, 2000)
      },
    }
  )

  // Save camera mapping points
  const { mutate: saveCamerasMapping } = useMutation(
    () =>
      VisionAPI.addUpdateCameraMappingCoors({
        version_id:
          formik?.values?.floorEdit && floorVersionId
            ? (floorVersionId as number)
            : (formik?.values.versionId as number),
        camera_id: cameraMappingProperties?.cameraId,
        values: cameraMappingProperties?.points as CameraFloorMapping[],
      }),
    {
      onSuccess: () => {
        handleOpenCameraMappingAlert()
        setTimeout(() => {
          setCameraMappingAlert(false)
        }, 2000)
      },
    }
  )

  // step 3 => save cameras changes
  const handleSaveCameraChanges = () => {
    if (cameraMappingProperties.points.length > 3) {
      const savedCamera = savedMappedCameras?.some((camera) => camera?.cameraId === selectedMappingCamera?.cameraId)
      setSavedCamerasChanges(true)
      saveCamerasMapping()
      setTimeout(() => {
        setSavedCamerasChanges(false)
      }, 2000)
      if (savedCamera) {
        // Edit on already saved camera
        setSavedMappedCameras((prevState) => {
          return prevState.map((cameraProps: ICameraMappingProperties) => {
            return cameraProps.cameraId === selectedMappingCamera?.cameraId
              ? {
                  ...cameraProps,
                  cameraId: cameraMappingProperties?.cameraId,
                  points: cameraMappingProperties?.points,
                }
              : cameraProps
          })
        })
      } else {
        setSavedMappedCameras([...savedMappedCameras, cameraMappingProperties])
      }
    }
  }

  //[Dismiss] dismiss existing camera mapping points
  const handleDismissCameraPoints = (cameraId: number, colorCode: string, cameraSrc: string, cameraName: string) => {
    setSaveCameraChangesAlert(false)
    const savedCamera = savedMappedCameras?.some((camera) => camera?.cameraId === cameraId)
    setSelectedMappingCamera({
      cameraName,
      colorCode,
      cameraSrc,
      cameraId,
    })

    if (savedCamera) {
      // Edit on already saved camera
      setSavedMappedCameras((prevState) => {
        return prevState.map((cameraProps: ICameraMappingProperties) => {
          return cameraProps.cameraId === selectedMappingCamera?.cameraId
            ? {
                ...cameraProps,
                cameraId: cameraMappingProperties?.cameraId,
                points: cameraMappingProperties?.points,
              }
            : cameraProps
        })
      })

      setCameraMappingProperties({
        cameraId,
        points: savedMappedCameras.find((camera) => camera.cameraId === cameraId)?.points || [],
      })

      setSelectedMappingCamera({
        cameraName,
        colorCode,
        cameraSrc,
        cameraId,
      })
    } else {
      setCameraMappingProperties({
        cameraId,
        points: [],
      })
    }
  }

  //[Continue] continue adding camera points
  const handleContinueCameraPoints = () => {
    setSaveCameraChangesAlert(false)
  }

  const checkCameraStatus = (cameraId: number): boolean => {
    const isCameraSaved =
      activeStep === 2
        ? cameraProperties?.find((camera) => camera.camera === cameraId)
        : savedMappedCameras?.find((cameraProp) => cameraProp.cameraId === cameraId)
    return isCameraSaved ? true : false
  }

  return (
    <FloorsContext.Provider
      value={{
        formik,
        canvasRef,
        canvasFloorRef,
        canvasCameraSrcRef,
        handleDropFloor,
        cameraProperties,
        selectedCamera,
        selectedMappingCamera,
        cameraMappingProperties,
        setCameraProperties,
        saveCameraChangesAlert,
        handleOnSelectCamera,
        handlePlaceCamera,
        handleMapCamerasToFloor,
        handleUndoCamerasPoints,
        handleSaveCameraChanges,
        pointCameraAlert,
        savedMappedCameras,
        savedCamerasChanges,
        setCamerasLocatingAlert,
        setCameraMappingAlert,
        handleDismissCameraPoints,
        handleContinueCameraPoints,
        addUpdateCamerasLocating,
        selectFloorVersion,
        isLoadingCameraCoors,
        floorEditSuccuss,
        resetAllData,
        setLocatingFloorImage,
        floorImageSizeLocating,
        setMappingFloorImage,
        floorImageSizeMapping,
        setMappingCameraImage,
        cameraImageSizeMapping,
        setActiveStep,
        activeStep,
        isSubmittingFloor,
        checkCameraStatus,
        camerasLocatingAlert,
        cameraMappingAlert,
        setFloorEditSuccuss,
        floorVersionId,
        deleteLocatedCamera,
        deleteCameraMapping,
        mappedCameras,
      }}
    >
      {children}
    </FloorsContext.Provider>
  )
}

export default FloorsContextProvider
