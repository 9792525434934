import { useMemo } from "react"
import { useQuery } from "react-query"
import { useLocation, useParams } from "react-router-dom"

import { Grid } from "@mui/material"

import { DateRangePicker, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import { intervalToDuration } from "date-fns"
import { Moment } from "moment"

import { VisionAPI } from "../../../API/VisionAPI"
import BetaTag from "../../../components/BetaTag"
import DownDaysBreakdownCard from "../../../components/GraphCards/DownDaysBreakdownCard"
import PaginatedBarGraphCard from "../../../components/GraphCards/PaginatedBarGraphCard"
import TotalDownTime from "../../../components/graphs/TotalDownTime"
import { useDateQuery } from "../../../hooks/useDateQuery"
import { FormattedDurationLog } from "../../../types/Custom/Interfaces"
import { TableColumn } from "../../../types/Custom/Types"
import { definitions } from "../../../types/Generated/apiTypes"
import { convertSecondsToReadableTime, formatDurationGraphData } from "../../../utils/genericHelpers"

import styles from "./NodesDownAnalytics.module.scss"

type NodeDownTimeAnalytics = definitions["NodeDownTimeAnalytics"]

type Params = {
  id?: number
}

type nodeInfo = {
  startDate: Moment
  endDate: Moment
  id: number
  name: string
}

const NodesDownAnalytics = () => {
  const location: { state: nodeInfo } = useLocation()
  const params: Params = useParams() //to extract node id from url

  const nodeInfo = location?.state

  const [startDate, setStartDate, endDate, setEndDate] = useDateQuery()

  const { data, isLoading } = useQuery<NodeDownTimeAnalytics, AxiosError>(
    ["fetchNodeDownLogs", params?.id, startDate?.format("YYYY-MM-DD"), endDate?.format("YYYY-MM-DD")],
    ({ queryKey }) =>
      VisionAPI.fetchNodeDownTimeAnalytics(queryKey[1] as number, {
        from_dt: queryKey[2] as string,
        to_dt: queryKey[3] as string,
      }),
    {
      enabled: !!startDate && !!endDate,
    }
  )

  const totalDuration = useMemo(() => {
    if (endDate !== null && startDate !== null) {
      const interval = intervalToDuration({
        start: startDate!.toDate(),
        end: endDate!.toDate(),
      })

      if (interval.days! <= 1 && interval.months! < 1) {
        return {
          days: 0,
          hours: 24,
          minutes: 0,
        }
      } else {
        return interval
      }
    }
    return {}
  }, [startDate, endDate])

  const { totalDownTimeDuration, downTimeData } = useMemo(() => {
    let totalDownTimeDuration = {}
    let downTimeData: FormattedDurationLog[] = []

    if (data && !isLoading) {
      const totalDownTimeInMilliSeconds = (data.total_down_time as number) * 1000
      totalDownTimeDuration = intervalToDuration({ start: 0, end: totalDownTimeInMilliSeconds })
      downTimeData = formatDurationGraphData(data.daily_down_time_logs)
    }

    return { totalDownTimeDuration, downTimeData }
  }, [data, isLoading])

  const tableColumns: TableColumn[] = [
    {
      title: "Date",
      field: "date",
      searchable: false,
    },
    {
      title: "Down Duration",
      field: "duration",
      searchable: false,
      render: (rowData: FormattedDurationLog) => <div>{convertSecondsToReadableTime(rowData.duration)}</div>,
    },
  ]

  return (
    <div className={styles.container}>
      <div className={styles.pageHeader}>
        <Typography
          variant="h2-regular"
          tooltip={`Information regarding your ${nodeInfo?.name} down time`}
          tooltipPlacement="right"
          tooltipIconSize={22}
          gutterBottom
          variantColor={2}
        >
          {nodeInfo?.name ?? "Node"}
        </Typography>
        <BetaTag />
      </div>
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        disabled={isLoading}
        onStartDateChange={setStartDate}
        onEndDateChange={setEndDate}
      />
      <Grid container spacing={2.5}>
        <Grid item xs={12} md={4} xl={3}>
          <TotalDownTime
            totalDownTime={totalDownTimeDuration}
            totalDuration={totalDuration}
            isLoading={isLoading}
            hasCardTitle
          />
        </Grid>
        <Grid item xs={12} md={8} xl={9}>
          <PaginatedBarGraphCard
            isLoading={isLoading}
            data={downTimeData}
            title="Node downtime"
            startDate={startDate?.format("YYYY-MM-DD")}
            endDate={endDate?.format("YYYY-MM-DD")}
            graphProps={{
              keys: ["duration"],
              indexBy: "date",
              customBarTitle: "- Down Duration",
              isDurationGraph: true,
            }}
            tableProps={{
              columns: tableColumns,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <DownDaysBreakdownCard data={data?.daily_down_time_logs} isLoading={isLoading} />
        </Grid>
      </Grid>
    </div>
  )
}
export default NodesDownAnalytics
