import React, { useState, useEffect } from "react"
import { useQuery } from "react-query"

import { useTheme, useMediaQuery } from "@mui/material"

import { DateRangePicker, InputChangeEvent, InputText, Select } from "@synapse-analytics/synapse-ui"
import { Typography } from "@synapse-analytics/synapse-ui"
import intervalToDuration from "date-fns/intervalToDuration"
import { DelimitedNumericArrayParam, StringParam, useQueryParams, withDefault } from "use-query-params"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../API/VisionAPI"
import { useDateQuery } from "../../hooks/useDateQuery"
import { useBranchesStore } from "../../store"
import { definitions } from "../../types/Generated/apiTypes"
import HistoryEvents from "./HistoryEvents"

import styles from "./SecurityEvents.module.scss"

type CamerasList = definitions["CameraList"]

const EventTypes = [
  { value: "", label: "All Events" },
  { value: "0", label: "Fire Detected" },
  { value: "1", label: "Violence Detected" },
  { value: "2", label: "Motion Detected" },
  { value: "3", label: "Intrusion Detected" },
  { value: "4", label: "Blacklist Car Detected" },
  { value: "5", label: "Blacklist Person Detected" },
  { value: "6", label: "Stray Animal Detected" },
]

const SecurityEvents = () => {
  const [startDate, setStartDate, endDate, setEndDate] = useDateQuery()

  const [query, setQuery] = useQueryParams({
    selectedCameras: withDefault(DelimitedNumericArrayParam, []),
    selectedIssueType: withDefault(StringParam, ""),
  })

  const theme = useTheme()
  const xSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  const [autoCompleteValue, setAutoCompleteValue] = useState<string | number>("")
  const [interval, setInterval] = useState<Duration>()

  const [selectedBranch] = useBranchesStore(
    (state: { selectedBranch: number | null }) => [state.selectedBranch],
    shallow
  )

  useEffect(() => {
    if (endDate !== null && startDate !== null) {
      const interval = intervalToDuration({
        start: startDate!.toDate(),
        end: endDate!.toDate(),
      })
      setInterval(interval)
    }
  }, [startDate, endDate])

  // fetch cameras list
  const { data: cameras = [], isLoading: isCameraListLoading } = useQuery<CamerasList[]>(
    ["fetchCamerasShortList", selectedBranch],
    ({ queryKey }) =>
      VisionAPI.fetchCamerasShortList({ branch: queryKey[1] as number, services: "violence,motion,fire,face,car" }),
    {
      enabled: !!selectedBranch,
    }
  )

  const handleEventTypeChange = (event: InputChangeEvent) => {
    setQuery({ selectedIssueType: event.target.value as string })
  }

  const handleAutoCompleteChange = (event: InputChangeEvent) => {
    const value = event.target.value
    setAutoCompleteValue(value)
  }

  const handleChangeCameras = (selectedCameras: number[]) => {
    setQuery({ selectedCameras })
  }

  return (
    <div className={styles.wrapper}>
      <Typography
        variant="h2-regular"
        tooltip="All past security incidents"
        tooltipPlacement="right"
        tooltipIconSize={22}
        gutterBottom
        variantColor={2}
      >
        {`${xSmallScreen ? "" : "Security"} Incidents History`}
      </Typography>
      <div className={styles.header}>
        <InputText
          value={autoCompleteValue}
          id="cameras-list"
          optionsWithValues={cameras?.map((camera) => {
            return {
              label: camera.name,
              value: camera.id!,
            }
          })}
          multipleModeProps={{
            isActive: true,
            selectedValues: query.selectedCameras as number[],
            setSelectedValues: handleChangeCameras,
            maxSelections: 9,
          }}
          placeholder="Search For A Camera"
          handleChange={handleAutoCompleteChange}
          loading={isCameraListLoading}
          width={235}
          variant="filled"
          menuProps={{
            menuMaxContent: true,
          }}
          hideDescription
        />
        <div className={styles.eventSelect}>
          <Select
            value={query.selectedIssueType}
            handleChange={handleEventTypeChange}
            id="event-type-selection"
            label="Event Type"
            isNotchedLabel
            optionsWithValues={EventTypes}
            menuProps={{
              menuMaxContent: true,
            }}
          />
        </div>
        <div className={styles.datePicker}>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            disableFuture
          />
        </div>
      </div>

      <HistoryEvents
        startDate={startDate}
        endDate={endDate}
        issueType={query.selectedIssueType}
        selectedCameras={query.selectedCameras as number[]}
        interval={interval!}
      />
    </div>
  )
}
export default SecurityEvents
