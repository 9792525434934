import React, { FC, useState, useEffect, useMemo } from "react"

import FaceIcon from "@mui/icons-material/Face"
import WcIcon from "@mui/icons-material/Wc"
import { Card } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"
import { Moment } from "moment"

import ButtonSwitch from "../../../../components/Buttons/ButtonSwitch"
import PaginatedBarGraph from "../../../../components/graphs/PaginatedBarGraph"
import { definitions } from "../../../../types/Generated/apiTypes"

import styles from "./CorridorCameraBreakdown.module.scss"

type CorridorCameraCount = definitions["CorridorCameraCount"]
type Corridor = definitions["Corridor"]

interface Props {
  logsData?: CorridorCameraCount[]
  loading?: boolean
  reference: any
  startDate: Moment | null
  endDate: Moment | null
  selectedCorridor?: Corridor
}

const CorridorCameraBreakdown: FC<Props> = ({ logsData, loading, reference, startDate, endDate, selectedCorridor }) => {
  const [activeTab, setActiveTab] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [graphData, setGraphData] = useState<Array<CorridorCameraCount> | undefined>(logsData)
  // only update graph  data if returning data is valid, else preserve state
  useEffect(() => {
    if (!loading && logsData) {
      setGraphData(logsData)
    }
  }, [logsData, loading])

  // calculate max number of pages
  const maxPages = useMemo(() => {
    if (graphData) {
      return Math.ceil(graphData.length / 10)
    }
  }, [graphData])

  // resetting page number on changing start/end dates
  useEffect(() => {
    if (startDate && endDate) {
      setCurrentPage(1)
    }
  }, [endDate, startDate, activeTab, selectedCorridor])

  return (
    <Card className={styles.wrapper} ref={reference}>
      <div className={styles.header}>
        <Typography variant="h2-bold" variantColor={2} noWrap>
          Corridor {selectedCorridor?.name} Broken Down By Camera{" "}
          {!loading && graphData && graphData?.length > 10 && `: Page ${currentPage}/${maxPages}`}
        </Typography>
        <ButtonSwitch
          activePage={activeTab}
          pages={["Gender", "Age"]}
          handleSelectButton={setActiveTab}
          disabled={loading}
          pagesIcons={[<WcIcon />, <FaceIcon />]}
        />
      </div>
      <PaginatedBarGraph
        barGraphData={graphData}
        isLoading={loading}
        key={`${selectedCorridor ? selectedCorridor?.name : "Corridor"}_${activeTab === 0 ? "Age" : "Gender"}`}
        ageGenderKeys={activeTab === 0 ? ["male_count", "female_count"] : ["adult_count", "child_count"]}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        indexBy="camera"
        height={300}
      />
    </Card>
  )
}
export default CorridorCameraBreakdown
