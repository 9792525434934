import { FC } from "react"

import BlurLinearOutlinedIcon from "@mui/icons-material/BlurLinearOutlined"
import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled"
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun"
import DoNotStepIcon from "@mui/icons-material/DoNotStep"
import GppBadOutlinedIcon from "@mui/icons-material/GppBadOutlined"
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment"
import NoTransferIcon from "@mui/icons-material/NoTransfer"
import PeopleIcon from "@mui/icons-material/People"
import PersonOffIcon from "@mui/icons-material/PersonOff"
import StrayAnimalIcon from "@mui/icons-material/Pets"
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt"

import { Tag } from "@synapse-analytics/synapse-ui"

import MCPIcon from "../assets/servicesIcons/mcp.svg?react"

import styles from "./ServiceEventTag.module.scss"

interface Props {
  serviceEventType:
    | "counter"
    | "heatmap"
    | "car"
    | "mcp"
    | "face"
    | "violence"
    | "motion"
    | "fire"
    | "intrusion"
    | "blackListCar"
    | "blackListPerson"
    | "stray_animals"
  hasMargin?: boolean
  size?: "small" | "regular"
}

type Variant = "indigo" | "negative" | "pink" | "cyan" | "violet" | "orange" | "warning" | "mint" | "default"

type Service = {
  icon: JSX.Element
  label: string
  variant: Variant
}

type Services = {
  [key: string]: Service
}

const ServiceCard: FC<Props> = ({ serviceEventType, hasMargin, size }) => {
  const services: Services = {
    mcp: {
      icon: <MCPIcon />,
      label: "MCP",
      variant: "indigo",
    },
    violence: {
      icon: <GppBadOutlinedIcon className={styles.icon} />,
      label: "Violence",
      variant: "negative",
    },
    heatmap: {
      icon: <BlurLinearOutlinedIcon className={styles.icon} />,
      label: "Heatmap",
      variant: "pink",
    },
    counter: {
      icon: <PeopleIcon className={styles.icon} />,
      label: "Counter",
      variant: "cyan",
    },
    car: {
      icon: <DirectionsCarFilledIcon className={styles.icon} />,
      label: "Car",
      variant: "violet",
    },
    face: {
      icon: <SentimentSatisfiedAltIcon className={styles.icon} />,
      label: "Faces",
      variant: "orange",
    },
    fire: {
      icon: <LocalFireDepartmentIcon className={styles.icon} />,
      label: "Fire",
      variant: "warning",
    },
    motion: {
      icon: <DirectionsRunIcon className={styles.icon} />,
      label: "Motion",
      variant: "mint",
    },
    intrusion: {
      icon: <DoNotStepIcon className={styles.icon} />,
      label: "Intrusion",
      variant: "pink",
    },
    blackListCar: {
      icon: <NoTransferIcon className={styles.icon} />,
      label: "Blacklist Car",
      variant: "default",
    },
    blackListPerson: {
      icon: <PersonOffIcon className={styles.icon} />,
      label: "Blacklist Person",
      variant: "default",
    },
    stray_animals: {
      icon: <StrayAnimalIcon className={styles.icon} />,
      label: "Stray Animals",
      variant: "orange",
    },
  }
  return (
    <Tag
      variant={services[serviceEventType].variant}
      style={{
        marginRight: hasMargin ? "8px" : "",
      }}
      size={size || "regular"}
      className={`${serviceEventType.includes("blackList") && styles.blacklistTag}`}
    >
      {services[serviceEventType].label} <div className={styles.iconWrapper}>{services[serviceEventType].icon}</div>
    </Tag>
  )
}
export default ServiceCard
