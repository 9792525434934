import { FC } from "react"

import { Typography } from "@synapse-analytics/synapse-ui"

import { PieChartGraphData } from "../../types/Custom/Interfaces"
import GraphEmptyState from "../graphs/GraphEmptyState"
import GraphLoadingState from "../graphs/GraphLoadingState"
import PieChart from "../graphs/PieChart"

import styles from "./PieChartCard.module.scss"

/**
 * Interface for the PieChartCard component props.
 */
interface Props {
  /**
   * The data to be displayed in the pie chart.
   */
  data: PieChartGraphData[]

  /**
   * Indicates if the component is currently loading data.
   */
  isLoading?: boolean

  /**
   * The title to be displayed above the pie chart.
   */
  title?: string

  /**
   * The subtitle to be displayed below the title.
   */
  subTitle?: string

  /**
   * The label to be displayed at the center of the pie chart.
   */
  pieChartLabel?: string

  /**
   * The value to be displayed at the center of the pie chart.
   */
  pieChartValue?: number

  /**
   * The height of the pie chart.
   */
  graphHeight?: number

  /**
   * A reference to the component for exporting purposes.
   */
  exportingRef?: React.MutableRefObject<any>
}

/**
 * The PieChartCard component displays a pie chart with customizable center label and metric.
 * It also handles loading and empty states.
 *
 * @param {Props} props - The props for the PieChartCard component.
 * @returns A JSX element representing the PieChartCard component.
 */
const PieChartCard: FC<Props> = ({
  data,
  subTitle,
  title,
  isLoading,
  pieChartLabel,
  pieChartValue,
  graphHeight,
  exportingRef,
}) => {
  const isEmpty = !data || (data && data.length < 1)
  return (
    <div className={styles.cardWrapper} ref={exportingRef}>
      <div>
        <Typography variant="h2-bold" variantColor={2}>
          {title}
        </Typography>
        {subTitle && (
          <Typography variant="p" variantColor={2}>
            {subTitle}
          </Typography>
        )}
      </div>
      {isLoading ? (
        <GraphLoadingState />
      ) : isEmpty ? (
        <GraphEmptyState />
      ) : (
        <PieChart
          centerLabel={pieChartLabel}
          centerMetric={pieChartValue ?? "No data"}
          data={data}
          height={graphHeight}
        />
      )}
    </div>
  )
}
export default PieChartCard
