import { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"

import DeleteOutline from "@mui/icons-material/DeleteOutline"

import { Action } from "@material-table/core"
import { NotificationUtils, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import moment from "moment"

import { VisionAPI } from "../../../API/VisionAPI"
import WarningDialog from "../../../components/WarningDialog"
import TokensTable from "../../../components/tables/GenericTable"
import { TableColumn } from "../../../types/Custom/Types"
import { definitions } from "../../../types/Generated/apiTypes"
import AddNewTokenDialog from "./AddNewTokenDialog"

import styles from "../APIIntegration.module.scss"

type AccessToken = definitions["AuthToken"]

const AccessTokensTable = () => {
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false)
  const [selectedTokenToDelete, setSelectedTokenToDelete] = useState<AccessToken>()

  const queryClient = useQueryClient()

  const { data: accessTokens, isLoading } = useQuery<AccessToken[], AxiosError>(
    "fetchAccessTokensList",
    VisionAPI.fetchAccessTokensList
  )

  const { mutate: deleteAccessToken, isLoading: isDeletingToken } = useMutation(
    (id?: number) => VisionAPI.deleteAccessToken(id),
    {
      onSuccess: () => {
        setIsDeleteConfirmationOpen(false)
        NotificationUtils.toast("Access Token deleted successfully", {
          snackBarVariant: "positive",
        })
        queryClient?.invalidateQueries("fetchAccessTokensList")
      },
    }
  )

  const tableColumns: TableColumn[] = [
    {
      title: "Token Name",
      field: "name",
      searchable: false,
      render: (rowData: AccessToken) => (
        <div title={rowData.name}>{rowData.name.length > 19 ? rowData.name.slice(0, 17) + "..." : rowData.name}</div>
      ),
    },
    {
      title: "Created",
      field: "created_at",
      searchable: false,
      render: (rowData: AccessToken) => <div>{moment(rowData.created_at).format("MMM DD, YYYY")}</div>,
    },
    {
      title: "Expires In",
      field: "expires_at",
      searchable: false,
      render: (rowData: AccessToken) => <div>{moment(rowData.expires_at).toNow(true)}</div>,
    },
  ]

  const isEmptyTable = (accessTokens && accessTokens?.length < 1) || !accessTokens

  const handleOpenDeleteDialog = (rowData: AccessToken) => {
    setSelectedTokenToDelete(rowData)
    setIsDeleteConfirmationOpen(true)
  }

  let actions: Action<AccessToken>[] = [
    {
      icon: () => <DeleteOutline />,
      tooltip: "Delete Access Token",
      onClick: (_e: any, data: any) => handleOpenDeleteDialog(data),
    },
  ]

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography variant="h3-bold" variantColor={2}>
          Access Tokens
        </Typography>
        <AddNewTokenDialog />
      </div>

      <TokensTable
        title="Access Tokens"
        columns={tableColumns}
        data={accessTokens}
        isEmpty={isEmptyTable}
        isLoading={isLoading}
        actions={actions}
        hasExport={false}
      />

      <WarningDialog
        confirmationText={`delete ${selectedTokenToDelete?.name} access token?`}
        isOpen={isDeleteConfirmationOpen}
        onCancel={() => setIsDeleteConfirmationOpen(false)}
        onConfirm={() => deleteAccessToken(selectedTokenToDelete?.id)}
        isLoading={isDeletingToken}
        actionTitle="Delete"
        cancelTitle="Cancel"
        content="Be aware by deleting this access token, this action can't be undone."
        dialogTitle={`Delete “${selectedTokenToDelete?.name}” access token?`}
      />
    </div>
  )
}
export default AccessTokensTable
