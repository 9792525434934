import moment from "moment"
import queryString from "query-string"
import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { createWithEqualityFn } from "zustand/traditional"

import { StoreApiState, CredentialsStoreState, BranchesStoreState, DateStoreState } from "./store.interfaces"

/**
 * Compares two objects for deep equality.
 *
 * @param prev - The first object to compare.
 * @param next - The second object to compare.
 * @returns A boolean indicating whether the two objects are deeply equal.
 */
const deepEqual = (prev: any, next: any): boolean => {
  return JSON.stringify(prev) === JSON.stringify(next)
}

// Function to determine WebSocket (WS) protocol based on the provided API protocol
/**
 * @param apiProtocol - The API protocol to determine the WS protocol for.
 * @returns The WS protocol determined based on the provided API protocol.
 */
export const getWsProtocol = (apiProtocol: string): string => {
  let wsProtocol: string | undefined
  if (apiProtocol === "http:") {
    wsProtocol = "ws:"
  } else if (apiProtocol === "https:") {
    wsProtocol = "wss:"
  } else {
    throw Error("Invalid apiProtocol Set")
  }
  return wsProtocol
}

// Define and export the store instance
/**
 * This module creates a Zustand store named `storeApi` to manage API configuration settings.
 * It exports a store instance that includes initial state values for WebSocket protocol, API protocol, hostname, and port.
 * These values are retrieved from the `window.__RUNTIME_CONFIG__` object during runtime.
 * Additionally, a method `setApiSettings` is provided (commented out) to update API settings dynamically if needed.
 */

/**
 * Creates a Zustand store instance for managing API configuration settings.
 *
 * @returns A Zustand store instance with initial state values for WebSocket protocol, API protocol, hostname, port, and client name.
 * These values are retrieved from the `window.__RUNTIME_CONFIG__` object during runtime.
 */
export const storeApi = create<StoreApiState>((set) => ({
  /**
   * WebSocket protocol determined based on the API protocol.
   */
  apiWsProtocol: getWsProtocol(`${window.__RUNTIME_CONFIG__.API_PROTOCOL}:`),
  /**
   * API protocol retrieved from runtime configuration.
   */
  apiProtocol: `${window.__RUNTIME_CONFIG__.API_PROTOCOL}`,
  /**
   * API hostname retrieved from runtime configuration.
   */
  apiHostname: window.__RUNTIME_CONFIG__.API_HOSTNAME,
  /**
   * API port retrieved from runtime configuration.
   */
  apiPort: window.__RUNTIME_CONFIG__.API_PORT,
  /**
   * Client name retrieved from runtime configuration.
   */
  clientName: window.__RUNTIME_CONFIG__.CLIENT_NAME,
}))

/**
 * Creates a Zustand store instance for managing user credentials.
 *
 * @returns A Zustand store instance with initial state values for current user, login error, and network error.
 */
export const useCredentialsStore = create<CredentialsStoreState>()((set) => ({
  currentUser: "",
  loginError: false,
  networkError: false,
  setCurrenUser: (user) => set(() => ({ currentUser: user })),
  setLoginError: (loginError) => set(() => ({ loginError })),
  setNetworkError: (networkError) => set(() => ({ networkError })),
}))

/**
 * Creates a Zustand store instance for managing branch selection and analytics settings.
 *
 * @returns A Zustand store instance with initial state values for selected branch and multiple analytics setting.
 */
export const useBranchesStore = createWithEqualityFn<BranchesStoreState>()(
  devtools(
    () => ({
      selectedBranch: null,
      isMultipleAnalytics: false,
    }),
    deepEqual
  )
)

/**
 * Sets the selected branch in the store.
 *
 * @param branch - The branch to set as the selected branch.
 */
export const setSelectedBranch = (branch: number | null) =>
  useBranchesStore.setState(() => ({ selectedBranch: branch }))

/**
 * Sets the multiple analytics setting in the store.
 *
 * @param value - The value to set for the multiple analytics setting.
 */
export const setIsMultipleAnalytics = (value: boolean) =>
  useBranchesStore.setState(() => ({ isMultipleAnalytics: value }))

/**
 * Parses the query string from the current window location to extract query parameters.
 *
 * @returns An object containing the parsed query parameters.
 */
const queryParams = queryString.parse(window.location.search)

/**
 * Creates a Zustand store instance for managing date settings.
 *
 * @returns A Zustand store instance with initial state values for start date, end date, and single day.
 */
export const useDateStore = create<DateStoreState>((set) => ({
  startDate:
    queryParams.startDate && typeof queryParams.startDate === "string"
      ? moment(queryParams.startDate)
      : moment().subtract(7, "days"),
  endDate: queryParams.endDate && typeof queryParams.endDate === "string" ? moment(queryParams.endDate) : moment(),
  day: queryParams.day && typeof queryParams.day === "string" ? moment(queryParams.day) : moment(),
  setStartDate: (date) => set(() => ({ startDate: date })),
  setEndDate: (date) => set(() => ({ endDate: date })),
  setSingleDay: (date) => set(() => ({ day: date })),
}))
