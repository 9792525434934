import { useRef, FC, useMemo } from "react"
import { useParams } from "react-router-dom"

import adultsIcon from "../../../../assets/adultsIcon.svg"
import childrenIcon from "../../../../assets/childrenIcon.svg"
import femaleIcon from "../../../../assets/femaleIcon.svg"
import maleIcon from "../../../../assets/maleIcon.svg"
import StatsCard from "../../../../components/StatsCard"
import { definitions } from "../../../../types/Generated/apiTypes"
import { calculateSumPerTime, calculateCountTotals } from "../../../../utils/counterUtils"
import { calculateShopsTotalCounts, calculateTableData, getEntityLogs } from "../../../../utils/shopUtils"

import styles from "./NumericStats.module.scss"

/**
 * Type definitions for CounterLogs, EntityGroupsCounts, and Shop
 * These are imported from the Generated/apiTypes definitions
 */
type CounterLogs = definitions["CounterLogs"]
type EntityGroupsCounts = definitions["EntityGroupsCounts"]
type Shop = definitions["Shop"]

/**
 * Props interface for NumericStats component
 */
interface Props {
  /**
   * Array of CounterLogs for shop counter data.
   */
  shopsCounterData?: CounterLogs[]
  /**
   * EntityGroupsCounts for entity groups counts.
   */
  entityGroupsCounts?: EntityGroupsCounts
  /**
   * Boolean indicating if data is loading.
   */
  loading: boolean
  /**
   * Optional Shop for shop entities.
   */
  shopEntities: Shop | undefined
  /**
   * Boolean indicating if shop entities are loading.
   */
  shopEntitiesLoading: boolean
}

/**
 * MatchParams interface for useParams hook
 * @property id - Optional string for match parameter ID
 */
interface MatchParams {
  id?: string
}

/**
 * NumericStats component
 * This component displays numeric statistics for tenants, including visitor counts, demographics, and fair share data.
 * It utilizes various utility functions to calculate and format the data for display.
 *
 * @param props - Props object containing shop counter data, entity groups counts, loading states, tenant ID, shop entities, and shop entities loading state
 * @returns JSX.Element - The NumericStats component
 */
const NumericStats: FC<Props> = ({
  shopsCounterData,
  loading,
  shopEntities,
  shopEntitiesLoading,
  entityGroupsCounts,
}) => {
  const visitorsRef = useRef(null)
  let params: MatchParams = useParams()

  /**
   * useMemo hook to calculate logs sum per time
   * This hook calculates the sum of logs per time period based on the shopsCounterData and useParams hook.
   * It returns an empty array if the data is loading or if there is no shopsCounterData.
   *
   * @returns Array - Array of logs sum per time
   */
  const logsSumPerTime = useMemo(() => {
    if (!loading && shopsCounterData) {
      return calculateSumPerTime(getEntityLogs(shopsCounterData, parseInt(params?.id!)))
    }
    return []
  }, [loading, shopsCounterData, params])

  /**
   * useMemo hook to calculate shop counts
   * This hook calculates the total counts for shops based on the shopsCounterData.
   * It returns an empty array if the data is loading or if there is no shopsCounterData.
   *
   * @returns Array - Array of shop counts
   */
  const shopCounts = useMemo(() => {
    if (!loading && shopsCounterData) {
      return calculateShopsTotalCounts(shopsCounterData)
    }
    return []
  }, [loading, shopsCounterData])

  /**
   * useMemo hook to calculate fair share data
   * This hook calculates the fair share data for shops based on the logsSumPerTime, shopCounts, and shopEntities.
   * It returns an empty array if the data is loading or if there is no shopEntities.
   *
   * @returns Array - Array of fair share data
   */
  const fairShareData = useMemo(() => {
    if (logsSumPerTime && !loading && !shopEntitiesLoading) {
      return calculateTableData(shopEntities, shopCounts, [{}])
    }
    return []
  }, [logsSumPerTime, shopEntities, loading, shopCounts, shopEntitiesLoading])

  /**
   * useMemo hook to calculate numeric statistics
   * This hook calculates the numeric statistics based on the logsSumPerTime.
   * It returns a default object if the data is loading or if there is no logsSumPerTime.
   *
   * @returns Object - Object containing numeric statistics
   */
  const numericStat = useMemo(() => {
    if (logsSumPerTime && !loading && !shopEntitiesLoading) {
      return calculateCountTotals(logsSumPerTime, "tenantDetails")
    }
    return {
      count_in_sum: 0,
      count_out_sum: 0,
      dwell_left: 0,
      dwell_entered: 0,
      stood_left: 0,
      stood_entered: 0,
      male_count_in: 0,
      female_count_in: 0,
      male_count_out: 0,
      female_count_out: 0,
      inside: 0,
      inside_male: 0,
      inside_female: 0,
      adult_count: 0,
      child_count: 0,
    }
  }, [logsSumPerTime, loading, shopEntitiesLoading])

  // Destructuring numericStat for easier access to its properties
  const {
    count_in_sum: totalCountInSum,
    female_count_in: totalFemaleCountIn,
    child_count: totalChildCount,
    adult_count: totalAdultCount,
    male_count_in: totalMaleCountIn,
    dwell_entered: totalDwellEntered,
    dwell_left: totalDwellLeft,
    stood_entered: totalStoodEntered,
    stood_left: totalStoodLeft,
  } = numericStat

  /**
   * useMemo hook to calculate fair share of category
   * This hook calculates the fair share of category based on the fairShareData and useParams hook.
   * It returns 0 if the data is loading or if there is no fairShareData.
   *
   * @returns Number - Fair share of category
   */
  const fairShareOfCategory = useMemo(() => {
    if (fairShareData && fairShareData.length > 0 && !loading) {
      for (let i = 0; i < fairShareData.length; i++) {
        if (fairShareData[i].id === parseInt(params?.id!) && fairShareData[i].area) {
          return (
            fairShareData[i]["Count In"] /
            fairShareData[i].category_count /
            (fairShareData[i].area! / fairShareData[i].area_of_category)
          )
        }
      }
    }
    return 0
  }, [fairShareData, loading, params])

  /**
   * useMemo hook to calculate fair share of subcategory
   * This hook calculates the fair share of subcategory based on the fairShareData and useParams hook.
   * It returns 0 if the data is loading or if there is no fairShareData.
   *
   * @returns Number - Fair share of subcategory
   */
  const fairShareOfSubcategory = useMemo(() => {
    if (fairShareData && fairShareData.length > 0 && !loading) {
      for (let i = 0; i < fairShareData.length; i++) {
        if (fairShareData[i].id === parseInt(params?.id!) && fairShareData[i].area) {
          return (
            fairShareData[i]["Count In"] /
            fairShareData[i].subcategory_count /
            (fairShareData[i].area! / fairShareData[i].area_of_subcategory)
          )
        }
      }
    }
    return 0
  }, [fairShareData, loading, params])

  /**
   * Calculates and memoizes the percentage of females based on the totalMaleCountIn and totalFemaleCountIn.
   * If either count is undefined, it returns 50 as a default value.
   *
   * @returns Number - Percentage of females
   */
  const femalePercentage = useMemo(() => {
    return totalMaleCountIn && totalFemaleCountIn
      ? (totalFemaleCountIn / (totalMaleCountIn + totalFemaleCountIn)) * 100
      : 50
  }, [totalMaleCountIn, totalFemaleCountIn])

  /**
   * Calculates and memoizes the percentage of males based on the totalMaleCountIn and totalFemaleCountIn.
   * If either count is undefined, it returns 100 as a default value.
   *
   * @returns Number - Percentage of males
   */
  const malePercentage = useMemo(() => {
    return totalMaleCountIn && totalFemaleCountIn
      ? (totalMaleCountIn / (totalMaleCountIn + totalFemaleCountIn)) * 100
      : 100
  }, [totalMaleCountIn, totalFemaleCountIn])

  /**
   * Calculates and memoizes the percentage of children based on the totalChildCount and totalAdultCount.
   * If either count is undefined, it returns 50 as a default value.
   *
   * @returns Number - Percentage of children
   */
  const childrenPercentage = useMemo(() => {
    return totalChildCount && totalAdultCount ? (totalChildCount / (totalChildCount + totalAdultCount)) * 100 : 50
  }, [totalChildCount, totalAdultCount])

  /**
   * Calculates and memoizes the percentage of adults based on the totalChildCount and totalAdultCount.
   * If either count is undefined, it returns 100 as a default value.
   *
   * @returns Number - Percentage of adults
   */
  const adultPercentage = useMemo(() => {
    return totalChildCount && totalAdultCount ? (totalAdultCount / (totalChildCount + totalAdultCount)) * 100 : 100
  }, [totalChildCount, totalAdultCount])

  /**
   * Calculates and memoizes the percentage of dwell and enter based on the totalDwellEntered and totalDwellLeft.
   * If either count is undefined, it returns 50 as a default value.
   *
   * @returns Number - Percentage of dwell and enter
   */
  const dwellAndEnterPercentage = useMemo(() => {
    return totalDwellEntered && totalDwellLeft ? (totalDwellEntered / (totalDwellLeft + totalDwellEntered)) * 100 : 50
  }, [totalDwellEntered, totalDwellLeft])

  /**
   * Calculates and memoizes the percentage of stood and enter based on the totalStoodEntered and totalStoodLeft.
   * If either count is undefined, it returns 50 as a default value.
   *
   * @returns Number - Percentage of stood and enter
   */
  const stoodAndEnterPercentage = useMemo(() => {
    return totalStoodEntered && totalStoodLeft ? (totalStoodEntered / (totalStoodLeft + totalStoodEntered)) * 100 : 50
  }, [totalStoodEntered, totalStoodLeft])

  /**
   * Calculates and memoizes the percentage of category fair share based on the fairShareOfCategory.
   * If fairShareOfCategory is undefined or less than or equal to 0, it returns 0.
   *
   * @returns Number - Percentage of category fair share
   */
  const categoryFairSharePercentage = useMemo(() => {
    return fairShareOfCategory && fairShareOfCategory > 0 ? (fairShareOfCategory / (1 + fairShareOfCategory)) * 100 : 0
  }, [fairShareOfCategory])

  /**
   * Calculates and memoizes the percentage of subcategory fair share based on the fairShareOfSubcategory.
   * If fairShareOfSubcategory is undefined or less than or equal to 0, it returns 0.
   *
   * @returns Number - Percentage of subcategory fair share
   */
  const subcategoryFairSharePercentage = useMemo(() => {
    return fairShareOfSubcategory && fairShareOfSubcategory > 0
      ? (fairShareOfSubcategory / (fairShareOfSubcategory + 1)) * 100
      : 0
  }, [fairShareOfSubcategory])

  return (
    <div className={styles.statsCardsWrapper}>
      {/* Visitors In Today Stats Card */}
      <StatsCard
        title="Total Count"
        distributionData={[
          {
            leftBar: {
              percentage: femalePercentage,
              label: "Female",
              value: totalFemaleCountIn,
              color: "var(--pink-background-2)",
              tooltipTitle: `Female : ${totalFemaleCountIn?.toLocaleString()}`,
              icon: femaleIcon,
            },
            rightBar: {
              percentage: malePercentage,
              label: "Male",
              value: totalMaleCountIn,
              color: "var(--blue-background-2)",
              tooltipTitle: `Male : ${totalMaleCountIn?.toLocaleString() || "No data"}`,
              icon: maleIcon,
            },
            title: "Gender Distribution",
          },
          {
            leftBar: {
              percentage: childrenPercentage,
              label: "Children",
              value: totalChildCount,
              color: "var(--teal-background-2)",
              tooltipTitle: `Children : ${totalChildCount?.toLocaleString()}`,
              icon: childrenIcon,
            },
            rightBar: {
              percentage: adultPercentage,
              label: "Adults",
              value: totalAdultCount,
              color: "var(--purple-background-2)",
              tooltipTitle: `Adults : ${totalAdultCount?.toLocaleString()}`,
              icon: adultsIcon,
            },
            title: "Age Distribution",
          },
          {
            leftBar: {
              percentage: dwellAndEnterPercentage,
              label: "Dwelled and entered",
              value: totalDwellEntered,
              color: "#FFDBCC",
              tooltipTitle: `Dwelled and entered : ${totalDwellEntered?.toLocaleString()}`,
            },
            rightBar: {
              percentage: 100 - dwellAndEnterPercentage,
              label: "Dwelled and left",
              value: totalDwellLeft,
              color: "#CCFFD1",
              tooltipTitle: `Dwelled and left : ${totalDwellLeft?.toLocaleString() || "No data"}`,
            },
            title: "Dwelling Distribution",
          },
          {
            leftBar: {
              percentage: stoodAndEnterPercentage,
              label: "Stood and entered",
              value: totalStoodEntered,
              color: "#F7F4A6",
              tooltipTitle: `Stood and entered : ${totalStoodEntered?.toLocaleString()}`,
            },
            rightBar: {
              percentage: 100 - stoodAndEnterPercentage,
              label: "Stood and left",
              value: totalStoodLeft,
              color: "#DCCCFF",
              tooltipTitle: `Stood and left : ${totalStoodLeft?.toLocaleString() || "No data"}`,
            },
            title: "Standing Distribution",
          },
          {
            sectionTitle: "Fair Share",
            leftBar: {
              percentage: categoryFairSharePercentage,
              label: "0",
              value: fairShareOfCategory,
              color: fairShareOfCategory! < 1 ? "var(--red-background-2)" : "var(--green-background-2)",
              tooltipTitle: `Category Fair Share : ${fairShareOfCategory?.toLocaleString()}`,
            },
            rightBar: {
              percentage: 100 - categoryFairSharePercentage,
              label: "",
              value: "",
              color: "var(--indigo-background-2)",
              tooltipTitle: "",
            },
            title: "Fair Share Category Distribution",
            isRaceBar: true,
          },
          {
            leftBar: {
              percentage: subcategoryFairSharePercentage,
              label: "0",
              value: fairShareOfSubcategory,
              color: fairShareOfSubcategory < 1 ? "var(--red-background-2)" : "var(--green-background-2)",
              tooltipTitle: `Sub-Category Fair Share : ${fairShareOfSubcategory?.toLocaleString()}`,
            },
            rightBar: {
              percentage: 100 - subcategoryFairSharePercentage,
              label: "",
              value: "",
              color: "var(--indigo-background-2)",
              tooltipTitle: "",
            },
            isRaceBar: true,
            title: "Fair Share Sub-Category Distribution",
          },
        ]}
        numericStat={totalCountInSum}
        isNumericalStatLoading={loading}
        areBarsLoading={loading}
        reference={visitorsRef}
        entityGroupsCounts={entityGroupsCounts}
      />
    </div>
  )
}
export default NumericStats
