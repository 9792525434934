import { useRef, useState, useEffect, useMemo } from "react"
import { useQuery } from "react-query"

import GetAppIcon from "@mui/icons-material/GetApp"
import { Grid } from "@mui/material"

import { Typography, Button } from "@synapse-analytics/synapse-ui"
import { DateRangePicker } from "@synapse-analytics/synapse-ui"
import intervalToDuration from "date-fns/intervalToDuration"
import domtoimage from "dom-to-image"
import FileSaver from "file-saver"
import JSZip from "jszip"
import { StringParam, useQueryParams, withDefault } from "use-query-params"

import { VisionAPI } from "../../../API/VisionAPI"
import CarsAverageOccupancy from "../../../components/GraphCards/CheckboxesLineGraphCard"
import PaginatedBarGraph from "../../../components/GraphCards/PaginatedBarGraphCard"
import { useDateQuery } from "../../../hooks/useDateQuery"
import { EntrancesBarData, TableColumn } from "../../../types/Custom/Types"
import { definitions } from "../../../types/Generated/apiTypes"
import { convertDataToColoredLineGraphAndTable, sortAndColorizeLogs } from "../../../utils/genericHelpers"
import CarsFilters from "../components/CarsFilters"
import CarsAverageTime from "./CarsAverageTime"
import CarsAverageVisits from "./CarsAverageVisits"

import styles from "./CarsAnalytics.module.scss"

type NewReturning = definitions["NewReturning"]
type CarCounterLogs = definitions["CarCounterLogs"]
type TotalTimeSpent = definitions["TotalTimeSpent"]
type WeekDaysAverageCount = definitions["WeekDaysAverageCount"]
type CarGatesCount = definitions["CarCountsByGate"]

const CarsAnalytics = () => {
  const [query, setQuery] = useQueryParams({
    selectedListOfInterest: withDefault(StringParam, ""),
    selectedCarBrand: withDefault(StringParam, ""),
    selectedCarType: withDefault(StringParam, ""),
  })

  const refCountsVisits = useRef<any>()
  const refCountsTime = useRef<any>()
  const refAverageTime = useRef<any>()
  const refAverageOccupancy = useRef<any>()
  const refAverageHourly = useRef<any>()
  const refGatesCounts = useRef<any>()

  const [timeGrain, setTimeGrain] = useState<"hour" | "day" | null>(null)
  const [interval, setInterval] = useState<Duration>()
  const [isLoading, setIsLoading] = useState(true)
  const [startDate, setStartDate, endDate, setEndDate] = useDateQuery()

  // calculating the interval and time grain based on start & end dates picked.
  // every time start and end dates are changed
  useEffect(() => {
    if (endDate !== null && startDate !== null) {
      const interval = intervalToDuration({
        start: startDate?.toDate(),
        end: endDate?.toDate(),
      })
      setInterval(interval)
      if (interval.days! > 1 || interval.months! >= 1) {
        setTimeGrain("day")
      } else {
        setTimeGrain("hour")
      }
    }
  }, [startDate, endDate, timeGrain])

  // new vs returning car logs query
  const { data: carsTotalCounts, isLoading: carsTotalCountsLoading } = useQuery<NewReturning>(
    [
      "fetchCarsTotalCounts",
      startDate?.format("YYYY-MM-DD"),
      endDate?.format("YYYY-MM-DD"),
      query.selectedListOfInterest,
      query.selectedCarBrand,
      query.selectedCarType,
    ],
    ({ queryKey }) =>
      VisionAPI.fetchCarsTotalCounts({
        start_dt: queryKey[1] as string,
        end_dt: queryKey[2] as string,
        list_of_interest: queryKey[3] as number,
        brand: queryKey[4] as string,
        car_type: queryKey[5] as string,
      }),
    {
      enabled: !!endDate,
    }
  )
  // daily/hourly line graph logs query
  const { data: carCounterLogs, isLoading: carCounterLogsLoading } = useQuery<CarCounterLogs[]>(
    [
      "fetchCarCounterLogs",
      new Date(startDate!.toDate().setHours(3, 0, 0, 0)),
      endDate ? new Date(endDate!.toDate().setHours(timeGrain === "hour" ? 3 : 23, 59, 59, 999)) : null,
      timeGrain,
      query.selectedListOfInterest,
      query.selectedCarBrand,
      query.selectedCarType,
    ],
    ({ queryKey }) =>
      VisionAPI.fetchCarParkingCounterStats({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        date_slice: queryKey[3] as string,
        list_of_interest: queryKey[4] as number,
        brand: queryKey[5] as string,
        car_type: queryKey[6] as string,
      }),
    {
      enabled: !!endDate && !!timeGrain,
    }
  )
  // average time spent inside circular progress and bar graph logs
  const { data: totalTimeSpent, isLoading: totalTimeSpentLoading } = useQuery<TotalTimeSpent>(
    [
      "fetchTotalTimeSpent",
      startDate?.format("YYYY-MM-DD"),
      endDate?.format("YYYY-MM-DD"),
      query.selectedListOfInterest,
      query.selectedCarBrand,
      query.selectedCarType,
    ],
    ({ queryKey }) =>
      VisionAPI.fetchCarsTimeSpentInside({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        list_of_interest: queryKey[3] as number,
        brand: queryKey[4] as string,
        car_type: queryKey[5] as string,
      }),
    {
      enabled: !!endDate,
    }
  )

  // weekly average line graph logs
  const { data: carsDayAverageLogs, isLoading: carsDayAverageLogsLoading } = useQuery<WeekDaysAverageCount[]>(
    [
      "fetchDayAverageLogs",
      startDate?.format("YYYY-MM-DD"),
      endDate?.format("YYYY-MM-DD"),
      query.selectedListOfInterest,
      query.selectedCarBrand,
      query.selectedCarType,
    ],
    ({ queryKey }) =>
      VisionAPI.fetchCarsDaysAverageCounts({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        list_of_interest: queryKey[3] as number,
        brand: queryKey[4] as string,
        car_type: queryKey[5] as string,
      }),
    {
      enabled: !!endDate,
    }
  )
  // cars counts by gates logs query
  const { data: carsGatesCounts, isLoading: carsGatesCountsLoading } = useQuery<CarGatesCount[]>(
    [
      "fetchCarsGatesCounts",
      startDate?.format("YYYY-MM-DD"),
      endDate?.format("YYYY-MM-DD"),
      query.selectedListOfInterest,
      query.selectedCarBrand,
      query.selectedCarType,
    ],
    ({ queryKey }) =>
      VisionAPI.fetchCarsGateCounts({
        start_dt: queryKey[1] as string,
        end_dt: queryKey[2] as string,
        list_of_interest: queryKey[3] as number,
        brand: queryKey[4] as string,
        car_type: queryKey[5] as string,
      }),
    {
      enabled: !!endDate,
    }
  )
  useEffect(() => {
    if (
      !!carsDayAverageLogsLoading ||
      !!totalTimeSpentLoading ||
      !!carCounterLogsLoading ||
      !!carsTotalCountsLoading ||
      !!carsGatesCountsLoading
    ) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [
    setIsLoading,
    carsDayAverageLogsLoading,
    totalTimeSpentLoading,
    carCounterLogsLoading,
    carsTotalCountsLoading,
    carsGatesCountsLoading,
  ])

  const exportGraphs = () => {
    const arrayOfRefs = [
      refCountsVisits,
      refCountsTime,
      refAverageTime,
      refAverageOccupancy,
      refAverageHourly,
      refGatesCounts,
    ]
    let zip = new JSZip()
    let images: any[] = []
    let tmp = 0
    arrayOfRefs.forEach((item) => {
      if (item !== undefined) {
        domtoimage
          .toBlob(item.current)
          .then((blob: any) => {
            images.push(blob)
          })
          .then(() => {
            zip.file(item!.current!.children[0]?.childNodes[0]?.textContent + ".jpeg", images[tmp], { binary: true })
            tmp++
            if (tmp === arrayOfRefs.length)
              zip.generateAsync({ type: "blob" }).then((blob) => {
                FileSaver.saveAs(blob, "Cars Analytics Graphs")
              })
          })
      }
    })
  }

  const { graphData: lineGraphData, tableData: hourlyAvgTableCounts } = useMemo(
    () =>
      convertDataToColoredLineGraphAndTable({
        data: carsDayAverageLogs,
        indexByKey: "day",
        logsKey: "hourly_count",
        logTimeStampKey: "hour",
        logCountKey: "avg_count",
      }),
    [carsDayAverageLogs]
  )

  // converting data to suitable format that nivo bar graph accepts
  // using the appropriate formatting function based on the sent data type
  const barGraphData: EntrancesBarData[] = useMemo(() => {
    if (carsGatesCountsLoading || !carsGatesCounts || !endDate) return []

    return sortAndColorizeLogs(carsGatesCounts, "count")
  }, [carsGatesCounts, endDate, carsGatesCountsLoading])

  const tableColumns: TableColumn[] = [
    {
      title: "Gate",
      field: "entity",
      searchable: false,
      render: (rowData: CarGatesCount) => (
        <div title={rowData.entity}>
          {rowData.entity.length > 19 ? rowData.entity.slice(0, 17) + "..." : rowData.entity}
        </div>
      ),
    },
    {
      title: "Count",
      field: "count",
      searchable: false,
    },
  ]

  const lineGraphTableColumns: TableColumn[] = [
    {
      title: "Hour",
      field: "hour",
      searchable: true,
    },
    ...(carsDayAverageLogs
      ? carsDayAverageLogs.map((log) => ({
          title: log.day,
          field: log.day,
          searchable: false,
          render: (rowData: Record<string, number>) => rowData[log.day] ?? 0,
        }))
      : []),
  ]

  return (
    <div className={styles.wrapper}>
      <Typography
        variant="h2-regular"
        tooltip="All you need to know about Cars statistics and monitoring counts"
        tooltipPlacement="right"
        tooltipIconSize={22}
        gutterBottom
        variantColor={2}
      >
        Car Analytics
      </Typography>

      {/* filter & search */}
      <div className={styles.filters}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className={styles.datePicker}>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              disabled={isLoading}
              onStartDateChange={setStartDate}
              onEndDateChange={setEndDate}
              disableFuture
            />
          </div>
          <CarsFilters
            isFetching={isLoading}
            endDate={endDate}
            selectedCarBrand={query.selectedCarBrand}
            selectedCarType={query.selectedCarType}
            selectedListOfInterest={query.selectedListOfInterest}
            setSelectedCarBrand={(brand: string) => setQuery({ selectedCarBrand: brand })}
            setSelectedCarType={(type: string) => setQuery({ selectedCarType: type })}
            setSelectedListOfInterest={(list: string) => setQuery({ selectedListOfInterest: list })}
            isAnalytics
          />
        </div>

        <Button
          variant="secondary"
          startIcon={<GetAppIcon fontSize="small" />}
          onClick={() => exportGraphs()}
          disabled={isLoading ? true : false}
        >
          Export
        </Button>
      </div>

      <Typography variant="a" variantColor={2} gutterBottom className={styles.title}>
        New Vs. Returning Stats
      </Typography>

      <Grid container spacing={2}>
        {/* Average Cars counts [Visits] */}
        <Grid item xs={12}>
          <CarsAverageVisits
            carCounterLogs={carCounterLogs!}
            carCounterLogsLoading={carCounterLogsLoading}
            carTotalCounts={carsTotalCounts!}
            carsTotalCountsLoading={carsTotalCountsLoading}
            interval={interval!}
            timeGrain={timeGrain === "hour" ? "hour" : "day"}
            refCountsVisits={refCountsVisits}
            refAverageOccupancy={refAverageOccupancy}
          />
        </Grid>
        <Grid item xs={12}>
          {/* Cars Counts By Gate */}
          <PaginatedBarGraph
            data={barGraphData}
            graphProps={{
              keys: ["count"],
              indexBy: "entity",
            }}
            tableProps={{
              columns: tableColumns,
            }}
            isLoading={carsGatesCountsLoading}
            startDate={startDate?.format("YYYY-MM-DD")}
            endDate={endDate?.format("YYYY-MM-DD")}
            reference={refGatesCounts}
            title="Cars counts by gate"
          />
        </Grid>
        <Grid item xs={12}>
          {/* Average Cars counts [Time spent] */}
          <CarsAverageTime
            totalTimeSpentLogs={totalTimeSpent!}
            isLoading={totalTimeSpentLoading}
            startDate={startDate ? startDate?.toDate() : null}
            endDate={endDate ? endDate.toDate() : null}
            refCountsTime={refCountsTime}
            refAverageTime={refAverageTime}
          />
        </Grid>

        <Grid item xs={12}>
          {/* Average Cars counts [Occupancy] */}
          <CarsAverageOccupancy
            graphProps={{ data: lineGraphData }}
            tableProps={{ data: hourlyAvgTableCounts, columns: lineGraphTableColumns }}
            title="Average counts per hour"
            isLoading={carsDayAverageLogsLoading}
            ref={refAverageHourly}
            timeGrain="hour"
            isLargeInterval={interval?.months! > 1}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default CarsAnalytics
