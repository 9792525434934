import React, { FC, useState, Fragment } from "react"
import { useMutation, useQueryClient } from "react-query"

import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import EditIcon from "@mui/icons-material/EditOutlined"
import { Card, CardMedia, CardActionArea } from "@mui/material"

import { Typography, Tag, NotificationUtils } from "@synapse-analytics/synapse-ui"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../../../API/VisionAPI"
import { useBranchesStore } from "../../../../store"
import { definitions } from "../../../../types/Generated/apiTypes"
import { getAuthBaseUrl } from "../../../../utils/auth"
import DeleteFloor from "./DeleteFloor"
import FloorAddEdit from "./FloorAddEdit"

import styles from "./FloorCard.module.scss"

type floorsList = definitions["FloorPlanList"]

interface Props {
  floor: floorsList
}

const APIURL = getAuthBaseUrl()

const Floor: FC<Props> = ({ floor }) => {
  const [openEdit, setOpenEdit] = useState(false)
  const [open, setOpen] = useState(false)
  const queryClient = useQueryClient()
  const [selectedBranch] = useBranchesStore(
    (state: { selectedBranch: number | null }) => [state.selectedBranch],
    shallow
  )

  const handleClose = () => {
    setOpen(false)
  }

  const handleCloseEdit = () => {
    setOpenEdit(false)
  }

  const { mutate: handleDeleteFloor } = useMutation(
    () => VisionAPI.deleteFloorPlan(floor?.id as number, selectedBranch),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("fetchFloors")
        NotificationUtils.toast(`${floor?.floor_name || "Floor"} has been deleted successfully`, {
          snackBarVariant: "positive",
        })
        handleClose()
      },
    }
  )

  return (
    <Fragment>
      <Card className={styles.floorWrapper}>
        <CardActionArea style={{ position: "relative" }} onClick={() => setOpenEdit(true)}>
          {floor?.image ? (
            <div>
              <div
                className={styles.status}
                style={{
                  color:
                    floor?.placed_cameras! > floor?.mapped_cameras!
                      ? "var(--negative-text-enabled)"
                      : floor?.mapped_cameras! !== 0 &&
                        floor?.placed_cameras! !== 0 &&
                        floor?.placed_cameras! === floor?.mapped_cameras!
                      ? "var(--positive-text-enabled)"
                      : "var(--warning-text-enabled)",
                  border:
                    floor?.placed_cameras! > floor?.mapped_cameras!
                      ? "1.5px solid var(--negative-border-active)"
                      : floor?.mapped_cameras! !== 0 &&
                        floor?.placed_cameras! !== 0 &&
                        floor?.placed_cameras! === floor?.mapped_cameras!
                      ? "1.5px solid var(--positive-border-active)"
                      : "1.5px solid var(--warning-border-active)",
                  backgroundColor:
                    floor?.placed_cameras! > floor?.mapped_cameras!
                      ? "var(--negative-background-active)"
                      : floor?.mapped_cameras! !== 0 &&
                        floor?.placed_cameras! !== 0 &&
                        floor?.placed_cameras! === floor?.mapped_cameras!
                      ? "var(--positive-background-active)"
                      : "var(--warning-background-active)",
                }}
              >
                {floor?.placed_cameras! > floor?.mapped_cameras!
                  ? "Setup Incomplete"
                  : floor?.mapped_cameras! !== 0 &&
                    floor?.placed_cameras! !== 0 &&
                    floor?.placed_cameras! === floor?.mapped_cameras!
                  ? "Setup Complete"
                  : "Setup Pending"}
              </div>
              <CardMedia className={styles.media} image={`${APIURL}${floor?.image}`} title={floor?.floor_name!} />
            </div>
          ) : (
            <div className={styles.media}>
              <Typography variant="label" variantColor={2} className={styles.noFrame}>
                No Frame Captured
              </Typography>
            </div>
          )}
          {/* floor image */}
        </CardActionArea>

        {/* floor name & action buttons */}
        <div className={styles.floorActions}>
          <Typography variant="h3-bold" title={floor?.floor_name!}>
            {floor?.floor_name?.length >= 19 ? floor?.floor_name?.slice(0, 18) + "..." : floor?.floor_name}
          </Typography>
          <div className={styles.actionButton}>
            <EditIcon className={styles.floorIcon} style={{ marginRight: 4 }} onClick={() => setOpenEdit(true)} />
            <DeleteIcon className={styles.floorIcon} onClick={() => setOpen(true)} />
          </div>
        </div>
        <Typography variant="p" variantColor={2} gutterBottom>
          {floor?.placed_cameras} camera active
        </Typography>

        {/* tags */}
        <div
          style={{
            width: "100%",
            boxSizing: "border-box",
            overflow: "hidden",
            display: "flex",
            height: 24,
            alignItems: "center",
          }}
        >
          {floor?.tags && floor?.tags?.length > 0 ? (
            <Fragment>
              {floor?.tags?.slice(0, 3).map((tag, i) => (
                <Tag
                  key={`${i}`}
                  title={tag}
                  className={styles.floorTags}
                  variant={i % 3 === 0 && i !== 0 ? "orange" : i === 0 ? "teal" : "default"}
                >
                  {tag.length > 10 ? tag.slice(0, 9) + ".." : tag}
                </Tag>
              ))}
              {floor?.tags?.length > 3 && (
                <Typography variant="span" className={styles.tagsCaption} display="inline">
                  + {floor?.tags?.length - 3} more
                </Typography>
              )}
            </Fragment>
          ) : (
            <Typography variant="label" variantColor={2}>
              No Tags Yet
            </Typography>
          )}
        </div>
      </Card>
      {/* delete floor */}
      <DeleteFloor
        floorName={floor?.floor_name}
        open={open}
        handleDeleteFloor={handleDeleteFloor}
        handleClose={handleClose}
      />

      <FloorAddEdit edit floor={floor} handleClose={handleCloseEdit} open={openEdit} />
    </Fragment>
  )
}

export default Floor
