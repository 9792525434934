import { FC, useState, useEffect } from "react"

import GetAppIcon from "@mui/icons-material/GetApp"
import { Card } from "@mui/material"
import IconButton from "@mui/material/IconButton"

import { Typography } from "@synapse-analytics/synapse-ui"
import { CsvBuilder } from "filefy"

import GraphEmptyState from "../../../components/graphs/GraphEmptyState"
import HourlyLineGraph from "../../../components/graphs/HourlyLineGraph"
import { LineGraphData } from "../../../types/Custom/Types"
import { definitions } from "../../../types/Generated/apiTypes"
import {
  calculateSumPerTime,
  convertToHourlyAvgWeekdaysCountGraphLogs,
  convertToHourlyAvgWeekendsCountGraphLogs,
} from "../../../utils/counterUtils"

import styles from "./HourlyAvgData.module.scss"

type CounterLogs = definitions["CounterLogs"]

interface Props {
  logsData?: CounterLogs[]
  loading: boolean
  interval?: Duration
  reference: any
  dayToFilter?: number | null
}

const HourlyAvgData: FC<Props> = ({ loading, logsData, interval, dayToFilter, reference }) => {
  const [wholeWeekLineGraphData, setWholeWeekLineGraphData] = useState<LineGraphData[]>([
    {
      id: "",
      data: [{ x: "", y: 0 }],
    },
  ])
  const [showExport, setShowExport] = useState(false)
  // formatting fetched data to match nivo's acceptable data form

  useEffect(() => {
    if (loading === false && logsData) {
      if (dayToFilter !== null && dayToFilter !== undefined) {
        if (dayToFilter >= 0 && dayToFilter <= 4) {
          setWholeWeekLineGraphData([
            ...convertToHourlyAvgWeekdaysCountGraphLogs(calculateSumPerTime(logsData, dayToFilter)),
          ])
        } else {
          setWholeWeekLineGraphData([
            ...convertToHourlyAvgWeekendsCountGraphLogs(calculateSumPerTime(logsData, dayToFilter)),
          ])
        }
      } else {
        setWholeWeekLineGraphData([
          ...convertToHourlyAvgWeekdaysCountGraphLogs(calculateSumPerTime(logsData)),
          ...convertToHourlyAvgWeekendsCountGraphLogs(calculateSumPerTime(logsData)),
        ])
      }

      if (logsData.length > 0 && (interval!.days! >= 7 || interval!.months! >= 1)) {
        setShowExport(true)
      } else {
        setShowExport(false)
      }
    } else {
      setShowExport(false)
    }
  }, [logsData, loading, interval, dayToFilter])

  const handleExportCSV = () => {
    const builder = new CsvBuilder(`Hourly Average Counts .csv`)
    let csvFormattedData: string[][] = [[]]
    csvFormattedData.pop()
    if (!loading && wholeWeekLineGraphData)
      for (let log of wholeWeekLineGraphData) {
        log.data.map((element) => {
          return csvFormattedData.push([element.x as string, element.y.toString()])
        })
      }
    builder.setColumns(["Hour", "Count In"]).addRows(csvFormattedData).exportFile()
  }
  return (
    <Card className={styles.wrapper} ref={reference}>
      <div className={styles.header}>
        <Typography variant="h2-bold" variantColor={2} className={styles.title}>
          Hourly average count
          {showExport && (
            <IconButton onClick={() => handleExportCSV()} className={styles.exportBtn} size="large">
              <GetAppIcon />
            </IconButton>
          )}
        </Typography>
      </div>
      {interval && interval!.days! < 7 && interval!.months! < 1 ? (
        <GraphEmptyState isRangeWarning={true} />
      ) : (
        <HourlyLineGraph
          data={wholeWeekLineGraphData}
          graphHeight={300}
          isLoading={loading}
          isAverageChart
          hasCheckbox
        />
      )}
    </Card>
  )
}
export default HourlyAvgData
