import { Fragment, useMemo } from "react"
import { useQuery } from "react-query"

import { Grid } from "@mui/material"

import { DateRangePicker, Typography } from "@synapse-analytics/synapse-ui"
import moment from "moment"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../API/VisionAPI"
import CheckboxesLineGraphCard from "../../components/GraphCards/CheckboxesLineGraphCard"
import PaginatedBarGraphCard from "../../components/GraphCards/PaginatedBarGraphCard"
import PieChartCard from "../../components/GraphCards/PieChartCard"
import { useDateQuery } from "../../hooks/useDateQuery"
import { useBranchesStore } from "../../store"
import { TableColumn } from "../../types/Custom/Types"
import { definitions } from "../../types/Generated/apiTypes"
import { convertDataToColoredLineGraphAndTable } from "../../utils/genericHelpers"

import styles from "./CheckoutCounter.module.scss"

type CashierPerformanceAnalytics = definitions["CashierPerformanceAnalytics"]
type CashierConversionRate = definitions["CashierConversionRate"]
type CashierHourlyConversionRate = definitions["CashierHourlyConversionRate"]

const CheckoutCounter = () => {
  const [selectedBranch] = useBranchesStore(
    (state: { selectedBranch: number | null }) => [state.selectedBranch],
    shallow
  )

  const [startDate, setStartDate, endDate, setEndDate] = useDateQuery()

  // The data returned by the query, representing area daily counts
  const { data: cashiersPerformance, isLoading: isCashierPerformanceLoading } = useQuery<CashierPerformanceAnalytics[]>(
    // The query key, which is an array containing the parameters used to fetch the data
    [
      "fetchCashiersAnalytics",
      // Start date of the logs (converted to string)
      startDate?.format("YYYY-MM-DD"),
      // End date of the logs (converted to string)
      endDate?.format("YYYY-MM-DD"),
      // Selected Branch Id
      selectedBranch,
    ],
    // Function to fetch the area counts based on the provided parameters
    ({ queryKey }) =>
      VisionAPI.fetchCashiersAnalytics({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        branch_id: queryKey[3] as number,
      }),
    {
      enabled: !!endDate && !!selectedBranch,
    }
  )

  // Fetches the hourly conversion rate data for cashiers based on the selected date range and branch.
  // This data includes the total count, served and unserved counts for male, female, and unknown genders.
  const { data: hourlyConversionRate, isLoading: isHourlyConversionRateLoading } = useQuery<CashierConversionRate>(
    // The query key is an array that includes the query identifier, start date, end date, and selected branch ID.
    [
      "fetchCashiersConversionRate",
      // Converts the start date to a string in YYYY-MM-DD format.
      startDate?.format("YYYY-MM-DD"),
      // Converts the end date to a string in YYYY-MM-DD format.
      endDate?.format("YYYY-MM-DD"),
      // The ID of the selected branch.
      selectedBranch,
    ],
    // This function fetches the hourly conversion rate data for cashiers based on the provided parameters.
    ({ queryKey }) =>
      VisionAPI.fetchCashiersHourlyConversionRate({
        // The start date of the logs in YYYY-MM-DD format.
        from_date: queryKey[1] as string,
        // The end date of the logs in YYYY-MM-DD format.
        to_date: queryKey[2] as string,
        // The ID of the branch for which to fetch the data.
        branch_id: queryKey[3] as number,
      }),
    {
      // Enables the query only if both the end date and selected branch are defined.
      enabled: !!endDate && !!selectedBranch,
    }
  )

  const { graphData: lineGraphData, tableData: hourlyAvgTableCounts } = useMemo(
    () =>
      convertDataToColoredLineGraphAndTable({
        data: cashiersPerformance,
        indexByKey: "cashier_name",
        logsKey: "hourly_avg_counts",
        logTimeStampKey: "hour",
        logCountKey: "avg_count",
      }),
    [cashiersPerformance]
  )
  const performanceHasUnknownCount = cashiersPerformance?.some((log) => log.unknown_count > 0)

  const tableColumns: TableColumn[] = [
    {
      title: "Cashier",
      field: "cashier_name",
      searchable: true,
    },
    {
      title: "Male Count",
      field: "male_count",
      searchable: false,
    },
    {
      title: "Female Count",
      field: "female_count",
      searchable: false,
    },
    ...(performanceHasUnknownCount
      ? [
          {
            title: "Unknown Count",
            field: "unknown_count",
            searchable: false,
          },
        ]
      : []),
  ]

  const rateHasUnknownCount = hourlyConversionRate?.hourly_conversion_rate?.some(
    (log) => log.unknown_served_percent > 0
  )

  const barGraphTableColumns: TableColumn[] = [
    {
      title: "Hour",
      field: "hour",
      searchable: true,
      render: (rowData) => moment().hour(Number(rowData.hour)).format("h A"),
    },
    {
      title: "Male Served Percent",
      field: "male_served_percent",
      searchable: false,
      render: (rowData) => `${rowData.male_served_percent}%`,
    },
    {
      title: "Female Served Percent",
      field: "female_served_percent",
      searchable: false,
      render: (rowData) => `${rowData.female_served_percent}%`,
    },
    ...(rateHasUnknownCount
      ? [
          {
            title: "Unknown Served Percent",
            field: "unknown_served_percent",
            searchable: false,
            render: (rowData: CashierHourlyConversionRate) => `${rowData.unknown_served_percent}%`,
          },
        ]
      : []),
  ]

  const lineGraphTableColumns: TableColumn[] = [
    {
      title: "Hour",
      field: "hour",
      searchable: true,
    },
    ...(cashiersPerformance
      ? cashiersPerformance.map((cashier) => ({
          title: cashier.cashier_name,
          field: cashier.cashier_name,
          searchable: false,
        }))
      : []),
  ]

  return (
    <Fragment>
      <Typography
        variant="h2-regular"
        gutterBottom
        variantColor={2}
        tooltip={`Checkout counters analytics over selected date range`}
        tooltipPlacement="right"
        tooltipIconSize={22}
      >
        Checkout counters
      </Typography>
      <div className={styles.container}>
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
          disableFuture
        />
        <Typography variant="a" variantColor={2}>
          Stats
        </Typography>
        <CheckboxesLineGraphCard
          graphProps={{ data: lineGraphData }}
          tableProps={{
            columns: lineGraphTableColumns,
            data: hourlyAvgTableCounts,
          }}
          title="Hourly Average Dwelling Counts"
          isLoading={isCashierPerformanceLoading}
          timeGrain="hour"
        />
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <PieChartCard
              title="Conversion rate"
              subTitle="Total Clients entered vs. Clients reached the checkout counter."
              data={[
                {
                  id: "Served",
                  label: "Clients reached the counter",
                  value:
                    (hourlyConversionRate?.male_served_count || 0) +
                    (hourlyConversionRate?.female_served_count || 0) +
                    (hourlyConversionRate?.unknown_gender_served_count || 0),
                  color: "var(--indigo-background-1)",
                  distributionData: [
                    {
                      label: "Male count",
                      color: "var(--blue-background-2)",
                      value: hourlyConversionRate?.male_served_count || 0,
                    },
                    {
                      label: "Female count",
                      color: "var(--pink-background-2)",
                      value: hourlyConversionRate?.female_served_count || 0,
                    },
                    {
                      label: "Unknown Count",
                      color: "#EAEEF2",
                      value: hourlyConversionRate?.unknown_gender_served_count || 0,
                    },
                  ],
                },
                {
                  id: "Unserved",
                  label: "Clients did not reach the counter",
                  value:
                    (hourlyConversionRate?.male_unserved_count || 0) +
                    (hourlyConversionRate?.female_unserved_count || 0) +
                    (hourlyConversionRate?.unknown_gender_unserved_count || 0),
                  color: "var(--indigo-background-2)",
                  distributionData: [
                    {
                      label: "Male count",
                      color: "var(--blue-background-2)",
                      value: hourlyConversionRate?.male_unserved_count || 0,
                    },
                    {
                      label: "Female count",
                      color: "var(--pink-background-2)",
                      value: hourlyConversionRate?.male_unserved_count || 0,
                    },
                    {
                      label: "Unknown Count",
                      color: "#EAEEF2",
                      value: hourlyConversionRate?.unknown_gender_unserved_count || 0,
                    },
                  ],
                },
              ]}
              isLoading={isHourlyConversionRateLoading}
              pieChartLabel="Total Clients entered"
              pieChartValue={hourlyConversionRate?.total_count}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <PaginatedBarGraphCard
              data={hourlyConversionRate?.hourly_conversion_rate}
              isLoading={isHourlyConversionRateLoading}
              startDate={startDate?.format("YYYY-MM-DD")}
              endDate={endDate?.format("YYYY-MM-DD")}
              contentHeight={400}
              graphProps={{
                indexBy: "hour",
                isPaginated: false,
                genderKeys: ["male_served_percent", "female_served_percent", "unknown_served_percent"],
                shouldDisplayDistribution: true,
              }}
              tableProps={{
                columns: barGraphTableColumns,
              }}
              title="Hourly Conversion rate percentage"
              subtitle="Conversion percentage = (Clients reached the counter/Total clients) x 100"
            />
          </Grid>
        </Grid>
        <PaginatedBarGraphCard
          data={cashiersPerformance}
          graphProps={{
            indexBy: "cashier_name",
            shouldDisplayDistribution: true,
          }}
          tableProps={{
            columns: tableColumns,
          }}
          isLoading={isCashierPerformanceLoading}
          startDate={startDate?.format("YYYY-MM-DD")}
          endDate={endDate?.format("YYYY-MM-DD")}
          title="Checkout Counters Performance"
        />
      </div>
    </Fragment>
  )
}

export default CheckoutCounter
