import React, { FC, useState, useEffect } from "react"

import { Grid, Paper } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"
import intervalToDuration from "date-fns/intervalToDuration"

import PaginatedBarGraph from "../../../components/graphs/PaginatedBarGraph"
import { definitions } from "../../../types/Generated/apiTypes"
import { formatGraphData } from "../../../utils/carsUtils"
import TotalTimeSpentBar from "./components/TotalTimeSpentBar"

import styles from "./CarsAverageTime.module.scss"

type TotalTimeSpent = definitions["TotalTimeSpent"]

interface Props {
  totalTimeSpentLogs: TotalTimeSpent
  isLoading: boolean
  startDate: Date | null
  endDate: Date | null
  refCountsTime: React.MutableRefObject<any>
  refAverageTime: React.MutableRefObject<any>
}
type GraphData = {
  date: string
  duration: number
  color?: string
}

const CarsAverageTime: FC<Props> = ({
  totalTimeSpentLogs,
  isLoading,
  startDate,
  endDate,
  refCountsTime,
  refAverageTime,
}) => {
  const [totalTimeSpent, setTotalTimeSpent] = useState<Duration>({
    days: 0,
    hours: 0,
    minutes: 0,
  })
  const [barGraphData, setBarGraphData] = useState<GraphData[]>([])

  // setting total down time duration state (in seconds) after logs are fetched from endpoint
  // used later in total down time circular progress
  // to have a ratio between total picked duration in seconds vs total down time in seconds
  useEffect(() => {
    if (!isLoading && startDate && endDate !== null) {
      if (totalTimeSpentLogs && totalTimeSpentLogs.avg_time_spent) {
        const totalTimeSpentInMilliSeconds = totalTimeSpentLogs.avg_time_spent * 1000
        const interval = intervalToDuration({ start: 0, end: totalTimeSpentInMilliSeconds })
        setTotalTimeSpent(interval)
        setBarGraphData(
          totalTimeSpentInMilliSeconds > 0 ? formatGraphData(totalTimeSpentLogs.week_days_avg_time_spent) : []
        )
      } else {
        setBarGraphData([])
        setTotalTimeSpent({
          days: 0,
          hours: 0,
          minutes: 0,
        })
      }
    }
  }, [totalTimeSpentLogs, startDate, endDate, isLoading])
  return (
    <Grid container spacing={2}>
      {/* stats */}
      <TotalTimeSpentBar totalTimeSpent={totalTimeSpent} isLoading={isLoading} refCountsTime={refCountsTime} />
      {/* Grouped Bar graph */}
      <Grid item xs={12} md={9}>
        <Paper className={styles.newReturnGraph} elevation={0} ref={refAverageTime}>
          <Typography variant="h2-bold" variantColor={2} className={styles.cardTitle}>
            Average time spent inside graph
          </Typography>
          <PaginatedBarGraph
            barGraphData={barGraphData}
            isLoading={isLoading}
            keys={["duration"]}
            indexBy="date"
            isDurationGraph
            customBarTitle=" - Average Time Spent Inside"
            groupMode="grouped"
            height={280}
            isPaginated={false}
          />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default CarsAverageTime
