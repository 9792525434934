import { FC, Fragment } from "react"

import { ComputedDatum, ResponsivePie } from "@nivo/pie"
import { Typography } from "@synapse-analytics/synapse-ui"

import { PieChartGraphData } from "../../types/Custom/Interfaces"
import LegendSlice from "./helpers/LegendSlice"

import styles from "./PieChart.module.scss"

/**
 * Interface for the PieChart component props.
 */
interface Props {
  /**
   * The data to be displayed in the pie chart.
   */
  data: PieChartGraphData[]

  /**
   * The label to be displayed at the center of the pie chart.
   */
  centerLabel?: string

  /**
   * The metric to be displayed at the center of the pie chart.
   */
  centerMetric?: number | string

  /**
   * The height of the pie chart. Defaults to 300.
   */
  height?: number
}

/**
 * The PieChart component displays a pie chart with customizable center label and metric.
 *
 * @param {Props} props - The props for the PieChart component.
 * @returns A JSX element representing the PieChart component.
 */
const PieChart: FC<Props> = ({ data, centerLabel, centerMetric, height = 300 }: Props) => {
  /**
   * Function to render the centered metric in the pie chart.
   *
   * @param {any} data - The data object containing the center coordinates.
   * @returns A JSX element representing the centered metric.
   */
  const CenteredMetric = (data: any): React.ReactElement => {
    return (
      <Fragment>
        <text textAnchor="middle" dominantBaseline="central" fill="var(--gray-text-2)">
          <tspan
            x={data.centerX}
            y={data.centerY - 10}
            style={{
              fontSize: "10px",
              fontWeight: 600,
            }}
          >
            {centerLabel}
          </tspan>
          <tspan
            x={data.centerX}
            y={data.centerY + 16}
            style={{
              fontSize: "24px",
              fontWeight: 700,
            }}
          >
            {centerMetric}
          </tspan>
        </text>
      </Fragment>
    )
  }

  return (
    <div style={{ minHeight: height }}>
      <div style={{ height: height, width: "100%" }}>
        <ResponsivePie
          data={data}
          colors={({ data }: ComputedDatum<any>): string => data.color}
          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
          innerRadius={0.5}
          padAngle={3}
          cornerRadius={15}
          enableArcLinkLabels={false}
          activeOuterRadiusOffset={8}
          enableArcLabels={false}
          borderWidth={0}
          borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
          arcLinkLabelsSkipAngle={10}
          startAngle={-22}
          arcLinkLabelsTextColor="var(--greyScale-text-2)"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
          legends={[]}
          layers={["arcs", "arcLabels", "arcLinkLabels", "legends", CenteredMetric]} // Add custom layer here
          tooltip={({ datum: { value, data, label } }) => (
            <div className={styles.tooltip}>
              <LegendSlice label={label} value={value} />
              {data && data?.distributionData && (
                <div className={styles.innerContainer}>
                  {data?.distributionData?.map((element: { color: string; label: string | number; value: number }) => (
                    <LegendSlice color={element.color} label={element.label} value={element.value} isGraphLegend />
                  ))}
                </div>
              )}
            </div>
          )}
        />
      </div>
      <div className={styles.legendsContainer}>
        {data?.map((slice) => (
          <div className={styles.legendAndDistribution}>
            <LegendSlice isGraphLegend color={slice.color} label={slice.label} value={slice.value} />
            {slice.distributionData && !!slice.value && (
              <ul className={styles.distributionData}>
                {slice.distributionData.map((element) => (
                  <li key={element.label}>
                    <Typography variant="span">
                      {element.value} {element.label.split(" ")[0]}
                    </Typography>
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default PieChart
