import { useState, Fragment } from "react"

import { SingleDayPicker, Typography } from "@synapse-analytics/synapse-ui"
import moment from "moment"
import { NumberParam, useQueryParam } from "use-query-params"

import CameraSearch from "../../../components/CameraSearch"
import Placeholder from "../../../components/Placeholder"
import { useDateQuery } from "../../../hooks/useDateQuery"
import { getCurrentDay } from "../../../utils/mcpUtils"
import CamerasMCPContainer from "./CamerasMCPContainer"

import styles from "../styles/MCP.module.scss"

const CamerasMCP = () => {
  const [dayStart] = getCurrentDay()
  const [selectedDate, setSelectedDate] = useDateQuery({ isDayPicker: true })
  const [selectedCamera, setSelectedCamera] = useQueryParam("camera", NumberParam)

  const [isLoading, setIsLoading] = useState(false)
  const [disableDateRange, setDisableDateRange] = useState(false)

  const handleDateChange = (date: moment.Moment | null) => {
    if (date) {
      setSelectedDate(date)
    }
  }

  const handleLoading = (loadingState: boolean) => {
    setIsLoading(loadingState)
  }
  const handleDisableDateRange = (disableState: boolean) => {
    setDisableDateRange(disableState)
  }

  return (
    <div className={styles.wrapper}>
      <Typography
        variant="h2-regular"
        tooltip="Visual representation of most common paths in selected camera"
        tooltipPlacement="right"
        tooltipIconSize={22}
        gutterBottom
        variantColor={2}
      >
        Cameras MCP
      </Typography>
      <div className={styles.header}>
        <CameraSearch
          setSelectedCamera={setSelectedCamera}
          services="mcp"
          shouldSearchActive
          selectedCamera={selectedCamera as number}
        />

        <div className={styles.datePicker}>
          <SingleDayPicker
            disabled={isLoading || disableDateRange}
            date={selectedDate}
            disableFuture
            onDateChange={handleDateChange}
          />
        </div>
      </div>
      {/* if no camera is selected yet , or no end date is selected , show placeholder */}
      <Fragment>
        {selectedCamera ? (
          <CamerasMCPContainer
            selectedDate={selectedDate ? selectedDate?.toDate() : dayStart}
            selectedCamera={selectedCamera}
            loadingSetter={handleLoading}
            disableSetter={handleDisableDateRange}
            key={`${selectedCamera}_MCP`}
          />
        ) : (
          <Placeholder selectionType="camera" isScreenPlaceholder />
        )}
      </Fragment>
    </div>
  )
}
export default CamerasMCP
