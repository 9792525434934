import React, { useEffect, useMemo, useState } from "react"
import { useQuery } from "react-query"

import CreditCardIcon from "@mui/icons-material/CreditCard"
import GetAppIcon from "@mui/icons-material/GetApp"
import LocalParkingIcon from "@mui/icons-material/LocalParking"
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom"
import PeopleIcon from "@mui/icons-material/People"
import TimerIcon from "@mui/icons-material/Timer"
import AmpStoriesIcon from "@mui/icons-material/WebStories"

import { Button, Tab, Tabs, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import { CsvBuilder } from "filefy"
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../API/VisionAPI"
import CameraSearch from "../../components/CameraSearch"
import Search from "../../components/Search"
import EntitiesTable from "../../components/tables/EntitiesTable"
import { useBranchesStore } from "../../store"
import { AvailableEntities, EntityRowData } from "../../types/Custom/Interfaces"
import { definitions } from "../../types/Generated/apiTypes"
import { mapCameraAndRegionsToEntities } from "../../utils/entityUtils"
import EntitiesContextProvider from "./EntitiesSetup/EntitiesContext/EntitiesContext"
import EntitiesSetup from "./EntitiesSetup/EntitiesSetup"

import styles from "./EntitiesAndRegions.module.scss"

type Gate = definitions["Gate"]

type Shop = definitions["Shop"]

type Corridor = definitions["Corridor"]

type DwellingArea = definitions["DwellingArea"]

type Parking = definitions["Parking"]

type Cashier = definitions["Cashier"]

type CamerasList = definitions["CameraList"]

type Region = definitions["counterentitiy_region_serializer"]

const sectionHeight = "auto"
type TabInfo = {
  label: string
  value: string
  icon: React.ReactElement
}

type RegionPoint = {
  x: number
  y: number
}
type RegionData = {
  camera: number
  id: number
  points: RegionPoint[]
  state: 0 | 1 | 2
  dwell_thresh?: Date | number
}
interface Entity extends Object {
  id: number
  name: string
  cameras: number[]
  regions: RegionData[]
  camera_name: string[]
  inRegion: boolean
  outRegion: boolean
  type: AvailableEntities
}
type TableData = Entity & {
  tableData: { id: number }
}

const tabsInfo: Array<TabInfo> = [
  { label: "Entrance Gates", value: "Entrance Gate", icon: <MeetingRoomIcon className={styles.tabIcon} /> },
  { label: "Tenants", value: "Tenant", icon: <PeopleIcon className={styles.tabIcon} /> },
  { label: "Corridors", value: "Corridor", icon: <AmpStoriesIcon className={styles.tabIcon} /> },
  { label: "Dwelling Areas", value: "Dwelling Area", icon: <TimerIcon className={styles.tabIcon} /> },
  { label: "Car Parkings", value: "Car Parking", icon: <LocalParkingIcon className={styles.tabIcon} /> },
  { label: "Cashier", value: "Cashier", icon: <CreditCardIcon className={styles.tabIcon} /> },
]

// order entities by their update date
const ordering = "-updated_dt"

function EntitiesAndRegions() {
  const [query, setQuery] = useQueryParams({
    searchVal: withDefault(StringParam, ""),
    selectedCamera: NumberParam,
    activeTab: withDefault(StringParam, "Entrance Gate"),
  })
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [selectedEntity, setSelectedEntity] = useState<EntityRowData>()
  const [isEdit, setIsEdit] = useState(false)
  const [tableData, setTableData] = useState<TableData[]>([])

  const [selectedBranch] = useBranchesStore(
    (state: { selectedBranch: number | null }) => [state.selectedBranch],
    shallow
  )

  const handleSearch = (value: string) => {
    setQuery({ searchVal: value })
  }

  const searchedTableData = tableData.filter((element) =>
    element.name.toLowerCase().includes(query.searchVal.toLowerCase())
  )

  const handleTabChange = (tab: AvailableEntities): void => {
    setQuery({ activeTab: tab })
  }

  // fetch regions
  const { data: regionsData, isLoading: regionsLoading } = useQuery<Region[], AxiosError>("fetchRegions", () =>
    VisionAPI.fetchRegions()
  )

  // fetch cameras list
  const { data: cameras, isLoading: camerasListLoading } = useQuery<CamerasList[], AxiosError>(
    ["fetchCamerasShortList", selectedBranch, query.activeTab],
    ({ queryKey }) =>
      VisionAPI.fetchCamerasShortList({
        branch: queryKey[1] as number,
        services: queryKey[1] === "Car Parking" ? "car" : "",
      }),
    {
      enabled: !!selectedBranch,
    }
  )

  const { data: entranceEntities, isLoading: entranceEntitiesLoading } = useQuery<Gate[], AxiosError>(
    ["fetchEntranceEntities", selectedBranch, query.selectedCamera, ordering],
    ({ queryKey }) =>
      VisionAPI.fetchEntrances({
        branch: queryKey[1] as number,
        camera: queryKey[2] as number,
        ordering: queryKey[3] as string,
      }),
    {
      enabled: query.activeTab === "Entrance Gate" && !!selectedBranch,
    }
  )

  const { data: shopEntities, isLoading: shopEntitiesLoading } = useQuery<Shop[], AxiosError>(
    ["fetchShopsEntities", selectedBranch, query.selectedCamera, ordering],
    ({ queryKey }) =>
      VisionAPI.fetchShops({
        branch: queryKey[1] as number,
        camera: queryKey[2] as number,
        ordering: queryKey[3] as string,
      }),
    {
      enabled: query.activeTab === "Tenant" && !!selectedBranch,
    }
  )

  const { data: corridorEntities, isLoading: corridorEntitiesLoading } = useQuery<Corridor[], AxiosError>(
    ["fetchCorridorEntities", selectedBranch, query.selectedCamera, ordering],
    ({ queryKey }) =>
      VisionAPI.fetchCorridors({
        branch: queryKey[1] as number,
        camera: queryKey[2] as number,
        ordering: queryKey[3] as string,
      }),
    {
      enabled: query.activeTab === "Corridor" && !!selectedBranch,
    }
  )

  const { data: dwellingAreas, isLoading: dwellingAreasLoading } = useQuery<DwellingArea[], AxiosError>(
    ["fetchDwellingAreasEntities", selectedBranch, query.selectedCamera, ordering],
    ({ queryKey }) =>
      VisionAPI.fetchDwellingAreas({
        branch: queryKey[1] as number,
        camera: queryKey[2] as number,
        ordering: queryKey[3] as string,
      }),
    {
      enabled: query.activeTab === "Dwelling Area" && !!selectedBranch,
    }
  )

  const { data: carParking, isLoading: carParkingLoading } = useQuery<Parking[], AxiosError>(
    ["fetchCarParkingEntities", query.selectedCamera, ordering],
    ({ queryKey }) => VisionAPI.fetchCarParking({ camera: queryKey[1] as number, ordering: queryKey[2] as string }),
    {
      enabled: query.activeTab === "Car Parking",
    }
  )

  const { data: carParkingRegions, isLoading: carParkingRegionsLoading } = useQuery<Region[], AxiosError>(
    ["fetchCarParkingRegions", query.selectedCamera],
    ({ queryKey }) => VisionAPI.fetchCarParkingRegions({ camera: queryKey[1] as number }),
    {
      enabled: query.activeTab === "Car Parking",
    }
  )

  const { data: cashierEntities, isLoading: cashierEntitiesLoading } = useQuery<Cashier[], AxiosError>(
    ["fetchCashierEntities", selectedBranch, query.selectedCamera, ordering],
    ({ queryKey }) =>
      VisionAPI.fetchCashiers({
        branch: queryKey[1] as number,
        camera: queryKey[2] as number,
        ordering: queryKey[3] as string,
      }),
    {
      enabled: query.activeTab === "Cashier" && !!selectedBranch,
    }
  )

  // Use useMemo to format Regions
  const regions = useMemo(() => {
    let regionsObj: {
      [key: string]: Region
    } = {}
    regionsData?.forEach((region) => (regionsObj[region?.id!] = { ...region }))
    return regionsObj
  }, [regionsData])

  // Use useMemo to format cameras object
  const camerasObj = useMemo(() => {
    let formattedCamerasObj: { [key: string]: CamerasList } = {}
    cameras?.forEach((camera: CamerasList) => {
      formattedCamerasObj[camera.id!] = { ...camera }
    })
    return formattedCamerasObj
  }, [cameras])

  // global table loading state , if any of them is true => isLoading = true
  const isLoading = [
    camerasListLoading,
    carParkingLoading,
    carParkingRegionsLoading,
    regionsLoading,
    corridorEntitiesLoading,
    entranceEntitiesLoading,
    shopEntitiesLoading,
    dwellingAreasLoading,
    cashierEntitiesLoading,
  ].some((element) => element === true)

  // setting table data for each tab
  useEffect(() => {
    switch (query.activeTab) {
      case "Entrance Gate":
        setTableData(mapCameraAndRegionsToEntities(entranceEntities, camerasObj, regions, "Entrance Gate"))
        break
      case "Tenant":
        setTableData(mapCameraAndRegionsToEntities(shopEntities, camerasObj, regions, "Tenant"))
        break
      case "Corridor":
        setTableData(mapCameraAndRegionsToEntities(corridorEntities, camerasObj, regions, "Corridor"))
        break
      case "Dwelling Area":
        setTableData(mapCameraAndRegionsToEntities(dwellingAreas, camerasObj, regions, "Dwelling Area"))
        break
      case "Car Parking":
        setTableData(mapCameraAndRegionsToEntities(carParking, camerasObj, carParkingRegions, "Car Parking"))
        break
      case "Cashier":
        setTableData(mapCameraAndRegionsToEntities(cashierEntities, camerasObj, regions, "Cashier"))
        break
      default:
        setTableData([])
    }
  }, [
    camerasObj,
    carParking,
    carParkingRegions,
    cashierEntities,
    corridorEntities,
    dwellingAreas,
    entranceEntities,
    query.activeTab,
    regions,
    shopEntities,
  ])

  const handleExportCSV = () => {
    const builder = new CsvBuilder(`${query?.activeTab} Entities.csv`)
    let csvFormattedData: string[][] = [[]]
    csvFormattedData.pop()
    if (searchedTableData || tableData) {
      const dataToExport = searchedTableData ? searchedTableData : tableData
      for (const entry of dataToExport) {
        csvFormattedData.push([entry.name, entry.camera_name.join("/")])
      }
    }
    builder.setColumns(["Entity", "Cameras"]).addRows(csvFormattedData).exportFile()
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
    // reset selected entity
    setSelectedEntity(undefined)
    setIsEdit(false)
  }

  const handleOpenEdit = (rowData: EntityRowData) => {
    setSelectedEntity(rowData)
    setIsEdit(true)
    setIsDialogOpen(true)
  }
  return (
    <div className={styles.wrapper}>
      <div>
        <Typography
          variant="h2-regular"
          tooltip="Get All Entities Data"
          tooltipPlacement="right"
          tooltipIconSize={22}
          gutterBottom
          variantColor={2}
        >
          Entities & Regions
        </Typography>
      </div>
      <div className={styles.header}>
        <Search
          handleSearch={handleSearch}
          searchValue={query.searchVal}
          placeholder="Search by entity name"
          topHeader={false}
          size={235}
        />
        <CameraSearch
          setSelectedCamera={(camera) => setQuery({ selectedCamera: camera })}
          selectedCamera={query.selectedCamera as number}
        />
      </div>
      <div className={styles.verticalMargin} />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className={styles.tabsAndButtons}>
          <Tabs value={query.activeTab}>
            {/*Grouping Tabs */}
            {tabsInfo.map((tab) => (
              <Tab
                icon={() => tab.icon}
                label={tab.label}
                onClick={() => handleTabChange(tab.value as AvailableEntities)}
                value={tab.value}
                selected={query.activeTab === tab.value}
                id={`${tab.value}_tab`}
              />
            ))}
          </Tabs>
          <div className={styles.tableButtons}>
            <Button
              variant="secondary"
              className={styles.exportButton}
              onClick={() => handleExportCSV()}
              startIcon={<GetAppIcon fontSize="small" />}
            >
              Export
            </Button>
            <EntitiesContextProvider
              activeTab={query.activeTab as AvailableEntities}
              setIsDialogOpen={setIsDialogOpen}
              handleClose={handleCloseDialog}
              isDialogOpen={isDialogOpen}
              editData={selectedEntity}
              isEdit={isEdit}
              key={selectedEntity?.id}
            >
              <EntitiesSetup />
            </EntitiesContextProvider>
          </div>
        </div>

        <EntitiesTable
          title="Entities Table"
          data={searchedTableData ? searchedTableData : tableData}
          maxBodyHeight={sectionHeight}
          isLoading={isLoading}
          handleOpenEdit={handleOpenEdit}
        />
      </div>
    </div>
  )
}

export default EntitiesAndRegions
