import { FC, Fragment, useMemo } from "react"

import adultsIcon from "../../../assets/adultsIcon.svg"
import childrenIcon from "../../../assets/childrenIcon.svg"
import femaleIcon from "../../../assets/femaleIcon.svg"
import maleIcon from "../../../assets/maleIcon.svg"
import StatsCard from "../../../components/StatsCard"
import { DistributionData } from "../../../types/Custom/Interfaces"
import { definitions } from "../../../types/Generated/apiTypes"
import { calculateCountTotals } from "../../../utils/counterUtils"

import styles from "./NumericStats.module.scss"

type CounterLogs = definitions["CounterLogs"]
type EntityGroupsCounts = definitions["EntityGroupsCounts"]
/**
 * Interface for the NumericStats component props.
 */
interface Props {
  /**
   * Optional boolean prop to determine if the data is live or not.
   */
  isLive?: boolean
  /**
   * Array of counter logs data.
   */
  logsData?: CounterLogs[]
  /**
   * Boolean to determine if the data is loading or not.
   */
  loading: boolean
  /**
   * Array of counter logs data from the last week.
   */
  lastWeekData?: CounterLogs[]
  /**
   * Entity groups counts data.
   */
  entityGroupsCounts?: EntityGroupsCounts
  /**
   * Reference to the visitors.
   */
  visitorsRef: any
  /**
   * Reference to the occupancy.
   */
  occRef: any
}

/**
 * Component to display numeric statistics.
 * @param {Props} props - The props of the component.
 */
const NumericStats: FC<Props> = ({
  isLive,
  logsData,
  loading,
  lastWeekData,
  visitorsRef,
  occRef,
  entityGroupsCounts,
}: Props) => {
  // formatting and calculating cumulative count
  const numericStat = useMemo(() => {
    if (!loading && logsData) {
      return calculateCountTotals(logsData)
    }
    return {
      count_in_sum: 0,
      count_out_sum: 0,
      male_count_in: 0,
      female_count_in: 0,
      male_count_out: 0,
      female_count_out: 0,
      inside: 0,
      inside_male: 0,
      inside_female: 0,
      adult_count: 0,
      child_count: 0,
      dwell_left: 0,
      dwell_entered: 0,
      stood_left: 0,
      stood_entered: 0,
      unknown_age_count_sum: 0,
      unknown_gender_count_sum: 0,
    }
  }, [logsData, loading])

  // formatting and calculating cumulative count for data from a week
  // (used for comparison and calculating increase/decrease percentages)
  const comparisonStat = useMemo(() => {
    if (!loading && lastWeekData && isLive) {
      return calculateCountTotals(lastWeekData)
    }
    return {
      count_in_sum: 0,
      count_out_sum: 0,
      male_count_in: 0,
      female_count_in: 0,
      male_count_out: 0,
      female_count_out: 0,
      inside: 0,
      inside_male: 0,
      inside_female: 0,
      adult_count: 0,
      child_count: 0,
      dwell_left: 0,
      dwell_entered: 0,
      stood_left: 0,
      stood_entered: 0,
      unknown_age_count_sum: 0,
      unknown_gender_count_sum: 0,
    }
  }, [lastWeekData, loading, isLive])

  const {
    count_in_sum: currentCountInSum,
    inside: currentInside,
    male_count_in: currentMaleCountIn,
    female_count_in: currentFemaleCountIn,
    child_count: currentChildCount,
    adult_count: currentAdultCount,
    dwell_entered: currentDwellEntered,
    dwell_left: currentDwellLeft,
    stood_entered: currentStoodEntered,
    stood_left: currentStoodLeft,
    unknown_age_count_sum: currentUnknownAgeCountSum,
    unknown_gender_count_sum: currentUnknownGenderCountSum,
  } = numericStat

  const { count_in_sum: lastWeekCountInSum, inside: lastWeekInside } = comparisonStat

  const femalePercentage = useMemo(() => {
    return currentMaleCountIn && currentFemaleCountIn
      ? (currentFemaleCountIn / (currentMaleCountIn + currentFemaleCountIn + currentUnknownGenderCountSum)) * 100
      : 50
  }, [currentMaleCountIn, currentFemaleCountIn, currentUnknownGenderCountSum])

  const malePercentage = useMemo(() => {
    return currentMaleCountIn && currentFemaleCountIn
      ? (currentMaleCountIn / (currentMaleCountIn + currentFemaleCountIn + currentUnknownGenderCountSum)) * 100
      : 100
  }, [currentMaleCountIn, currentFemaleCountIn, currentUnknownGenderCountSum])

  const childrenPercentage = useMemo(() => {
    return currentChildCount && currentAdultCount
      ? (currentChildCount / (currentChildCount + currentAdultCount + currentUnknownAgeCountSum)) * 100
      : 50
  }, [currentChildCount, currentAdultCount, currentUnknownAgeCountSum])

  const adultPercentage = useMemo(() => {
    return currentChildCount && currentAdultCount
      ? (currentAdultCount / (currentChildCount + currentAdultCount + currentUnknownAgeCountSum)) * 100
      : 100
  }, [currentChildCount, currentAdultCount, currentUnknownAgeCountSum])

  const dwellAndEnterPercentage = useMemo(() => {
    return currentDwellEntered && currentDwellLeft
      ? (currentDwellEntered / (currentDwellLeft + currentDwellEntered)) * 100
      : 50
  }, [currentDwellEntered, currentDwellLeft])

  const stoodAndEnterPercentage = useMemo(() => {
    return currentStoodEntered && currentStoodLeft
      ? (currentStoodEntered / (currentStoodLeft + currentStoodEntered)) * 100
      : 50
  }, [currentStoodEntered, currentStoodLeft])

  const basicDistributions: DistributionData[] = [
    {
      leftBar: {
        percentage: femalePercentage,
        label: "Female",
        value: currentFemaleCountIn,
        color: "var(--pink-background-2)",
        tooltipTitle: `Female : ${currentFemaleCountIn?.toLocaleString()}`,
        icon: femaleIcon,
      },
      rightBar: {
        percentage: malePercentage,
        label: "Male",
        value: currentMaleCountIn,
        color: "var(--blue-background-2)",
        tooltipTitle: `Male : ${currentMaleCountIn?.toLocaleString() || "No data"}`,
        icon: maleIcon,
      },
      title: "Gender Distribution",
      unknown: currentUnknownGenderCountSum,
    },
    {
      leftBar: {
        percentage: childrenPercentage,
        label: "Children",
        value: currentChildCount,
        color: "var(--teal-background-2)",
        tooltipTitle: `Children : ${currentChildCount?.toLocaleString()}`,
        icon: childrenIcon,
      },
      rightBar: {
        percentage: adultPercentage,
        label: "Adults",
        value: currentAdultCount,
        color: "var(--purple-background-2)",
        tooltipTitle: `Adults : ${currentAdultCount?.toLocaleString()}`,
        icon: adultsIcon,
      },
      title: "Age Distribution",
      unknown: currentUnknownAgeCountSum,
    },
  ]
  const dwellingDistributions = [
    {
      leftBar: {
        percentage: dwellAndEnterPercentage,
        label: "Dwelled and entered",
        value: currentDwellEntered,
        color: "#FFDBCC",
        tooltipTitle: `Dwelled and entered : ${currentDwellEntered?.toLocaleString()}`,
      },
      rightBar: {
        percentage: 100 - dwellAndEnterPercentage,
        label: "Dwelled and left",
        value: currentDwellLeft,
        color: "#CCFFD1",
        tooltipTitle: `Dwelled and left : ${currentDwellLeft?.toLocaleString() || "No data"}`,
      },
      title: "Dwelling Distribution",
    },
    {
      leftBar: {
        percentage: stoodAndEnterPercentage,
        label: "Stood and entered",
        value: currentStoodEntered,
        color: "#F7F4A6",
        tooltipTitle: `Stood and entered : ${currentStoodEntered?.toLocaleString()}`,
      },
      rightBar: {
        percentage: 100 - stoodAndEnterPercentage,
        label: "Stood and left",
        value: currentStoodLeft,
        color: "#DCCCFF",
        tooltipTitle: `Stood and left : ${currentStoodLeft?.toLocaleString() || "No data"}`,
      },
      title: "Standing Distribution",
    },
  ]

  return (
    <Fragment>
      <div className={styles.statsCardsWrapper}>
        {isLive ? (
          <Fragment>
            {/* Visitors In Card */}
            <StatsCard
              title="Visitors In Today"
              numericStat={currentCountInSum}
              historicalStat={lastWeekCountInSum}
              entityGroupsCounts={entityGroupsCounts}
              marginBottom={true}
              isNumericalStatLoading={loading}
              areBarsLoading={loading}
              reference={visitorsRef}
              distributionData={basicDistributions}
            />
            {/* Occupancy In Card */}
            <StatsCard
              title="Occupancy In Today"
              numericStat={currentInside}
              historicalStat={lastWeekInside}
              isNumericalStatLoading={loading}
              height="20%"
              isSecondCard
              reference={occRef}
            />
          </Fragment>
        ) : (
          <Fragment>
            {/* Visitors In Overall Card */}
            <StatsCard
              title="Visitors In Overall"
              numericStat={currentCountInSum}
              height="100%"
              isNumericalStatLoading={loading}
              entityGroupsCounts={entityGroupsCounts}
              reference={visitorsRef}
              distributionData={[...basicDistributions, ...dwellingDistributions]}
              areBarsLoading={loading}
            />
          </Fragment>
        )}
      </div>
    </Fragment>
  )
}
export default NumericStats
