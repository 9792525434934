import React, { ChangeEvent, FC } from "react"
import { useQuery } from "react-query"

import { Grid } from "@mui/material"

import { Typography, Switch } from "@synapse-analytics/synapse-ui"
import { Moment } from "moment"
import { BooleanParam, useQueryParam, withDefault } from "use-query-params"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../../API/VisionAPI"
import PaginatedBarGraph from "../../../components/GraphCards/PaginatedBarGraphCard"
import { useBranchesStore } from "../../../store"
import { ExportingRefs } from "../../../types/Custom/Interfaces"
import { TableColumn } from "../../../types/Custom/Types"
import { definitions } from "../../../types/Generated/apiTypes"
import SingleCorridor from "./SingleCorridor"

import styles from "./CorridorStatistics.module.scss"

type CorridorAgeGenderCount = definitions["CorridorAgeGenderCount"]

interface Props {
  startDate: Moment | null
  endDate: Moment | null
  interval: Duration
  timeGrain: "hour" | "day"
  refs: ExportingRefs
}
const CorridorStatistics: FC<Props> = ({ startDate, endDate, interval, timeGrain, refs }) => {
  const [shouldIncludeStaff, setShouldIncludeStaff] = useQueryParam("staff", withDefault(BooleanParam, false))

  const [selectedBranch] = useBranchesStore(
    (state: { selectedBranch: number | null }) => [state.selectedBranch],
    shallow
  )

  const { corridorsPerformanceRef, corridorOverTimeRef, corridorBreakdown } = refs

  const { data: corridorsCounts, isLoading: corridorsCountsLoading } = useQuery<CorridorAgeGenderCount[]>(
    [
      "fetchCorridorsCounts",
      startDate?.format("YYYY-MM-DD"),
      endDate?.format("YYYY-MM-DD"),
      shouldIncludeStaff,
      selectedBranch,
    ],
    ({ queryKey }) =>
      VisionAPI.fetchCorridorsCounts({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        include_staff: queryKey[3] as boolean,
        branch: queryKey[4] as number,
      }),
    {
      enabled: !!startDate && !!endDate && !!selectedBranch,
    }
  )

  const handleSwitchStaffInclusion = (event: ChangeEvent<HTMLInputElement>) => {
    setShouldIncludeStaff(event.target.checked)
  }

  const tableColumns: TableColumn[] = [
    {
      title: "Corridor",
      field: "entity_name",
      searchable: false,
      render: (rowData: CorridorAgeGenderCount) => (
        <div title={rowData.entity_name}>
          {rowData.entity_name.length > 19 ? rowData.entity_name.slice(0, 17) + "..." : rowData.entity_name}
        </div>
      ),
    },
    {
      title: "Male Count",
      field: "male_count",
      searchable: false,
    },
    {
      title: "Female Count",
      field: "female_count",
      searchable: false,
    },
    {
      title: "Adult Count",
      field: "adult_count",
      searchable: false,
    },
    {
      title: "Child Count",
      field: "child_count",
      searchable: false,
    },
  ]

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <Typography variant="a" variantColor={2} className={styles.title}>
          Stats
        </Typography>
        <Switch checked={shouldIncludeStaff} onChange={handleSwitchStaffInclusion} label="Include staff" />
      </div>
      {/* Categories Performance */}
      <Grid container spacing={2}>
        <Grid item md={12}>
          <PaginatedBarGraph
            data={corridorsCounts}
            isLoading={corridorsCountsLoading}
            startDate={startDate?.format("YYYY-MM-DD")}
            endDate={endDate?.format("YYYY-MM-DD")}
            title="Corridors performance"
            reference={corridorsPerformanceRef}
            graphProps={{
              keys: ["Count In"],
              indexBy: "entity_name",
              shouldDisplayDistribution: true,
            }}
            tableProps={{
              columns: tableColumns,
            }}
          />
        </Grid>
        <Grid item md={12}>
          <SingleCorridor
            startDate={startDate}
            endDate={endDate}
            timeGrain={timeGrain}
            interval={interval}
            shouldIncludeStaff={shouldIncludeStaff}
            refs={[corridorOverTimeRef, corridorBreakdown]}
          />
        </Grid>
      </Grid>
    </div>
  )
}
export default CorridorStatistics
