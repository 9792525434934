import React, { useState, useContext, FC, useEffect, Fragment } from "react"
import { useQueries } from "react-query"

import { MobileStepper, Dialog, DialogTitle, DialogContent, CircularProgress } from "@mui/material"

import { Button } from "@synapse-analytics/synapse-ui"
import moment from "moment"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../../API/VisionAPI"
import { useBranchesStore } from "../../../store"
import { definitions } from "../../../types/Generated/apiTypes"
import { CampaignContext } from "../CampaignContext/CampaignContext"
import CampaignFirstStep from "./CampaignFirstStep"
import CampaignSecondStep from "./CampaignSecondStep"

import styles from "./CampaignNewEdit.module.scss"

type CampaignDetail = definitions["CampaignDetail"]

interface Props {
  open: boolean
  handleClose: () => void
  campaign?: CampaignDetail
  edit?: boolean
}

const CampaignNewEdit: FC<Props> = ({ open, handleClose, campaign, edit }) => {
  const { formik, addSuccess, editSuccess, loadingNewCampaign, loadingEditCampaign } = useContext(CampaignContext)
  const [activeStep, setActiveStep] = useState(0)
  const [selectedBranch] = useBranchesStore(
    (state: { selectedBranch: number | null }) => [state.selectedBranch],
    shallow
  )

  useEffect(() => {
    if (addSuccess || editSuccess) {
      handleClose()
      setActiveStep(0)
    }
    // eslint-disable-next-line
  }, [addSuccess, editSuccess])

  const handleNext = () => {
    if (activeStep === 1) {
      return formik?.submitForm()
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  useEffect(() => {
    if (edit && open) {
      formik?.setFieldValue("id", campaign?.id!)
      formik?.setFieldValue("edit", true)
      formik?.setFieldValue("startDate", moment(campaign?.start_date!))
      formik?.setFieldValue("endDate", moment(campaign?.end_date!))
      formik?.setFieldValue("name", campaign?.name!)
      formik?.setFieldValue("description", campaign?.description!)
      formik?.setFieldValue(
        "target_entities",
        campaign?.target_entities!?.map((ent) => ent?.id!)
      )
      formik?.setFieldValue("selected_entities", campaign?.target_entities!)
      formik?.setFieldValue("tags", campaign?.tags!)
      formik?.setFieldValue("goal", campaign?.goal!)
    }
    // eslint-disable-next-line
  }, [edit, open])

  const results: any[] = useQueries([
    {
      queryKey: ["fetchGatesEntities", selectedBranch],
      queryFn: ({ queryKey }: { queryKey: any }) => VisionAPI.fetchEntrances({ branch: queryKey[1] as number }),
      enabled: open && activeStep === 0 && !!selectedBranch,
    },
    {
      queryKey: ["fetchCarsEntities"],
      queryFn: () => VisionAPI.fetchCarParking(),
      enabled: open && activeStep === 0,
    },
    {
      queryKey: ["fetchTenantsEntities", selectedBranch],
      queryFn: ({ queryKey }: { queryKey: any }) => VisionAPI.fetchShops({ branch: queryKey[1] as number }),
      enabled: open && activeStep === 0 && !!selectedBranch,
    },
  ])

  return (
    <Fragment>
      <Dialog
        open={open}
        classes={{ paperWidthSm: activeStep === 0 ? styles.paperWidthOne : styles.paperWidthTwo }}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle classes={{ root: styles.title }} id="alert-dialog-title">
          {edit ? "Edit Campaign" : "Create Campaign"}
        </DialogTitle>

        {/* campaign STEPS */}
        <DialogContent
          style={{ backgroundColor: "var(--important-background-default-2)" }}
          className={styles.dialogContent}
        >
          {activeStep === 0 ? (
            <CampaignFirstStep edit={edit!} activeStep={activeStep} open={open} />
          ) : (
            <CampaignSecondStep
              gatesEntities={results[0]?.data!}
              carsEntities={results[1]?.data!}
              tenantsEntities={results[2]?.data!}
            />
          )}

          {/* Footer */}
          <div className={styles.footerButtonsWrapper}>
            <MobileStepper
              classes={{ root: styles.stepRoot }}
              variant="dots"
              steps={2}
              position="static"
              activeStep={activeStep}
              className={styles.root}
              backButton={undefined}
              nextButton={undefined}
            />

            <div className={styles.footerButtons}>
              <Button variant="secondary" size="small" onClick={handleBack} disabled={activeStep === 0}>
                Back
              </Button>
              <Button
                size="regular"
                variant="primary"
                className={styles.next}
                onClick={handleNext}
                disabled={
                  loadingNewCampaign ||
                  loadingEditCampaign ||
                  results[0]?.isLoading ||
                  results[1]?.isLoading ||
                  results[2]?.isLoading ||
                  (activeStep === 0 && formik?.values?.name === "") ||
                  (activeStep === 0 &&
                    formik?.values?.startDate === moment() &&
                    formik?.values?.endDate === moment()) ||
                  (activeStep === 1 && formik?.values?.goal === 0)
                    ? true
                    : false
                }
              >
                {loadingNewCampaign || loadingEditCampaign ? (
                  <CircularProgress size={25} />
                ) : activeStep === 0 ? (
                  "Next"
                ) : edit ? (
                  "Save"
                ) : (
                  "Create"
                )}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

export default CampaignNewEdit
