import React, { FC, useMemo } from "react"

import { Grid, Paper } from "@mui/material"

import DataOverTimeCard from "../../../components/GraphCards/DataOverTimeCard"
import PieChartCard from "../../../components/GraphCards/PieChartCard"
import { LineGraphData, TableColumn } from "../../../types/Custom/Types"
import { definitions } from "../../../types/Generated/apiTypes"
import { calculateCarsSumPerTime, convertCarsDataToHourlyLineGraph } from "../../../utils/carsUtils"
import { formatTimestampForTable } from "../../../utils/genericHelpers"

import styles from "./CarsAverageVisits.module.scss"

type NewReturning = definitions["NewReturning"]
type CarCounterLogs = definitions["CarCounterLogs"]

type OverTimeTableData = {
  timestamp: Date | string
  "Count In"?: number
  "Count Out"?: number
}

interface Props {
  carTotalCounts: NewReturning
  carsTotalCountsLoading: boolean
  carCounterLogs: CarCounterLogs[]
  carCounterLogsLoading: boolean
  interval?: Duration
  timeGrain: "hour" | "day"
  refCountsVisits: React.MutableRefObject<any>
  refAverageOccupancy: React.MutableRefObject<any>
}

const CarsAverageVisits: FC<Props> = ({
  carTotalCounts,
  carsTotalCountsLoading,
  carCounterLogs,
  carCounterLogsLoading,
  timeGrain,
  interval,
  refCountsVisits,
  refAverageOccupancy,
}) => {
  // converting data to suitable format that nivo bar graph accepts
  // using the appropriate formatting function based on the sent data type
  const lineGraphData = useMemo(() => {
    if (!carCounterLogsLoading && carCounterLogs) {
      const lineGraphTableData: OverTimeTableData[] = calculateCarsSumPerTime(carCounterLogs)

      const lineGraphData: LineGraphData[] = convertCarsDataToHourlyLineGraph(carCounterLogs)
      return { lineGraphTableData, lineGraphData }
    }
  }, [carCounterLogs, carCounterLogsLoading])

  const lineGraphTableColumns: TableColumn[] = [
    {
      title: "Date/Time",
      field: "timestamp",
      searchable: false,
      render: (rowData: OverTimeTableData) => (
        <div>{formatTimestampForTable(rowData.timestamp, timeGrain as string)}</div>
      ),
    },
    {
      title: "Count In",
      field: "Count In",
      searchable: false,
    },
    {
      title: "Count Out",
      field: "Count Out",
      searchable: false,
    },
  ]
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <PieChartCard
          title="Car Traffic Overview"
          subTitle="Distribution of New, Returning, and Unknown Cars."
          data={[
            {
              id: "Unknown",
              label: "Unknown",
              value: carTotalCounts?.n_unknown,
              color: "#F87C4F",
            },
            {
              id: "New Cars",
              label: "New Cars",
              value: carTotalCounts?.n_new,
              color: "#A08CEB",
            },
            {
              id: "Returning Cars",
              label: "Returning Cars",
              value: carTotalCounts?.n_returning,
              color: "#7896D9",
            },
          ]}
          isLoading={carsTotalCountsLoading}
          pieChartLabel="Total Car Counts"
          pieChartValue={carTotalCounts?.n_new + carTotalCounts?.n_returning + carTotalCounts?.n_unknown}
          exportingRef={refCountsVisits}
        />
      </Grid>
      <Grid item xs={12} md={9}>
        <Paper className={styles.newReturnGraph} elevation={0} ref={refAverageOccupancy}>
          <DataOverTimeCard
            graphProps={{ data: lineGraphData?.lineGraphData, interval: interval }}
            tableProps={{
              data: lineGraphData?.lineGraphTableData,
              columns: lineGraphTableColumns,
            }}
            timeGrain={timeGrain}
            isLoading={carCounterLogsLoading}
            reference={refAverageOccupancy}
            contentHeight={320}
            dataType="car gates counts"
          />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default CarsAverageVisits
