import React, { useState, useEffect, useContext, Fragment, useMemo } from "react"
import { useQuery } from "react-query"

import { Card, Grid } from "@mui/material"

import { InputText, InputChangeEvent } from "@synapse-analytics/synapse-ui"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../../../API/VisionAPI"
import { useBranchesStore } from "../../../../store"
import { definitions } from "../../../../types/Generated/apiTypes"
import CameraPlaceholder from "../../../Cameras/assets/cameraPlaceholder.svg"
import { FloorsContext } from "../../floorsContext/FloorsContext"
import CameraPreview from "./CameraPreview"

import styles from "./CamerasSelection.module.scss"

type Camera = definitions["CameraRetrieveUpdate"] & {
  colorCode?: string
}

type CamerasList = definitions["CameraList"]

const CamerasSelection = () => {
  const { formik, cameraProperties, deleteLocatedCamera, deleteCameraMapping } = useContext(FloorsContext)
  const [selectedCameras, setSelectedCameras] = useState<number[]>(formik.values.SelectedCameras)
  const [validCameras, setValidCameras] = useState<Camera[]>(formik.values.ValidCameras)
  const [invalidCameras, setInvalidCameras] = useState<Camera[]>(formik.values.invalidCameras)
  const [autoCompleteValue, setAutoCompleteValue] = useState<string>("")
  const [selectedBranch] = useBranchesStore(
    (state: { selectedBranch: number | null }) => [state.selectedBranch],
    shallow
  )

  // fetch cameras list
  const { data: cameras = [], isLoading: isCamerasListLoading } = useQuery<CamerasList[]>(
    ["fetchCamerasShortList", selectedBranch],
    ({ queryKey }) => VisionAPI.fetchCamerasShortList({ branch: queryKey[1] as number }),
    {
      enabled: !!selectedBranch,
    }
  )

  // Use useMemo to format camerasObj
  const modifiedCameras = useMemo(() => {
    return cameras?.map((camera: CamerasList) => {
      return {
        label: camera.name,
        value: camera.id ?? "",
      }
    })
  }, [cameras])

  const handleAddValidCamera = (newCamera: Camera) => {
    if (!validCameras.some((camera) => camera.id === newCamera.id)) {
      setValidCameras((prevValue) => [...prevValue, newCamera])
    }
  }

  const handleAddInvalidCamera = (newCamera: Camera) => {
    if (!invalidCameras.some((camera) => camera.name === newCamera.name))
      setInvalidCameras((prevValue) => [...prevValue, newCamera])
  }

  const handleRemoveValidCamera = (cameraToRemove: number) => {
    setValidCameras((cameras) => cameras?.filter((camera) => camera.id !== cameraToRemove))
  }

  const handleRemoveInvalidCamera = (cameraToRemove: number) => {
    setInvalidCameras((cameras) => cameras?.filter((camera) => camera.id !== cameraToRemove))
  }

  const handleDeleteLocatedAndMappedCamera = (cameraToRemove: number) => {
    deleteLocatedCamera(cameraToRemove)
    deleteCameraMapping(cameraToRemove)
  }

  const handleCameraUnSelect = (cameraToRemove: number) => {
    setSelectedCameras((cameras) => cameras?.filter((camera) => camera !== cameraToRemove))
    // if the this camera to delete is in the located cameras array => send a delete request to remove it
    const isLocated = cameraProperties.some((camera) => camera.camera === cameraToRemove)
    if (isLocated && cameraToRemove) {
      handleDeleteLocatedAndMappedCamera(+cameraToRemove)
    }
  }

  const handleAutoCompleteChange = (event: InputChangeEvent) => {
    setAutoCompleteValue(event.target.value as string)
  }

  const handleChangeCameras = (selectedCameras: number[]) => {
    setSelectedCameras(selectedCameras)
    formik.setFieldValue("SelectedCameras", selectedCameras)
  }

  useEffect(() => {
    formik.setFieldValue("ValidCameras", validCameras)
    //eslint-disable-next-line
  }, [validCameras])
  useEffect(() => {
    formik.setFieldValue("SelectedCameras", selectedCameras)
    //eslint-disable-next-line
  }, [selectedCameras])
  useEffect(() => {
    formik.setFieldValue("invalidCameras", invalidCameras)
    //eslint-disable-next-line
  }, [invalidCameras])

  return (
    <Fragment>
      <Card className={styles.camerasSelectionCard}>
        <InputText
          value={autoCompleteValue}
          id="SelectedCameras"
          label="Select Cameras Mapped To This Floor"
          optionsWithValues={modifiedCameras}
          multipleModeProps={{
            isActive: !!cameras,
            selectedValues: selectedCameras,
            setSelectedValues: handleChangeCameras,
          }}
          placeholder="Search For A Camera"
          handleChange={handleAutoCompleteChange}
          loading={isCamerasListLoading || !cameras}
          fullWidth
          variant="filled"
          menuProps={{
            menuMaxContent: true,
          }}
        />

        {/* if no cameras are selected yet , show placeholder */}
        <Fragment>
          {selectedCameras && selectedCameras.length > 0 ? (
            <Grid container spacing={2} style={{ marginTop: 8 }}>
              {selectedCameras.map((camera) => (
                <Grid item md={4} xs={12} key={camera}>
                  <CameraPreview
                    camera={camera}
                    addValidCamera={handleAddValidCamera}
                    removeValidCamera={handleRemoveValidCamera}
                    addInvalidCamera={handleAddInvalidCamera}
                    removeInvalidCamera={handleRemoveInvalidCamera}
                    unSelectCamera={handleCameraUnSelect}
                    isDrawingSelection={false}
                    checkMark={false}
                    margin={false}
                    isLocated={cameraProperties.some((cameraProp) => cameraProp.camera === camera)}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <div className={styles.CameraPlaceholderWrapper}>
              <div>
                <img src={CameraPlaceholder} className={styles.cameraPlaceholder} alt="No Camera Selected" />
              </div>
            </div>
          )}
        </Fragment>
      </Card>
    </Fragment>
  )
}
export default CamerasSelection
