import { FC, useEffect, useState } from "react"
import { useQuery } from "react-query"

import CloseIcon from "@mui/icons-material/Close"

import { InputChangeEvent, InputText } from "@synapse-analytics/synapse-ui"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../API/VisionAPI"
import { useBranchesStore } from "../store"
import { definitions } from "../types/Generated/apiTypes"

import styles from "./CameraSearch.module.scss"

type CamerasList = definitions["CameraList"]

interface Props {
  services?: string
  setSelectedCamera: (cameraID: number | undefined) => void
  shouldSearchActive?: boolean
  selectedCamera?: number
}
const CameraSearch: FC<Props> = ({ setSelectedCamera, services, shouldSearchActive, selectedCamera }) => {
  const [selectedBranch] = useBranchesStore(
    (state: { selectedBranch: number | null }) => [state.selectedBranch],
    shallow
  )
  const [autocompleteValue, setAutocompleteValue] = useState<string | number>("")

  const { data: cameras = [], isLoading: isCamerasListLoading } = useQuery<CamerasList[]>(
    ["fetchCamerasShortList", selectedBranch, services, shouldSearchActive],
    ({ queryKey }) =>
      VisionAPI.fetchCamerasShortList({
        branch: queryKey[1] as number,
        services: queryKey[2] as string,
        active: queryKey[3] as boolean,
      }),
    {
      enabled: !!selectedBranch,
    }
  )

  // This effect updates the autocomplete input value to reflect the selected camera ID whenever the cameras list or the selected camera changes
  useEffect(() => {
    if (!!selectedCamera)
      setAutocompleteValue(cameras?.find((camera) => (camera?.id as number) === selectedCamera)?.id as number)
  }, [cameras, selectedCamera])

  const handleChangeCamera = (event: InputChangeEvent) => {
    const target = event.target
    if (Number(target.value) && !!target.label) setSelectedCamera(target.value as number)
    else {
      setAutocompleteValue(target.value)
    }
  }

  const handleResetSelection = () => {
    setAutocompleteValue("")
    setSelectedCamera(undefined)
  }

  return (
    <InputText
      id="cameras-list"
      value={autocompleteValue}
      optionsWithValues={cameras.map((camera) => {
        return {
          label: camera.name,
          value: camera.id!,
        }
      })}
      placeholder="Search for a camera"
      handleChange={handleChangeCamera}
      loading={isCamerasListLoading}
      width={235}
      variant="filled"
      hideDescription
    >
      {selectedCamera && (
        <div className={styles.textContainer}>
          <CloseIcon onClick={handleResetSelection} fontSize="inherit" className={styles.clearInput} />
        </div>
      )}
    </InputText>
  )
}
export default CameraSearch
