import React, { useState, useEffect, ChangeEvent } from "react"
import { useQuery } from "react-query"

import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import GetAppIcon from "@mui/icons-material/GetApp"
import { Grid } from "@mui/material"

import { Chip, Button, Typography, Switch } from "@synapse-analytics/synapse-ui"
import { DateRangePicker } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import { format } from "date-fns"
import intervalToDuration from "date-fns/intervalToDuration"
import { CsvBuilder } from "filefy"
import { ArrayParam, BooleanParam, StringParam, useQueryParams, withDefault } from "use-query-params"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../API/VisionAPI"
import Search from "../../components/Search"
import EntitiesList from "../../components/tables/EntitiesList"
import { useDateQuery } from "../../hooks/useDateQuery"
import { useBranchesStore } from "../../store"
import { definitions } from "../../types/Generated/apiTypes"

import styles from "./DwellingAreas.module.scss"

type Category = definitions["Category"]

type TableLogs = definitions["DwellingAreaAnalytics"]

const DwellingAreas = () => {
  const [startDate, setStartDate, endDate, setEndDate] = useDateQuery()
  const [query, setQuery] = useQueryParams({
    searchVal: withDefault(StringParam, ""),
    shouldIncludeStaff: withDefault(BooleanParam, false),
    selectedCategories: withDefault(ArrayParam, []),
  })

  const [timeGrain, setTimeGrain] = useState<"hour" | "day" | null>(null)
  const [interval, setInterval] = useState<Duration>()
  const [quickFilterOpen, setQuickFilterOpen] = useState(query.selectedCategories?.length > 0 || false)
  const [selectedBranch] = useBranchesStore(
    (state: { selectedBranch: number | null }) => [state.selectedBranch],
    shallow
  )

  useEffect(() => {
    if (!!endDate && !!startDate) {
      const interval = intervalToDuration({
        start: startDate!.toDate(),
        end: endDate!.toDate(),
      })
      setInterval(interval)
      if (interval.days! > 1 || interval.months! >= 1) {
        setTimeGrain("day")
      } else {
        setTimeGrain("hour")
      }
    }
  }, [startDate, endDate, timeGrain])

  const handleSearch = (value: string) => {
    setQuery({ searchVal: value })
  }

  const { data: categories, isLoading: categoriesLoading } = useQuery<Category[], AxiosError>(
    "fetchCategories",
    VisionAPI.fetchCategories
  )

  const { data: tableData, isLoading: tableLogsLoading } = useQuery<TableLogs[]>(
    [
      "fetchDwellingAreasAnalytics",
      startDate?.format("YYYY-MM-DD"),
      endDate?.format("YYYY-MM-DD"),
      query.shouldIncludeStaff,
      selectedBranch,
    ],
    ({ queryKey }) =>
      VisionAPI.fetchDwellingAreasAnalytics({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        include_staff: queryKey[3] as boolean,
        branch: queryKey[4] as number,
      }),
    {
      enabled: !!startDate && !!endDate && !!selectedBranch,
    }
  )

  const isLoading = [tableLogsLoading, categoriesLoading].some((element) => element === true)

  // handling clicking on one of the categories filters
  const handleFilterByCategory = (category: string) => {
    const categories = [...query.selectedCategories] as string[]

    //checking weather categories array contain that category
    if (!categories.includes(category)) {
      categories.push(category) //adding to array because value doesn't exists
    } else {
      categories.splice(categories.indexOf(category), 1) //deleting
    }
    setQuery({ selectedCategories: [...categories] })
  }

  const filteredTableData = tableData?.filter((element) => {
    const matchesCategory =
      query.selectedCategories.length === 0 || (query.selectedCategories as string[]).includes(element.category)
    const matchesSearch = element.name.toLowerCase().includes(query.searchVal.toLowerCase())
    return matchesCategory && matchesSearch
  })

  const handleExportCSV = () => {
    const builder = new CsvBuilder(
      `Dwelling Areas Table - From ` +
        (startDate ? format(new Date(startDate!.toDate()), "do MMM yyyy") : "Picked Date") +
        (endDate !== null ? " To " + format(new Date(endDate.toDate()), "do MMM yyyy") : "")
    )
    let csvFormattedData: string[][] = [[]]
    csvFormattedData.pop()
    filteredTableData?.map((row) =>
      csvFormattedData.push([
        row.name,
        row.category || "-",
        row.subcategory || "-",
        row?.total_dwelling_count?.toString() || "No Data",
        !row.avg_dwelling_duration ? "No Data" : (row?.avg_dwelling_duration / 60).toFixed(2).toString() + " Minutes",
      ])
    )
    builder
      .setColumns([
        "Dwelling Area Name",
        "Category",
        "Sub Category",
        "Total Dwelling Count",
        "Average Dwelling Duration",
      ])
      .addRows(csvFormattedData)
      .exportFile()
  }

  const handleSwitchStaffInclusion = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery({ shouldIncludeStaff: event.target.checked })
  }

  return (
    <div className={styles.wrapper}>
      <div>
        <Typography
          variant="h2-regular"
          tooltip="Information regarding your dwelling areas"
          tooltipPlacement="right"
          tooltipIconSize={22}
          gutterBottom
          variantColor={2}
        >
          Dwelling List
        </Typography>
      </div>
      <div className={styles.headerUtils}>
        <div className={styles.searchAndDate}>
          <Search
            handleSearch={handleSearch}
            searchValue={query.searchVal}
            placeholder="E.g. Dwelling Area Name"
            topHeader={false}
            loading={isLoading}
          />
          <div className={styles.datePicker}>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              disabled={isLoading}
              onStartDateChange={setStartDate}
              onEndDateChange={setEndDate}
            />
          </div>
          <Button
            endIcon={quickFilterOpen ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
            className={styles.filterTrigger}
            variant="secondary"
            onClick={() => setQuickFilterOpen((prevValue) => !prevValue)}
            disabled={!categories || (categories && categories?.length < 1)}
          >
            Quick Filters
          </Button>
        </div>
        <Button
          startIcon={<GetAppIcon fontSize="small" className={styles.exportIcon} />}
          variant="secondary"
          onClick={() => handleExportCSV()}
          disabled={isLoading}
        >
          Export
        </Button>
      </div>
      <Grid
        container
        spacing={1}
        className={styles.animation}
        style={{ display: quickFilterOpen ? "" : "none", margin: "8px 0px 34px 0px" }}
      >
        {categories?.map((category: any, index: number) => (
          <Grid item key={index}>
            <Chip
              removable={(query.selectedCategories as string[]).includes(category.name)}
              clickable
              onClick={() => handleFilterByCategory(category.name)}
              onRemove={() => null}
              isSelected={(query.selectedCategories as string[]).includes(category.name)}
              disabled={isLoading}
              className={styles.quickFilterBtn}
            >
              {category.name}
            </Chip>
          </Grid>
        ))}
      </Grid>

      <div className={styles.staffSwitch}>
        <Switch checked={!!query.shouldIncludeStaff} onChange={handleSwitchStaffInclusion} label="Include staff" />
      </div>
      <EntitiesList
        isLoading={isLoading}
        data={filteredTableData || []}
        maxBodyHeight="auto"
        title="Dwelling Areas Table"
        startDate={startDate}
        endDate={endDate}
        interval={interval!}
        isDwelling
      />
    </div>
  )
}
export default DwellingAreas
