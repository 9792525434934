import { useState, useEffect, ReactElement, FC } from "react"

import { Box, useMediaQuery } from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import { useTheme } from "@mui/material/styles"

import { DatumValue, Layer, LineSvgProps, PointSymbolProps, ResponsiveLine } from "@nivo/line"

import adultsIcon from "../../assets/adultsIcon.svg"
import childrenIcon from "../../assets/childrenIcon.svg"
import femaleIcon from "../../assets/femaleIcon.svg"
import maleIcon from "../../assets/maleIcon.svg"
import { LineGraphData } from "../../types/Custom/Types"
import { AverageBarColors, findMissingDataEntry, graphColors, lineGraphLayers } from "../../utils/graphUtils"
import DistributionBarsCard from "../DistributionBarsCard"
import GraphEmptyState from "./GraphEmptyState"
import GraphLoadingState from "./GraphLoadingState"
import { gridTheme } from "./gridTheme"
import GraphTooltip from "./helpers/GraphTooltip"

import styles from "./HourlyLineGraph.module.scss"

/**
 * HourlyLineGraph component props interface.
 */
interface HourlyLineGraphProps {
  /**
   * The data to be displayed in the graph.
   */
  data: LineGraphData[]
  /**
   * The height of the graph.
   */
  graphHeight: number
  /**
   * Indicates if the data is currently being loaded.
   */
  isLoading?: boolean
  /**
   * Determines if the graph should display average data.
   */
  isAverageChart?: boolean
  /**
   * Determines if checkboxes for filtering data should be displayed.
   */
  hasCheckbox?: boolean
  /**
   * Determines if the gender and age card should be displayed.
   */
  hasGenderAge?: boolean
}

/**
 * The HourlyLineGraph component displays a line graph for hourly data.
 * It supports filtering data based on checkboxes and can display either average data or detailed data.
 *
 * @param {HourlyLineGraphProps} props - The props for the component.
 * @returns {ReactElement} The HourlyLineGraph component.
 */
const HourlyLineGraph: FC<HourlyLineGraphProps> = ({
  data,
  graphHeight,
  hasGenderAge,
  isAverageChart,
  isLoading,
  hasCheckbox,
}: HourlyLineGraphProps): ReactElement => {
  const theme = useTheme()
  const largeScreen = useMediaQuery(theme.breakpoints.down("xl"))
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"))

  const [checkBoxStates, setCheckBoxStates] = useState(
    isAverageChart
      ? { Weekdays: false, Weekends: false }
      : {
          countIn: false,
          countOut: false,
          occupancy: false,
        }
  )
  const [slicedData, setSlicedData] = useState(data)

  // checkbox filtering logic
  useEffect(() => {
    // Array to store indices based on checkbox states
    const indicesToInclude: number[] = []

    // Check if countIn or Weekends checkbox is checked, and include corresponding indices
    if (checkBoxStates.countIn || checkBoxStates.Weekends) {
      indicesToInclude.push(1)
    }

    // Check if countOut or Weekdays checkbox is checked, and include corresponding indices
    if (checkBoxStates.countOut || checkBoxStates.Weekdays) {
      indicesToInclude.push(0)
    }

    // Check if occupancy checkbox is checked, and include corresponding index
    if (checkBoxStates.occupancy) {
      indicesToInclude.push(2)
    }

    // Create newDataArr by mapping indices to corresponding values and filter out undefined values
    const newDataArr = indicesToInclude.map((index) => data[index]).filter((value) => value !== undefined)

    // Set slicedData based on whether newDataArr has any elements, else slice the original data
    setSlicedData(indicesToInclude.length > 0 ? newDataArr : data)
  }, [checkBoxStates, data, isAverageChart])

  // handling checkbox click
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckBoxStates({ ...checkBoxStates, [event.target.name]: event.target.checked })
  }

  const commonLineProps: LineSvgProps = {
    data: slicedData,
    theme: gridTheme,
    curve: "cardinal",
    yScale: { type: "linear", min: "auto", max: "auto", stacked: false, reverse: false },
    axisTop: null,
    axisRight: null,
    enableSlices: "x",
    legends: !hasCheckbox
      ? [
          {
            anchor: "bottom",
            direction: "row",
            justify: false,
            translateX: 4,
            translateY: 60,
            itemsSpacing: 6,
            itemDirection: "left-to-right",
            itemWidth: 109,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 14,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]
      : [],
  }

  const averageChartConfig: LineSvgProps = {
    margin: { top: 30, right: 10, bottom: 80, left: 40 },
    data: slicedData,
    xScale: { type: "point" as const },
    axisBottom: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: smallScreen ? -45 : 0,
      format: (v: string) => (parseInt(v.substring(0, v.length - 2), 10) % 2 === 0 ? v : ""),
      legendPosition: "middle",
    },
    colors: (line: { id: keyof typeof AverageBarColors }) => AverageBarColors[line.id],
    sliceTooltip: ({ slice }: { slice: { points: any[]; id: DatumValue } }) => (
      <GraphTooltip
        slicePoints={slice.points}
        timeStamp={slice.points[0].data.xFormatted}
        key={`${slice.id}`}
        weekAvg
      />
    ),
    pointSize: 5,
    pointColor: { theme: "background" },
    pointBorderWidth: 2,
    enablePointLabel: false,
    pointLabel: "y",
    pointLabelYOffset: -12,
    useMesh: true,
  }

  const lineChartConfig: LineSvgProps = {
    data: slicedData,
    margin: { top: 20, right: 30, bottom: 65, left: 68 },
    xScale: { type: "time" as const, format: "native", precision: "hour", useUTC: false },
    xFormat: "time:%d %b %I %p",
    yScale: { type: "linear", min: "auto", max: "auto", stacked: false, reverse: false },
    areaOpacity: 0.07,
    enableArea: true,
    layers: lineGraphLayers as Layer[],
    sliceTooltip: ({ slice }: { slice: any }) => {
      const femalePercentage =
        slice.points[0].data.maleCount && slice.points[0].data.femaleCount
          ? (slice.points[0].data.femaleCount / (slice.points[0].data.maleCount + slice.points[0].data.femaleCount)) *
            100
          : 50

      const malePercentage =
        slice.points[0].data.maleCount && slice.points[0].data.femaleCount
          ? (slice.points[0].data.maleCount / (slice.points[0].data.maleCount + slice.points[0].data.femaleCount)) * 100
          : 50

      const childrenPercentage =
        slice.points[0].data.childCount && slice.points[0].data.adultCount
          ? (slice.points[0].data.childCount / (slice.points[0].data.childCount + slice.points[0].data.adultCount)) *
            100
          : 50

      const adultPercentage =
        slice.points[0].data.adultCount && slice.points[0].data.childCount
          ? (slice.points[0].data.adultCount / (slice.points[0].data.adultCount + slice.points[0].data.childCount)) *
            100
          : 50

      return hasGenderAge ? (
        <DistributionBarsCard
          distributionData={[
            {
              leftBar: {
                percentage: femalePercentage,
                label: "Female",
                value: slice.points[0].data.femaleCount,
                color: "var(--pink-background-2)",
                tooltipTitle: `Female : ${slice.points[0].data.femaleCount?.toLocaleString()}`,
                icon: femaleIcon,
              },
              rightBar: {
                percentage: malePercentage,
                label: "Male",
                value: slice.points[0].data.maleCount,
                color: "var(--blue-background-2)",
                tooltipTitle: `Male : ${slice.points[0].data.maleCount?.toLocaleString() || "No data"}`,
                icon: maleIcon,
              },
              title: "Gender Distribution",
            },
            {
              leftBar: {
                percentage: childrenPercentage,
                label: "Children",
                value: slice.points[0].data.childCount,
                color: "var(--teal-background-2)",
                tooltipTitle: `Children : ${slice.points[0].data.childCount?.toLocaleString()}`,
                icon: childrenIcon,
              },
              rightBar: {
                percentage: adultPercentage,
                label: "Adults",
                value: slice.points[0].data.adultCount,
                color: "var(--purple-background-2)",
                tooltipTitle: `Adults : ${slice.points[0].data.adultCount?.toLocaleString()}`,
                icon: adultsIcon,
              },
              title: "Age Distribution",
            },
          ]}
          key={slice.id}
          pointDate={slice.points[0].data.xFormatted}
          graphTooltip
        />
      ) : (
        <GraphTooltip
          slicePoints={slice.points}
          timeStamp={slice.points[0].data.xFormatted}
          key={slice.id}
          missingInfo={findMissingDataEntry(slice.points)}
        />
      )
    },
    axisBottom: {
      tickValues: "every 2 hour",
      format: "%I %p",
      tickSize: 6,
      tickPadding: 9,
      tickRotation: largeScreen ? -45 : 0,
      legendPosition: "middle",
    },
    axisLeft: {
      tickSize: 0,
      tickPadding: 8,
      tickRotation: 0,
      legend: "",
      format: (e: number) => Math.floor(e) === e && e,
      legendOffset: -50,
      legendPosition: "middle",
    },
    colors: (line: { id: keyof typeof graphColors }) => graphColors[line.id],
    pointSize: 12,
    pointSymbol: (props: PointSymbolProps) => (
      <circle
        cx="0"
        cy="0"
        r="6"
        strokeWidth="3"
        fill={props.datum.missingInfo && props.datum.missingInfo.length > 0 ? "#E5484D" : "transparent"}
      />
    ),

    pointBorderWidth: 0,
    pointBorderColor: { from: "serieColor" },
    enablePointLabel: false,
    pointLabel: "y",
    pointLabelYOffset: -12,
    useMesh: true,
  }

  return (
    <Box
      sx={{
        // 40 is the min height of checkboxes group
        minHeight: graphHeight + 40,
      }}
    >
      {isLoading ? (
        <GraphLoadingState />
      ) : (data && data[0]?.data && data[0]?.data?.length < 1) || !data ? (
        <GraphEmptyState />
      ) : (
        <Box sx={{ height: graphHeight }}>
          {isAverageChart ? (
            <ResponsiveLine {...commonLineProps} {...averageChartConfig} />
          ) : (
            <ResponsiveLine {...commonLineProps} {...lineChartConfig} />
          )}
        </Box>
      )}
      <div
        className={styles.checkBoxesGroup}
        style={{
          visibility: hasCheckbox && !isLoading ? "visible" : "hidden",
        }}
      >
        <div className={styles.checkbox}>
          <FormControlLabel
            style={{ color: "var(--blue-text-2)" }}
            control={
              <Checkbox
                sx={{
                  color: "var(--blue-text-2)",
                  "&.Mui-checked": {
                    color: "var(--blue-text-2)",
                  },
                }}
                checked={isAverageChart ? checkBoxStates.Weekdays : checkBoxStates.countIn}
                onChange={handleChange}
                name={isAverageChart ? "Weekdays" : "countIn"}
              />
            }
            label={isAverageChart ? "Weekdays" : "Incount"}
          />
        </div>
        <div className={styles.checkbox}>
          <FormControlLabel
            style={{ color: "var(--pink-background-1)" }}
            control={
              <Checkbox
                sx={{
                  color: "var(--pink-background-1)",
                  "&.Mui-checked": {
                    color: "var(--pink-background-1)",
                  },
                }}
                checked={isAverageChart ? checkBoxStates.Weekends : checkBoxStates.countOut}
                onChange={handleChange}
                name={isAverageChart ? "Weekends" : "countOut"}
              />
            }
            label={isAverageChart ? "Weekends" : "Outcount"}
          />
        </div>
        <div className={styles.checkbox}>
          {!isAverageChart && (
            <FormControlLabel
              style={{ color: "var(--green-background-1)" }}
              control={
                <Checkbox
                  sx={{
                    color: "var(--green-background-1)",
                    "&.Mui-checked": {
                      color: "var(--green-background-1)",
                    },
                  }}
                  checked={checkBoxStates.occupancy}
                  onChange={handleChange}
                  name="occupancy"
                />
              }
              label="Occupancy"
            />
          )}
        </div>
      </div>
    </Box>
  )
}

export default HourlyLineGraph
