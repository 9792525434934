import { FC } from "react"

import { Typography } from "@synapse-analytics/synapse-ui"

import styles from "./LegendSlice.module.scss"

/**
 * LegendSlice is a React component that displays a tooltip slice with a color chip, label, and value.
 *
 * @param color - The color of the tooltip slice chip.
 * @param label - The label of the tooltip slice, can be a string or a number.
 * @param value - The value of the tooltip slice, can be a number or a string.
 *
 * @returns A JSX element representing the tooltip slice.
 */
const LegendSlice: FC<{
  color?: string
  label: string | number
  value?: number | string
  isGraphLegend?: boolean
  isSecondaryLegend?: boolean
}> = ({ color, label, value, isGraphLegend }) => (
  <div className={styles.wrapper}>
    <div className={styles.legendAndTitle}>
      {color && <span style={{ background: color }} className={styles.legend} />}
      <Typography variant={isGraphLegend ? "span" : "p"} display="block" variantColor={2} noWrap>
        {label}
      </Typography>
    </div>
    <Typography variant={isGraphLegend ? "span" : "label"} noWrap variantColor={value ? 1 : 2}>
      <b>{value || "No data"}</b>
    </Typography>
  </div>
)
export default LegendSlice
