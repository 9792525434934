import { useState, useEffect, FC, ReactElement } from "react"

import { Box } from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"

import { Layer, ResponsiveLine } from "@nivo/line"

import adultsIcon from "../../assets/adultsIcon.svg"
import childrenIcon from "../../assets/childrenIcon.svg"
import femaleIcon from "../../assets/femaleIcon.svg"
import maleIcon from "../../assets/maleIcon.svg"
import { LineGraphData } from "../../types/Custom/Types"
import { findMissingDataEntry, graphColors, lineGraphLayers } from "../../utils/graphUtils"
import DistributionBarsCard from "../DistributionBarsCard"
import GraphEmptyState from "./GraphEmptyState"
import GraphLoadingState from "./GraphLoadingState"
import { gridTheme } from "./gridTheme"
import GraphTooltip from "./helpers/GraphTooltip"

import styles from "./DailyLineGraph.module.scss"

/**
 * Interface for DailyLineGraph component props.
 */
interface DailyLineGraphProps {
  /**
   * The data to be displayed in the graph.
   */
  data: LineGraphData[]
  /**
   * The height of the graph.
   */
  graphHeight: number
  /**
   * Indicates if the data is currently being loaded.
   */
  isLoading?: boolean
  /**
   * Determines if checkboxes for filtering data should be displayed.
   */
  hasCheckbox?: boolean
  /**
   * Interval of date range , used to determine the frequency of data points.
   */
  interval?: Duration
  /**
   * Determines if the gender and age card should be displayed.
   */
  hasGenderAge?: boolean
}

/**
 * The DailyLineGraph component displays a line graph for daily data.
 * It supports filtering data based on checkboxes and can display either average data or detailed data.
 *
 * @param {DailyLineGraphProps} props - The props for the component.
 * @returns {ReactElement} The DailyLineGraph component.
 */
const DailyLineGraph: FC<DailyLineGraphProps> = ({
  data,
  graphHeight,
  isLoading,
  hasCheckbox = true,
  interval,
  hasGenderAge,
}: DailyLineGraphProps): ReactElement => {
  const [checkBoxStates, setCheckBoxStates] = useState({
    countIn: false,
    countOut: false,
  })
  const [slicedData, setSlicedData] = useState(data)
  // filter out data based on checkboxes state
  useEffect(() => {
    // Array to store indices based on checkbox states
    const indicesToInclude: number[] = []

    // Check if countIn checkbox is checked, and include corresponding indices
    if (checkBoxStates.countIn) {
      indicesToInclude.push(1)
    }

    // Check if countOut checkbox is checked, and include corresponding indices
    if (checkBoxStates.countOut) {
      indicesToInclude.push(0)
    }

    // Create newDataArr by mapping indices to corresponding values and filter out undefined values
    const newDataArr = indicesToInclude.map((index) => data[index]).filter((value) => value !== undefined)

    // Set slicedData based on whether newDataArr has any elements, else slice the original data
    setSlicedData(indicesToInclude.length > 0 ? newDataArr : data.filter((element) => element.id !== "Occupancy"))
  }, [checkBoxStates, data])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckBoxStates({ ...checkBoxStates, [event.target.name]: event.target.checked })
  }

  return (
    <Box
      sx={{
        // 40 is the min height of checkboxes group
        minHeight: graphHeight + 40,
      }}
    >
      {isLoading ? (
        <GraphLoadingState />
      ) : (data && data[0]?.data && data[0]?.data?.length < 1) || !data ? (
        <GraphEmptyState />
      ) : (
        <Box
          sx={{
            height: graphHeight,
          }}
        >
          <ResponsiveLine
            data={slicedData}
            theme={gridTheme}
            margin={{ top: 60, right: 33, bottom: 90, left: 53 }}
            xScale={{ type: "time", format: "%Y-%m-%d", precision: "day", useUTC: false }}
            xFormat="time:%a %d %b"
            enablePoints={true}
            curve="cardinal"
            yScale={{ type: "linear", min: "auto", max: "auto", stacked: false, reverse: false }}
            areaOpacity={0.07}
            enableArea={true}
            layers={lineGraphLayers as Layer[]}
            sliceTooltip={({ slice }: { slice: any }) => {
              const femalePercentage =
                slice.points[0].data.maleCount && slice.points[0].data.femaleCount
                  ? (slice.points[0].data.femaleCount /
                      (slice.points[0].data.maleCount + slice.points[0].data.femaleCount)) *
                    100
                  : 50

              const malePercentage =
                slice.points[0].data.maleCount && slice.points[0].data.femaleCount
                  ? (slice.points[0].data.maleCount /
                      (slice.points[0].data.maleCount + slice.points[0].data.femaleCount)) *
                    100
                  : 50

              const childrenPercentage =
                slice.points[0].data.childCount && slice.points[0].data.adultCount
                  ? (slice.points[0].data.childCount /
                      (slice.points[0].data.childCount + slice.points[0].data.adultCount)) *
                    100
                  : 50

              const adultPercentage =
                slice.points[0].data.adultCount && slice.points[0].data.childCount
                  ? (slice.points[0].data.adultCount /
                      (slice.points[0].data.adultCount + slice.points[0].data.childCount)) *
                    100
                  : 50

              return hasGenderAge ? (
                <DistributionBarsCard
                  distributionData={[
                    {
                      leftBar: {
                        percentage: femalePercentage,
                        label: "Female",
                        value: slice.points[0].data.femaleCount,
                        color: "var(--pink-background-2)",
                        tooltipTitle: `Female : ${slice.points[0].data.femaleCount?.toLocaleString()}`,
                        icon: femaleIcon,
                      },
                      rightBar: {
                        percentage: malePercentage,
                        label: "Male",
                        value: slice.points[0].data.maleCount,
                        color: "var(--blue-background-2)",
                        tooltipTitle: `Male : ${slice.points[0].data.maleCount?.toLocaleString() || "No data"}`,
                        icon: maleIcon,
                      },
                      title: "Gender Distribution",
                    },
                    {
                      leftBar: {
                        percentage: childrenPercentage,
                        label: "Children",
                        value: slice.points[0].data.childCount,
                        color: "var(--teal-background-2)",
                        tooltipTitle: `Children : ${slice.points[0].data.childCount?.toLocaleString()}`,
                        icon: childrenIcon,
                      },
                      rightBar: {
                        percentage: adultPercentage,
                        label: "Adults",
                        value: slice.points[0].data.adultCount,
                        color: "var(--purple-background-2)",
                        tooltipTitle: `Adults : ${slice.points[0].data.adultCount?.toLocaleString()}`,
                        icon: adultsIcon,
                      },
                      title: "Age Distribution",
                    },
                  ]}
                  key={slice.id}
                  pointDate={slice.points[0].data.xFormatted}
                  graphTooltip
                />
              ) : (
                <GraphTooltip
                  slicePoints={slice.points}
                  timeStamp={String(slice.points[0].data.xFormatted)}
                  key={String(slice.id)}
                  missingInfo={findMissingDataEntry(slice.points)}
                />
              )
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickValues: interval && interval.months && interval.months >= 1 ? "every 2 days" : "every 1 day",
              format: "%a %d %b",
              legend: "",
              tickSize: 6,
              tickPadding: 9,
              tickRotation: -45,
              legendPosition: "middle",
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 8,
              tickRotation: 0,
              format: (e) => Math.floor(e) === e && e,
              legend: "",
              legendOffset: -50,
              legendPosition: "middle",
            }}
            legends={
              !hasCheckbox
                ? [
                    {
                      anchor: "bottom",
                      direction: "row",
                      justify: false,
                      translateX: 4,
                      translateY: 95,
                      itemsSpacing: 6,
                      itemDirection: "left-to-right",
                      itemWidth: 109,
                      itemHeight: 25,
                      itemOpacity: 0.75,
                      symbolSize: 14,
                      symbolShape: "circle",
                      symbolBorderColor: "rgba(0, 0, 0, .5)",
                      effects: [
                        {
                          on: "hover",
                          style: {
                            itemBackground: "rgba(0, 0, 0, .03)",
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]
                : []
            }
            colors={(line) => graphColors[line.id as keyof typeof graphColors]}
            pointSize={12}
            pointSymbol={(e) => {
              return (
                <circle
                  cx="0"
                  cy="0"
                  r="6"
                  strokeWidth="3"
                  fill={!!e.datum.missingInfo && e.datum.missingInfo?.length > 0 ? "#E5484D" : "transparent"}
                />
              )
            }}
            pointBorderWidth={0}
            pointBorderColor={{ from: "serieColor" }}
            enablePointLabel={false}
            pointLabel="y"
            pointLabelYOffset={-12}
            useMesh={true}
            enableSlices="x"
          />
        </Box>
      )}
      {hasCheckbox && (
        <div
          className={styles.checkBoxesGroup}
          style={{
            visibility: hasCheckbox && !isLoading ? "visible" : "hidden",
          }}
        >
          <div className={styles.checkbox}>
            <FormControlLabel
              className={styles.checkBox}
              style={{ color: "var(--blue-text-2)" }}
              control={
                <Checkbox
                  sx={{
                    color: "var(--blue-text-2)",
                    "&.Mui-checked": {
                      color: "var(--blue-text-2)",
                    },
                  }}
                  checked={checkBoxStates.countIn}
                  onChange={handleChange}
                  name="countIn"
                />
              }
              label="Incount"
            />
          </div>
          <div className={styles.checkbox}>
            <FormControlLabel
              className={styles.checkBox}
              style={{ color: "var(--pink-background-1)" }}
              control={
                <Checkbox
                  sx={{
                    color: "var(--pink-background-1)",
                    "&.Mui-checked": {
                      color: "var(--pink-background-1)",
                    },
                  }}
                  checked={checkBoxStates.countOut}
                  onChange={handleChange}
                  name="countOut"
                />
              }
              label="Outcount"
            />
          </div>
        </div>
      )}
    </Box>
  )
}

export default DailyLineGraph
