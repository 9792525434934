import React, { FC, useEffect, useState, Fragment, useMemo } from "react"
import { useQuery } from "react-query"
import { useNavigate } from "react-router-dom"

import { Grid, CircularProgress } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import moment, { Moment } from "moment"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../../API/VisionAPI"
import Podium from "../../../components/Podium"
import { default as LeaderBoardTable } from "../../../components/tables/LeaderBoardTable"
import { routes } from "../../../routes/routes"
import { useBranchesStore } from "../../../store"
import { TableColumn } from "../../../types/Custom/Types"
import { definitions } from "../../../types/Generated/apiTypes"
import {
  calculateShopsTotalCounts,
  calculateShopsAverageDwellingTime,
  calculateTableData,
  adjustCategoriesAndSubcategories,
} from "../../../utils/shopUtils"

import styles from "./Leaderboard.module.scss"

type Category = definitions["Category"]
type Shop = definitions["Shop"]
type EntitiesLogs = definitions["EntitiesLogs"]
type DwellingLogs = definitions["DwellingLogs"]
type shopData = definitions["Shop"] & {
  "Count In": number
  area_of_category: number
  area_of_subcategory: number
  category_count: number
  dwelling: number | string
  subcategory_count: number
  fair_share_of_category: number | string
  occupancyRate: number | string
  tableData: { id: number }
  index?: string
}
interface Props {
  startDate?: Moment | null
  endDate?: Moment | null
  timeGrain: "hour" | "day"
  selectedCategory: any
  categories?: Category[]
  categoriesLoading?: boolean
  shouldIncludeStaff?: boolean
}

const weekAgo = moment().subtract(7, "days")
const now = moment()

const Leaderboard: FC<Props> = ({
  startDate,
  endDate,
  categories,
  categoriesLoading,
  timeGrain,
  selectedCategory,
  shouldIncludeStaff,
}) => {
  const [tableData, setTableData] = useState<shopData[]>([])
  const [clonedShopsTableData, setClonedShopsTableData] = React.useState([])
  const [selectedBranch] = useBranchesStore(
    (state: { selectedBranch: number | null }) => [state.selectedBranch],
    shallow
  )

  const navigate = useNavigate()
  // fetching data
  const { data: shopsLogs, isLoading: shopsLogsLoading } = useQuery<EntitiesLogs>(
    [
      "fetchShopsCounts",
      startDate?.format("YYYY-MM-DD"),
      endDate?.format("YYYY-MM-DD"),
      timeGrain,
      selectedBranch,
      shouldIncludeStaff,
    ],
    ({ queryKey }) =>
      VisionAPI.fetchShopsCounterStats({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        date_slice: queryKey[3] as string,
        branch: queryKey[4] as number,
        include_staff: queryKey[5] as boolean,
      }),
    {
      enabled: !!startDate && !!endDate && !!timeGrain && !!selectedBranch,
    }
  )

  const { data: shopEntitiesData, isLoading: shopEntitiesLoading } = useQuery<Shop[], AxiosError>(
    ["fetchShopsEntities", selectedBranch],
    ({ queryKey }) => VisionAPI.fetchShops({ branch: queryKey[1] as number }),
    {
      enabled: !categoriesLoading && !!selectedBranch,
    }
  )

  const { data: shopsDwellingLogs, isLoading: shopsDwellingLogsLoading } = useQuery<DwellingLogs[]>(
    [
      "fetchShopsDwellingLogs",
      startDate?.format("YYYY-MM-DD"),
      endDate?.format("YYYY-MM-DD"),
      shouldIncludeStaff,
      timeGrain,
    ],
    ({ queryKey }) =>
      VisionAPI.fetchShopsDwelling({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        include_staff: queryKey[3] as boolean,
        date_slice: queryKey[4] as string,
      }),
    {
      enabled: !!startDate && !!endDate && !!timeGrain,
    }
  )

  const isLoading = [shopsLogsLoading, shopEntitiesLoading, shopsDwellingLogsLoading, categoriesLoading].some(
    (element) => element === true
  )

  // Use useMemo to format shop entities
  const shopEntities = useMemo(() => {
    return adjustCategoriesAndSubcategories(shopEntitiesData, categories)
  }, [categories, shopEntitiesData])

  //   cloning table data from shop entities
  useEffect(() => {
    if (shopEntities && !shopEntitiesLoading) {
      setClonedShopsTableData(Object.values(JSON.parse(JSON.stringify(shopEntities))))
    }
  }, [shopEntities, shopEntitiesLoading])
  // calculating fair share of category
  // and thereafter formatting table rows (adding ordinal suffix , sorting , slicing to top 10 , removing nan values)
  useEffect(() => {
    if (
      !shopsLogsLoading &&
      !shopsDwellingLogsLoading &&
      shopsLogs &&
      shopsDwellingLogs &&
      clonedShopsTableData.length > 0
    ) {
      const calculatedTableData: shopData[] = calculateTableData(
        clonedShopsTableData,
        calculateShopsTotalCounts(shopsLogs?.entities_logs),
        calculateShopsAverageDwellingTime(shopsDwellingLogs)
      )
      const fairShareAddedData = calculatedTableData.map((element) => {
        return {
          ...element,
          occupancyRate: element.area ? parseFloat((element["Count In"] / element["area"]).toFixed(2)) : 0,
          fair_share_of_category: element.area
            ? parseFloat(
                (
                  element["Count In"] /
                  element["category_count"] /
                  (element["area"]! / element["area_of_category"])
                ).toFixed(2)
              )
            : 0,
        }
      })

      // filter table data to include only data of selected category
      // then sort table data to pass it sorted to the podium
      const filteredTableData: shopData[] = fairShareAddedData
        .filter((element) => (selectedCategory ? element.category === selectedCategory.name : element))
        .sort((a, b) => (a["Count In"] > b["Count In"] ? -1 : 1))
      // Tmp -> handle NaN shops
      for (let i = 0; i < filteredTableData.length; i++) {
        if (filteredTableData[i]["Count In"] <= 0 || !filteredTableData[i]["Count In"]) {
          filteredTableData[i].dwelling = "No Data"
          filteredTableData[i].fair_share_of_category = "No Data"
          filteredTableData[i].occupancyRate = "No Data"
        }
        // add ordinal suffix if not added to table rows (1st,2nd...)
        if (!filteredTableData[i]["index"]) {
          filteredTableData[i]["index"] = moment.localeData().ordinal(i + 1)
        }
      }
      // slice table data from 0 to 10
      filteredTableData.splice(10)

      setTableData(filteredTableData)
    }
  }, [clonedShopsTableData, shopsLogs, shopsDwellingLogsLoading, shopsDwellingLogs, shopsLogsLoading, selectedCategory])

  const isEmptyTable = tableData?.filter((shopData) => shopData?.["Count In"] > 0)?.length < 1

  const tableColumns: TableColumn[] = [
    {
      title: "Place",
      field: "index",
      searchable: false,
      render: (rowData: shopData) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className={styles.ordinaryTableRow}>{rowData.index}</div>
        </div>
      ),
    },
    {
      title: "Tenant Name",
      field: "name",
      searchable: false,
      render: (rowData: shopData) => (
        <div
          className={`${styles.ordinaryTableRow} ${styles.tenantName}`}
          title={rowData.name}
          onClick={() =>
            navigate(`/${routes?.tenantsList}/${routes?.tenantDetails}/${rowData.id}`, {
              state: {
                startDate: weekAgo?.toDate(),
                endDate: now?.toDate(),
                uniqueRowId: rowData.id,
                tenantName: rowData.name,
                area: rowData.area,
                category: rowData.category,
                subcategory: rowData.subcategory,
              },
            })
          }
        >
          {rowData?.name && rowData?.name?.length > 19 ? rowData?.name.slice(0, 17) + ".." : rowData.name}
        </div>
      ),
    },
    {
      title: "Visitor Counts",
      field: "Count In",
      searchable: false,
      render: (rowData: shopData) => (
        <div className={styles.ordinaryTableRow}>
          {rowData["Count In"] && rowData["Count In"] > 0 ? rowData["Count In"] : "No Data"}
        </div>
      ),
    },
    {
      title: "Fair Share",
      field: "fair_share_of_category",
      searchable: false,
      render: (rowData: shopData) => <div className={styles.ordinaryTableRow}>{rowData.fair_share_of_category}</div>,
      cellStyle: {
        textAlign: "left",
      },
      headerStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Sub-category",
      field: "subcategory",
      searchable: false,
      render: (rowData: shopData) => (
        <div className={styles.ordinaryTableRow} title={rowData.subcategory ? `${rowData.subcategory}` : "subcategory"}>
          {rowData.subcategory
            ? `${rowData?.subcategory}`?.length > 19
              ? `${rowData?.subcategory}`.slice(0, 17) + ".."
              : rowData?.subcategory
            : "-"}
        </div>
      ),
    },
  ]

  return (
    <div className={styles.wrapper}>
      <Typography variant="h2-bold" gutterBottom className={styles.title} align="center">
        Leaderboard
      </Typography>
      {isLoading ? (
        <Grid container direction="row" justifyContent="center" alignItems="center" className={styles.loadingContainer}>
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <Fragment>
          {!isEmptyTable && (
            <Podium
              data={{
                first: {
                  name: tableData[0]?.name,
                  value: tableData[0]?.["Count In"],
                  subcategory: tableData[0]?.subcategory,
                },
                second: {
                  name: tableData[1]?.name,
                  value: tableData[1]?.["Count In"],
                  subcategory: tableData[1]?.subcategory,
                },
                third: {
                  name: tableData[2]?.name,
                  value: tableData[2]?.["Count In"],
                  subcategory: tableData[2]?.subcategory,
                },
              }}
            />
          )}
          <LeaderBoardTable
            isLoading={isLoading}
            data={tableData}
            columns={tableColumns}
            title="LeaderBoard"
            isEmpty={isEmptyTable}
          />
        </Fragment>
      )}
    </div>
  )
}
export default Leaderboard
