import React, { FC } from "react"

// icons
import CameraIcon from "@mui/icons-material/CameraAltOutlined"
import CategoryIcon from "@mui/icons-material/Category"
import CarsIcon from "@mui/icons-material/Commute"
import MCPIcon from "@mui/icons-material/CompareArrows"
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun"
import NodesIcon from "@mui/icons-material/DnsOutlined"
import MarketingIcon from "@mui/icons-material/Equalizer"
import SecurityEventsIcon from "@mui/icons-material/GridOn"
import APIIntegrationIcon from "@mui/icons-material/IntegrationInstructionsOutlined"
import FloorIcon from "@mui/icons-material/Map"
import PlaceIcon from "@mui/icons-material/Place"
import CameraLive from "@mui/icons-material/VideocamOutlined"
import RolesIcon from "@mui/icons-material/VpnKeyOutlined"
import HeatmapIcon from "@mui/icons-material/Whatshot"
import { Paper } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"

import { routes } from "../../../routes/routes"
import Auth from "../../../utils/auth"
import AccordionItem from "./AccordionItem"
import SidebarItem from "./SidebarItem"

import styles from "./UserData.module.scss"

interface Props {
  persona: string
  activeScreen: string
}

const UserData: FC<Props> = ({ activeScreen, persona }) => {
  const hasAdminGenericItems =
    persona === "admin" &&
    [Auth.getCategoriesPermissions(), Auth.getUsersPermission(), Auth.getCarsAdminPermission()].some(
      (condition) => !!condition
    )

  return (
    <Paper className={styles.userData}>
      <Typography variant="label" textTransform="capitalize" variantColor={2} gutterBottom className={styles.descTitle}>
        {persona} details
      </Typography>

      {/* Analytics Persona Items */}

      {/* People Count */}

      {persona === "analytics" && Auth.getPeopleCountPermission() && (
        <AccordionItem
          ItemIcon={DirectionsRunIcon}
          activeScreen={activeScreen}
          itemLabel="People Count"
          subRoutes={[
            routes?.liveGates,
            routes?.historyGates,
            routes?.tenants,
            routes?.tenantsList,
            routes?.tenantDetails,
            routes?.corridors,
            routes?.checkoutCounter,
            routes?.missingData,
          ]}
          accordionItems={[
            <SidebarItem
              activeScreen={activeScreen}
              itemLabel="Gates"
              accordionItem
              route={`${routes?.gates}`}
              subRoutes={[routes?.liveGates, routes?.historyGates]}
            />,
            <SidebarItem activeScreen={activeScreen} itemLabel="Categories" route={routes?.tenants} accordionItem />,
            <SidebarItem
              activeScreen={activeScreen}
              itemLabel="Tenants"
              route={routes?.tenantsList}
              subRoutes={[routes?.tenantDetails]}
              accordionItem
            />,
            <SidebarItem
              activeScreen={activeScreen}
              itemLabel="Dwelling Areas"
              route={routes?.dwellingAreas}
              subRoutes={[routes?.dwellingAreaDetails]}
              accordionItem
            />,
            <SidebarItem
              activeScreen={activeScreen}
              itemLabel="Checkout Counter"
              route={routes?.checkoutCounter}
              accordionItem
            />,
            <SidebarItem activeScreen={activeScreen} itemLabel="Corridors" route={routes?.corridors} accordionItem />,
            Auth.getMissingDataPermission() && (
              <SidebarItem
                activeScreen={activeScreen}
                itemLabel="Missing Data"
                route={routes?.missingData}
                accordionItem
              />
            ),
          ]}
        />
      )}

      {/* Most Common Paths */}

      {persona === "analytics" && Auth.getMcpPermissions() && (
        <AccordionItem
          ItemIcon={MCPIcon}
          activeScreen={activeScreen}
          itemLabel="Most Common Paths"
          subRoutes={[routes?.floorsMCP, routes?.camerasMCP]}
          accordionItems={[
            <SidebarItem activeScreen={activeScreen} itemLabel="Floors MCP" accordionItem route={routes?.floorsMCP} />,
            <SidebarItem
              activeScreen={activeScreen}
              itemLabel="Cameras MCP"
              accordionItem
              route={routes?.camerasMCP}
            />,
          ]}
        />
      )}

      {/* Heatmap */}

      {persona === "analytics" && Auth.getHeatmapPermission() && (
        <AccordionItem
          ItemIcon={HeatmapIcon}
          activeScreen={activeScreen}
          itemLabel="Heatmap"
          subRoutes={[routes?.heatmapLive, routes?.heatmapHistory, routes?.cameraHeatmap]}
          accordionItems={[
            <SidebarItem
              activeScreen={activeScreen}
              itemLabel="Floors Heatmap"
              accordionItem
              route={routes?.heatmapLive}
              subRoutes={[routes?.heatmapHistory]}
            />,
            <SidebarItem
              activeScreen={activeScreen}
              itemLabel="Cameras Heatmap"
              accordionItem
              route={routes?.cameraHeatmap}
            />,
          ]}
        />
      )}

      {/* Cars Analytics */}
      {persona === "analytics" && Auth.getCarsAnalyticsPermission() && (
        <SidebarItem
          activeScreen={activeScreen}
          itemLabel="Cars Analytics"
          route={`${routes?.carsAnalytics}`}
          ItemIcon={CarsIcon}
        />
      )}

      {/* Admin Persona Items */}

      {/* Cameras List */}

      {persona === "admin" && Auth.getCamerasPermission() && (
        <AccordionItem
          ItemIcon={CameraIcon}
          activeScreen={activeScreen}
          itemLabel="Cameras"
          subRoutes={[routes?.cameraList, routes?.cameraDetails, routes?.cameraViewChanges]}
          accordionItems={[
            <SidebarItem
              activeScreen={activeScreen}
              itemLabel="Cameras List"
              route={routes?.cameraList}
              subRoutes={[routes?.cameraDetails]}
              accordionItem
            />,
            <SidebarItem
              activeScreen={activeScreen}
              itemLabel="Camera View Changes"
              route={routes?.cameraViewChanges}
              accordionItem
            />,
          ]}
        />
      )}

      {/* Floor setup */}

      {persona === "admin" && Auth.getFloorPermission() && (
        <SidebarItem activeScreen={activeScreen} itemLabel="Floors" route={routes?.floors} ItemIcon={FloorIcon} />
      )}

      {/* Entities & Regions */}

      {persona === "admin" && Auth.getRegionsPermission() && (
        <SidebarItem
          activeScreen={activeScreen}
          itemLabel="Entities & Regions"
          route={routes?.entitiesAndRegions}
          ItemIcon={PlaceIcon}
        />
      )}

      {/* Nodes */}

      {persona === "admin" && Auth.getNodesPermission() && (
        <SidebarItem
          activeScreen={activeScreen}
          itemLabel="Nodes"
          route={routes?.nodesList}
          subRoutes={[routes?.nodeDownAnalytics]}
          ItemIcon={NodesIcon}
        />
      )}

      {/* Generic Items */}

      {hasAdminGenericItems && (
        <Typography variant="label" variantColor={2} gutterBottom>
          Generic Items
        </Typography>
      )}

      {/* Categories Setup */}

      {persona === "admin" && Auth.getCategoriesPermissions() && (
        <SidebarItem
          activeScreen={activeScreen}
          itemLabel="Categories Management"
          route={routes?.categoriesList}
          ItemIcon={CategoryIcon}
        />
      )}

      {/* Users & Permissions */}

      {persona === "admin" && Auth.getUsersPermission() && (
        <SidebarItem
          activeScreen={activeScreen}
          itemLabel="Users & Permissions"
          route={routes?.users}
          subRoutes={[routes?.createUser]}
          ItemIcon={RolesIcon}
        />
      )}

      {/* Cars Admin */}

      {persona === "admin" && Auth.getCarsAdminPermission() && (
        <SidebarItem
          activeScreen={activeScreen}
          itemLabel="Cars Lists"
          route={`${routes?.carsList}`}
          ItemIcon={CarsIcon}
        />
      )}

      {/* Api Integration */}
      {persona === "admin" && Auth.getAPIIntegrationPermission() && (
        <SidebarItem
          activeScreen={activeScreen}
          itemLabel="API Integration"
          route={`${routes?.apiIntegration}`}
          ItemIcon={APIIntegrationIcon}
        />
      )}

      {/* Campaign Planner */}

      {persona === "marketing" && Auth.getCampaignPermission() && (
        <SidebarItem
          activeScreen={activeScreen}
          itemLabel="Campaign List"
          route={routes?.campaign}
          ItemIcon={MarketingIcon}
        />
      )}

      {/* Camera Live */}

      {persona === "security" && Auth.getSecurityCameraStreamPermissions() && (
        <SidebarItem
          activeScreen={activeScreen}
          itemLabel="Live Streams"
          route={routes?.liveStreams}
          ItemIcon={CameraLive}
        />
      )}

      {/* Security Events */}

      {persona === "security" && Auth.getSecurityEventsPermissions() && (
        <SidebarItem
          activeScreen={activeScreen}
          itemLabel="Incidents"
          route={`${routes?.securityIncidents}`}
          ItemIcon={SecurityEventsIcon}
        />
      )}

      {/* Cars Logs */}
      {persona === "security" && Auth.getSecurityCarsPermissions() && (
        <SidebarItem
          activeScreen={activeScreen}
          itemLabel="Car Logs"
          accordionItem
          route={`${routes?.carsLogs}`}
          ItemIcon={CarsIcon}
        />
      )}
    </Paper>
  )
}

export default UserData
