import { useEffect, FC, useRef, useState } from "react"

import { Button } from "@synapse-analytics/synapse-ui"

import styles from "./RegionsCanvas.module.scss"

interface Props {
  imageSrc?: string
  regions?: { state: 0 | 1 | 2; points: { x: number; y: number }[] }[]
  handleClick?: () => void
  width?: number
  height: number
}
const RegionsCanvas: FC<Props> = ({ width, height, imageSrc, regions, handleClick }) => {
  const [isHovering, setIsHovering] = useState(false)

  const ref = useRef<HTMLCanvasElement>(null)

  const handleMouseEnter = () => setIsHovering(true)
  const handleMouseLeave = () => setIsHovering(false)

  // useEffect to draw the provided image onto the canvas and render regions with specified points and states
  useEffect(() => {
    // Create a new Image object
    const image = new Image()

    // Set the source of the image to the provided imageSrc
    image.src = imageSrc || ""

    // When the image is loaded, perform the following actions
    image.onload = () => {
      // Get the canvas element from the ref
      const canvas = ref.current

      // Get the 2D rendering context of the canvas
      const ctx = canvas?.getContext("2d")

      // If the rendering context exists
      if (ctx) {
        // Draw the loaded image onto the canvas
        ctx.drawImage(image, 0, 0, canvas!.width, canvas!.height)

        // Set the stroke style for drawing regions
        ctx.strokeStyle = "var(--neutral-text-enabled)"

        // Set the line width for drawing regions
        ctx.lineWidth = 0.6

        if (regions) {
          // Iterate over each region
          regions.forEach((region) => {
            // Determine the fill style based on the region state
            const fillStyle =
              region.state === 0
                ? "rgba(128, 206, 128, 0.6)"
                : region.state === 1
                ? "rgba(255, 0, 0, 0.6)"
                : "rgba(74, 78, 161, 0.6)"

            // Set the fill style
            ctx.fillStyle = fillStyle

            // Begin drawing a new path
            ctx.beginPath()

            // Move to the first point of the region
            ctx.moveTo(region.points[0].x, region.points[0].y)

            // Iterate over each point of the region
            for (let i = 0; i < region.points.length; i++) {
              // Draw lines to connect the points
              ctx.lineTo(region.points[i].x, region.points[i].y)
            }

            // Close the path
            ctx.closePath()

            // Stroke the path
            ctx.stroke()

            // Fill the path
            ctx.fill()
          })
        }
      }
    }
  }, [imageSrc, regions, ref])

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={styles.wrapper}>
      {isHovering && !!handleClick && (
        <div className={styles.backdrop}>
          <Button variant="secondary" onClick={handleClick}>
            Preview
          </Button>
        </div>
      )}
      <canvas ref={ref} className={styles.drawingBoard} width={width || "100%"} height={height} />
    </div>
  )
}
export default RegionsCanvas
