import { useState, useEffect, Fragment, FC } from "react"

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import UnknownIcon from "@mui/icons-material/NoAccounts"
import { useMediaQuery, Button, Box } from "@mui/material"

import { ComputedDatum, ResponsiveBar } from "@nivo/bar"
import { Typography } from "@synapse-analytics/synapse-ui"
import { intervalToDuration } from "date-fns"
import moment from "moment"

import AdultIcon from "../../assets/adultsIcon.svg?react"
import ChildrenIcon from "../../assets/childrenIcon.svg?react"
import FemaleIcon from "../../assets/femaleIcon.svg?react"
import MaleIcon from "../../assets/maleIcon.svg?react"
import { getBarColor } from "../../utils/graphUtils"
import GraphEmptyState from "./GraphEmptyState"
import GraphLoadingState from "./GraphLoadingState"
import { gridTheme } from "./gridTheme"
import GraphTooltip from "./helpers/GraphTooltip"

import styles from "./PaginatedBarGraph.module.scss"

interface PaginatedBarGraphProps {
  /**
   * Indicates if the graph is currently loading.
   */
  isLoading?: boolean
  /**
   * Function to set the current page.
   */
  setCurrentPage?: (page: number) => void
  /**
   * The current page number.
   */
  currentPage?: number
  /**
   * Indicates if the graph is displaying duration data.
   */
  isDurationGraph?: boolean
  /**
   * Indicates if missing data should be included in the graph.
   */
  shouldIncludeMissingData?: boolean
  /**
   * Custom title for the bar graph.
   */
  customBarTitle?: string
  /**
   * The data for the bar graph.
   */
  barGraphData?: any[]
  /**
   * Keys for age and gender data.
   */
  ageGenderKeys?: string[] | false
  /**
   * The mode for grouping data.
   */
  groupMode?: string
  /**
   * The keys for the data.
   */
  keys?: string[]
  /**
   * The index to use for the data.
   */
  indexBy?: string
  /**
   * The height of the graph.
   */
  height?: number
  /**
   * Indicates if the graph is paginated.
   */
  isPaginated?: boolean
}

/**
 * PaginatedBarGraph component.
 *
 * This component renders a paginated bar graph with optional age and gender keys.
 * It also includes pagination controls and a tooltip for each bar.
 *
 * @param props - The props for the component.
 * @returns The PaginatedBarGraph component.
 */
const PaginatedBarGraph: FC<PaginatedBarGraphProps> = ({
  isLoading,
  setCurrentPage,
  currentPage,
  isDurationGraph,
  shouldIncludeMissingData,
  customBarTitle,
  barGraphData = [],
  ageGenderKeys,
  groupMode = "stacked",
  keys = ["Count In"],
  indexBy = "entity",
  height = 340,
  isPaginated = true,
}) => {
  const xLargeScreen = useMediaQuery("(min-width:1900px)")
  const [sliceData, setSlicedData] = useState(barGraphData)
  const [sliceStIndex, setSliceStIndex] = useState<number>(0)
  const [sliceEndIndex, setSliceEndIndex] = useState<number>(10)

  const shouldRenderPagination = barGraphData && barGraphData.length > 10 && isPaginated

  const hasUnknown = barGraphData.some((element) => Object.keys(element).some((key) => key.includes("unknown")))
  const hasPercentage = barGraphData.some((element) => Object.keys(element).some((key) => key.includes("percent")))
  const isHourlyBars = barGraphData.some((element) => Object.keys(element).some((key) => key.toLowerCase() === "hour"))

  const isEmpty = !barGraphData || (barGraphData && barGraphData.length < 1)

  // This useEffect resets the slice start and end indices to 0 and 10 respectively when the isLoading state changes.
  useEffect(() => {
    setSliceStIndex(0)
    setSliceEndIndex(10)
  }, [isLoading])

  // This useEffect updates the data state by slicing the barGraphData array based on the current sliceStIndex and sliceEndIndex.
  useEffect(() => {
    if (shouldRenderPagination) {
      setSlicedData(barGraphData.slice(sliceStIndex, sliceEndIndex))
    }
  }, [barGraphData, shouldRenderPagination, sliceEndIndex, sliceStIndex])

  const handleRightPagination = () => {
    setSliceStIndex(sliceEndIndex)
    if (sliceEndIndex + 10 >= barGraphData.length) {
      setSliceEndIndex(barGraphData.length)
    } else {
      setSliceEndIndex((prevSliceEndIndex) => prevSliceEndIndex + 10)
    }
    if (currentPage) setCurrentPage?.(currentPage + 1)
  }

  const handleLeftPagination = () => {
    if (sliceStIndex - 10 <= 0) {
      setSliceEndIndex(sliceStIndex)
      setSliceStIndex(0)
    } else {
      setSliceEndIndex(sliceStIndex)
      setSliceStIndex((prevSliceStIndex) => prevSliceStIndex - 10)
    }
    if (currentPage) setCurrentPage?.(currentPage - 1)
  }

  return (
    <div
      style={{
        minHeight: height,
      }}
    >
      {isLoading ? (
        <GraphLoadingState />
      ) : !isEmpty ? (
        <div className={styles.wrapper}>
          <Box
            className={styles.graphContainer}
            sx={{
              height: height - (ageGenderKeys ? 4 : 0),
            }}
          >
            {shouldRenderPagination && (
              <div className={styles.barPaginateWrapper}>
                <Button
                  className={styles.barChartPaginateBTN}
                  onClick={handleLeftPagination}
                  disabled={sliceStIndex === 0 ? true : false}
                >
                  <ChevronLeftIcon />
                </Button>
              </div>
            )}
            <ResponsiveBar
              theme={gridTheme}
              data={shouldRenderPagination ? sliceData : barGraphData}
              keys={ageGenderKeys ? ageGenderKeys : keys}
              indexBy={indexBy}
              margin={{
                top: xLargeScreen ? 60 : 40,
                right: barGraphData?.length > 10 ? 35 : -20,
                bottom: 60,
                left: barGraphData?.length < 10 ? 75 : 35,
              }}
              padding={isPaginated ? 0.6 : 0.3}
              valueScale={{ type: "linear" }}
              colors={
                ageGenderKeys || shouldIncludeMissingData
                  ? ({ id }: ComputedDatum<any>): string => getBarColor(String(id))
                  : ({ data }: ComputedDatum<any>): string => data.color
              }
              animate={true}
              enableLabel={false}
              tooltip={(slice) => {
                const interval = isDurationGraph && intervalToDuration({ start: 0, end: slice.value * 1000 })

                return shouldIncludeMissingData ? (
                  <GraphTooltip
                    barGraphSlice={slice}
                    missingInfo={slice.data.missingInfo}
                    entity={String(slice.indexValue)}
                    key={String(slice.id)}
                  />
                ) : (
                  <div>
                    <span>{slice.data[slice.value]}</span>
                    <table
                      style={{
                        background: "white",
                        color: "inherit",
                        fontSize: "14px",
                        borderRadius: "2px",
                        boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px",
                        padding: "5px 9px",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td style={{ padding: "3px 5px" }}>
                            <span
                              key="chip"
                              style={{
                                display: "block",
                                width: "12px",
                                height: "12px",
                                background: slice.color,
                              }}
                            />
                          </td>
                          <td style={{ padding: "3px 5px" }}>
                            {customBarTitle
                              ? `${slice.indexValue} ${customBarTitle} : `
                              : `${String(slice.id)
                                  .replace(/_/g, " ")
                                  .split(" ")
                                  .map((w: string) => w[0].toUpperCase() + w.substring(1))
                                  .join(" ")} -  ${
                                  isHourlyBars
                                    ? moment().hour(Number(slice.indexValue)).format("h A")
                                    : slice.indexValue
                                }:`}
                          </td>
                          <td style={{ padding: "3px 5px" }}>
                            <strong key="value">
                              {isDurationGraph
                                ? `${
                                    interval
                                      ? `${`${
                                          interval.days ? interval.days * 24 : 0 + (interval.hours || 0)
                                        } Hour(s)`} and ${interval.minutes} Minute(s)`
                                      : ""
                                  }`
                                : hasPercentage
                                ? `${slice.value}%`
                                : slice.value}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )
              }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 0,
                tickPadding: 18,
                tickRotation: xLargeScreen ? 0 : -45,
                legend: "",
                legendPosition: "middle",
                legendOffset: 0,
                format: (value) => {
                  if (isHourlyBars) {
                    return moment().hour(Number(value)).format("h A")
                  }
                  if (value?.length >= 15) {
                    return `${value?.slice(0, 13)}..`
                  }
                },
              }}
              enableGridX={false}
              enableGridY={true}
              minValue={0}
              gridYValues={5}
              axisLeft={{
                tickValues: 5,
                tickSize: 0,
                tickPadding: 0,
                tickRotation: 0,
                format: (value) => {
                  if (isDurationGraph) {
                    const interval = intervalToDuration({ start: 0, end: value * 1000 })
                    const totalHours = (interval.days || 0) * 24 + (interval.hours || 0)
                    const displayHours = totalHours > 0 ? `${totalHours}h` : ""
                    const displayMinutes = (interval.minutes || 0) > 0 ? `${interval.minutes}m` : ""
                    return `${displayHours}${displayHours && displayMinutes ? " " : ""}${displayMinutes}`
                  }
                  if (hasPercentage) {
                    return `${value}%`
                  }
                },
              }}
              labelSkipWidth={0}
              labelSkipHeight={12}
              labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              legends={[]}
              groupMode={groupMode as "stacked" | "grouped" | undefined}
            />
            {shouldRenderPagination && (
              <div className={styles.barPaginateWrapper}>
                <Button
                  className={styles.barChartPaginateBTN}
                  onClick={handleRightPagination}
                  disabled={sliceEndIndex >= barGraphData.length ? true : false}
                >
                  <ChevronRightIcon />
                </Button>
              </div>
            )}
          </Box>
          {ageGenderKeys && ageGenderKeys.length > 0 && (
            <div className={styles.legendContainer}>
              {ageGenderKeys?.some((key) => key.includes("male") || key.includes("female")) ? (
                <Fragment>
                  <Typography className={`${styles.legend} ${styles.maleLegend}`} variant="span">
                    <MaleIcon className={styles.legendIcon} />
                    Male
                  </Typography>
                  <Typography className={`${styles.legend} ${styles.femaleLegend}`} variant="span">
                    <FemaleIcon className={styles.legendIcon} />
                    Female
                  </Typography>
                  {hasUnknown && (
                    <Typography variant="span" variantColor={2} className={`${styles.legend} ${styles.unknownLegend}`}>
                      <UnknownIcon className={styles.legendIcon} fontSize="small" />
                      Unknown
                    </Typography>
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  <Typography variant="span" className={`${styles.legend} ${styles.childLegend}`}>
                    <ChildrenIcon className={styles.legendIcon} />
                    Children
                  </Typography>
                  <Typography variant="span" className={`${styles.legend} ${styles.adultLegend}`}>
                    <AdultIcon className={styles.legendIcon} />
                    Adults
                  </Typography>
                  {hasUnknown && (
                    <Typography variant="span" variantColor={2} className={`${styles.legend} ${styles.unknownLegend}`}>
                      <UnknownIcon className={styles.legendIcon} fontSize="small" />
                      Unknown
                    </Typography>
                  )}
                </Fragment>
              )}
            </div>
          )}
        </div>
      ) : (
        <GraphEmptyState />
      )}
    </div>
  )
}
export default PaginatedBarGraph
