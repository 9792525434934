import { Dispatch, SetStateAction, useEffect, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import moment from "moment"
import queryString from "query-string"

import { useDateStore } from "../store"

// Helper function to return a no-operation function
const noop = (() => {}) as Dispatch<SetStateAction<moment.Moment | null>>

/**
 * Interface for the parameters of the useDateQuery hook.
 */
interface UseDateQueryParams {
  /**
   * Boolean indicating if the day picker mode is active.
   */
  isDayPicker?: boolean
  /**
   * Boolean indicating if the date query should be hidden from URL
   */
  isHidden?: boolean
}

/**
 * Calculates start and end dates based on query params
 * & updates the params according to changes in the page
 * NOTE: all expected date formats are ISO date formats
 * @returns An array containing the start date, a function to set the start date, the end date, and a function to set the end date.
 */
export function useDateQuery({ isDayPicker = false, isHidden = false }: UseDateQueryParams = {}): [
  moment.Moment | null,
  Dispatch<SetStateAction<moment.Moment | null>>,
  moment.Moment | null,
  Dispatch<SetStateAction<moment.Moment | null>>
] {
  const navigate = useNavigate()
  const location = useLocation()

  // Fetch query search params from URL
  const queryParams = useMemo(() => queryString.parse(location.search), [location.search])

  // Access the date store
  const { startDate, endDate, day, setStartDate, setEndDate, setSingleDay } = useDateStore()

  // Merge existing query parameters with the new ones
  const mergedQueryParams = useMemo(() => {
    if (isHidden) return queryParams

    const params = { ...queryParams }
    if (isDayPicker) {
      params.day = day?.toISOString()
      delete params.startDate
      delete params.endDate
    } else {
      params.startDate = startDate?.toISOString()
      params.endDate = endDate?.toISOString()
    }
    return params
  }, [endDate, queryParams, startDate, day, isDayPicker, isHidden])

  // Function to compare two query objects and check if they are the same
  const areQueryParamsEqual = (params1: any, params2: any): boolean => {
    return JSON.stringify(params1) === JSON.stringify(params2)
  }

  // Modify the URL when the dates or page value change, preserving state
  useEffect(() => {
    const dateQuery = queryString.stringify(mergedQueryParams)

    // Prevent navigation if the query params haven't changed
    if (!areQueryParamsEqual(queryParams, mergedQueryParams)) {
      navigate(
        {
          pathname: location.pathname,
          search: dateQuery,
        },
        {
          replace: true,
          state: location.state, // Preserve the existing state object
        }
      )
    }
  }, [location.pathname, mergedQueryParams, navigate, location.state, queryParams])

  // This selection dynamically returns the appropriate date-related state and setter functions based on the 'isDayPicker' flag.
  // If 'isDayPicker' is true, it returns the 'day' state, 'setSingleDay' function, and two null/noop values to maintain a consistent return structure.
  // If 'isDayPicker' is false, it returns the 'startDate', 'endDate' states, and their respective setter functions.
  if (isDayPicker) {
    return [day, setSingleDay, null, noop]
  } else {
    return [startDate, setStartDate, endDate, setEndDate]
  }
}
