import { FC, useMemo } from "react"
import { useQuery } from "react-query"

import { Grid } from "@mui/material"

import { Tag, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import moment, { Moment } from "moment"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../../API/VisionAPI"
import CheckboxesLineGraphCard from "../../../components/GraphCards/CheckboxesLineGraphCard"
import { useBranchesStore } from "../../../store"
import { ColoredLineGraphData, TableColumn } from "../../../types/Custom/Types"
import { definitions } from "../../../types/Generated/apiTypes"
import { convertToLineGraphData } from "../../../utils/securityUtils"
import NumericStat from "./Analytics/NumericStats"

import styles from "./EventsSummary.module.scss"

type SecurityLogs = definitions["SecurityViolationAnalytics"]

interface Props {
  startDate: Moment | null
  endDate: Moment | null
  selectedCameras: number[]
  issueType: string
  interval: Duration
}

const EventsSummary: FC<Props> = ({ startDate, endDate, selectedCameras, issueType, interval }) => {
  const [selectedBranch] = useBranchesStore(
    (state: { selectedBranch: number | null }) => [state.selectedBranch],
    shallow
  )

  const { data: analyticsLogs, isLoading: analyticsLogsLoading } = useQuery<SecurityLogs[], AxiosError>(
    [
      "fetchSecurityAnalyticsLogs",
      issueType,
      selectedCameras,
      startDate?.format("YYYY-MM-DD"),
      endDate?.format("YYYY-MM-DD"),
      selectedBranch,
    ],
    ({ queryKey }) => {
      const selectedCameras = queryKey[2] as number[]
      const cameras = selectedCameras.join(",")

      return VisionAPI.fetchSecurityEventsAnalyticsLogs({
        type: queryKey[1] as string,
        cameras,
        from_date: queryKey[3] as string,
        to_date: queryKey[4] as string,
        branch: queryKey[5] as number,
      })
    },
    {
      enabled: !!startDate && !!endDate && !!selectedBranch,
    }
  )

  const lineGraphData = useMemo(() => convertToLineGraphData(analyticsLogs) as ColoredLineGraphData[], [analyticsLogs])

  const lineGraphTableColumns: TableColumn[] = [
    {
      title: "Day",
      field: "day",
      searchable: true,
      render: (rowData) => moment(rowData.day).format("D MMM YYYY"),
    },
    {
      title: "Motion",
      field: "motion_count",
      searchable: true,
      render: (rowData) => rowData.motion_count ?? 0,
    },
    {
      title: "Fire",
      field: "fire_count",
      searchable: true,
      render: (rowData) => rowData.fire_count ?? 0,
    },
    {
      title: "Violence",
      field: "violence_count",
      searchable: true,
      render: (rowData) => rowData.violence_count ?? 0,
    },
    {
      title: "Intrusion",
      field: "intrusion_count",
      searchable: true,
      render: (rowData) => rowData.intrusion_count ?? 0,
    },
    {
      title: "Blacklist Car",
      field: "blacklist_car_count",
      searchable: true,
      render: (rowData) => rowData.blacklist_car_count ?? 0,
    },
    {
      title: "Blacklist Person",
      field: "blacklist_person_count",
      searchable: true,
      render: (rowData) => rowData.blacklist_person_count ?? 0,
    },
    {
      title: "Stray Animal",
      field: "stray_animal_count",
      searchable: true,
      render: (rowData) => rowData.stray_animal_count ?? 0,
    },
  ]

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Typography variant="a" variantColor={2}>
          Events Summary
        </Typography>
        <Tag variant="grass">Beta</Tag>
      </div>
      <Grid container spacing={2}>
        {/* numeric stats section */}
        <Grid item md={3} xs={12}>
          <NumericStat logs={analyticsLogs} isLoading={analyticsLogsLoading} />
        </Grid>
        {/* graph */}
        <Grid item md={9} xs={12}>
          <CheckboxesLineGraphCard
            graphProps={{
              data: lineGraphData,
            }}
            tableProps={{
              columns: lineGraphTableColumns,
              data: analyticsLogs,
            }}
            isLoading={analyticsLogsLoading}
            title="Incidents Over Time"
            timeGrain="day"
            contentHeight={290}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default EventsSummary
