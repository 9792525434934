import { Fragment, useMemo, useState } from "react"
import { useQuery } from "react-query"

import { Grid } from "@mui/material"

import { DateRangePicker, InputText, Typography } from "@synapse-analytics/synapse-ui"
import { DelimitedNumericArrayParam, useQueryParam, withDefault } from "use-query-params"

import { VisionAPI } from "../../API/VisionAPI"
import CheckboxesLineGraph from "../../components/GraphCards/CheckboxesLineGraphCard"
import PaginatedBarGraph from "../../components/GraphCards/PaginatedBarGraphCard"
import { useDateQuery } from "../../hooks/useDateQuery"
import { EntrancesBarData, TableColumn } from "../../types/Custom/Types"
import { definitions } from "../../types/Generated/apiTypes"
import auth from "../../utils/auth"
import {
  calculateDateSlice,
  convertDataToColoredLineGraphAndTable,
  sortAndColorizeLogs,
} from "../../utils/genericHelpers"
import Leaderboard from "./components/Leaderboard"

import styles from "./BranchesAnalytics.module.scss"

type BranchData = definitions["BranchesAnalytics"]
type Branch = definitions["Branch"]

// map selected branches into comma separated ids string
const getBranchIdsString = (branches: number[]) => {
  return branches?.length > 0 ? branches.join(",") : undefined
}

const BranchesAnalytics = () => {
  const [startDate, setStartDate, endDate, setEndDate] = useDateQuery()
  const [autoCompleteValue, setAutoCompleteValue] = useState("")
  const [selectedBranches, setSelectedBranches] = useQueryParam("branches", withDefault(DelimitedNumericArrayParam, []))

  const userId = auth.getUserId()
  const timeGrain = calculateDateSlice(startDate, endDate)

  const { data: branches, isLoading: branchesLoading } = useQuery<Branch[]>(
    ["fetchUserBranches", userId],
    ({ queryKey }) => VisionAPI?.fetchUserBranches({ user_id: queryKey[1] as string }),
    {
      enabled: !!userId,
    }
  )

  const { data: branchesData, isLoading: branchesDataLoading } = useQuery<BranchData[]>(
    [
      "fetchBranchesCounts",
      startDate?.format("YYYY-MM-DD"),
      endDate?.format("YYYY-MM-DD"),
      timeGrain,
      getBranchIdsString(selectedBranches as number[]),
    ],
    ({ queryKey }) =>
      VisionAPI?.fetchBranchesAnalytics({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        date_slice: queryKey[3] as string,
        branch_ids: queryKey[4] as string,
      }),
    {
      enabled: !!startDate && !!endDate,
    }
  )

  const handleChangeBranches = (selectedBranches: number[]) => {
    setSelectedBranches(selectedBranches)
  }

  // branches mapped to a form SUI Select optionsWithValues accept
  const mappedBranches =
    branches?.map((branch) => ({
      label: branch.name,
      value: branch.id!,
    })) || []

  const { graphData: lineGraphData, tableData: hourlyAvgTableCounts } = useMemo(
    () =>
      convertDataToColoredLineGraphAndTable({
        data: branchesData,
        indexByKey: "branch_name",
        logsKey: "logs",
        logTimeStampKey: "timestamp",
        logCountKey: "count_in_sum",
        timestampFormatting: "D MMM YYYY",
      }),
    [branchesData]
  )

  const lineGraphTableColumns: TableColumn[] = [
    {
      title: "Day",
      field: "timestamp",
      searchable: true,
    },
    ...(branchesData
      ? branchesData.map((branch) => ({
          title: branch.branch_name,
          field: branch.branch_name,
          searchable: false,
          render: (rowData: Record<string, number>) => rowData[branch.branch_name] ?? 0,
        }))
      : []),
  ]

  // converting data to suitable format that nivo bar graph accepts
  // using the appropriate formatting function based on the sent data type
  const barGraphData: EntrancesBarData[] = useMemo(() => {
    if (branchesDataLoading || !branchesData || !endDate) return []

    return sortAndColorizeLogs(branchesData, "total_count_in_sum")
  }, [branchesData, endDate, branchesDataLoading])

  const tableColumns: TableColumn[] = [
    {
      title: "Branch Name",
      field: "branch_name",
      searchable: false,
      render: (rowData: BranchData) => (
        <div title={rowData.branch_name}>
          {rowData.branch_name.length > 19 ? rowData.branch_name.slice(0, 17) + "..." : rowData.branch_name}
        </div>
      ),
    },
    {
      title: "Visitors count",
      field: "total_count_in_sum",
      searchable: false,
    },
  ]

  return (
    <Fragment>
      <Typography
        variant="h2-regular"
        tooltip="Comprehensive analytics dashboard showcasing counts and trends for selected branches"
        tooltipPlacement="right"
        tooltipIconSize={22}
        gutterBottom
        variantColor={2}
      >
        Multiple Branches
      </Typography>
      <div className={styles.filtersWrapper}>
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
          disableFuture
        />
        <InputText
          width={280}
          value={autoCompleteValue}
          handleChange={(event) => setAutoCompleteValue(event.target.value as string)}
          variant="filled"
          multipleModeProps={{
            isActive: true,
            selectedValues: selectedBranches as number[],
            setSelectedValues: handleChangeBranches,
          }}
          placeholder="search for a branch"
          loading={branchesLoading}
          optionsWithValues={mappedBranches}
          hideDescription
        />
      </div>
      <Grid container spacing={2}>
        <Grid container spacing={2} item xs={12} md={8}>
          {/* Branches Counts - Bar graph */}
          <Grid item xs={12}>
            <PaginatedBarGraph
              data={barGraphData}
              isLoading={branchesDataLoading}
              startDate={startDate?.format("YYYY-MM-DD")}
              graphProps={{
                keys: ["total_count_in_sum"],
                indexBy: "branch_name",
              }}
              tableProps={{
                columns: tableColumns,
              }}
              endDate={endDate?.format("YYYY-MM-DD")}
              title="Branches Performance"
              contentHeight={343}
            />
          </Grid>
          {/* Counts Over Time - Line graph */}
          <Grid item xs={12}>
            <CheckboxesLineGraph
              graphProps={{
                data: lineGraphData,
              }}
              tableProps={{
                columns: lineGraphTableColumns,
                data: hourlyAvgTableCounts,
              }}
              isLoading={branchesDataLoading}
              title="Counts Over Time"
              timeGrain={timeGrain}
            />
          </Grid>
        </Grid>
        {/* Branches Leader board  */}
        <Grid item xs={12} md={4}>
          <Leaderboard data={branchesData} isLoading={branchesDataLoading} />
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default BranchesAnalytics
