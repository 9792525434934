import React, { FC, useState, useEffect, useRef, ChangeEvent, useMemo } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"

import { Grid } from "@mui/material"

import { Switch, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import moment, { Moment } from "moment"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../../../API/VisionAPI"
import DataOverTimeCard from "../../../../components/GraphCards/DataOverTimeCard"
import LeaderBoard from "../../../../components/tables/LeaderBoardTable"
import { useBranchesStore } from "../../../../store"
import { LineGraphData, TableColumn } from "../../../../types/Custom/Types"
import { definitions } from "../../../../types/Generated/apiTypes"
import { formatTimestampForTable } from "../../../../utils/genericHelpers"
import {
  calculateShopsTotalCounts,
  calculateShopsAverageDwellingTime,
  calculateTableData,
  adjustCategoriesAndSubcategories,
  calculateShopsSumPerTime,
  getEntityLogs,
  convertShopsDataToHourlyLineGraph,
} from "../../../../utils/shopUtils"
import AvgCountWeek from "../../../EntranceGates/components/AvgCountWeek"
import NumericStats from "./NumericStats"

import styles from "./ShopDetails.module.scss"

type Shop = definitions["Shop"]

type EntitiesLogs = definitions["EntitiesLogs"]
type DwellingLogs = definitions["DwellingLogs"]
type shopData = definitions["Shop"] & {
  "Count In": number
  area_of_category: number
  area_of_subcategory: number
  category_count: number
  dwelling: number | string
  subcategory_count: number
  fair_share_of_category: number | string
  occupancyRate: number | string
  tableData: { id: number }
  index?: string
}

type OverTimeTableData = {
  timestamp: Date | string
  "Count In"?: number
  "Count Out"?: number
}

type Category = definitions["Category"]

type HourlyAVGCounts = definitions["HourlyAVGCounts"]

interface Props {
  startDate?: Moment | null
  endDate?: Moment | null
  timeGrain: "hour" | "day"
  interval: Duration
  setLoadingState: (isLoading: boolean) => void
  tenantId?: number
  tenantCategory?: number
  categories?: Category[]
  categoriesLoading?: boolean
}
interface MatchParams {
  id?: string
}

const ShopDetails: FC<Props> = ({
  startDate,
  endDate,
  timeGrain,
  interval,
  setLoadingState,
  tenantId,
  tenantCategory,
  categories,
  categoriesLoading,
}) => {
  let params: MatchParams = useParams()
  const hourlyDataRef = useRef()
  const id = tenantId ?? parseInt(params!.id!)
  const [clonedShopsTableData, setClonedShopsTableData] = React.useState([])
  const [tableData, setTableData] = useState<shopData[]>([])
  const [shouldIncludeStaff, setShouldIncludeStaff] = useState(false)

  const [selectedBranch] = useBranchesStore(
    (state: { selectedBranch: number | null }) => [state.selectedBranch],
    shallow
  )

  // The data returned by the query, representing all shops' entities
  const { data: shopEntitiesData, isLoading: shopEntitiesLoading } = useQuery<Shop[], AxiosError>(
    // The query key, which is an array containing the parameters used to fetch the data
    [
      "fetchShopsEntities",
      // ID of the selected branch (number)
      selectedBranch,
    ],
    // Function to fetch the entities of all shops based on the provided categories and selected branch
    ({ queryKey }) => VisionAPI.fetchShops({ branch: queryKey[1] as number }),
    {
      enabled: !categoriesLoading && !!selectedBranch,
    }
  )
  // The data returned by the query, representing shop counts
  const { data: shopsLogs, isLoading: shopsLogsLoading } = useQuery<EntitiesLogs>(
    // The query key, which is an array containing the parameters used to fetch the data
    [
      "fetchShopsCounts",
      // Start date of the logs (converted to string)
      startDate?.format("YYYY-MM-DD"),
      // End date of the logs (converted to string)
      endDate?.format("YYYY-MM-DD"),
      // Time granularity (e.g., "hour", "day", etc.)
      timeGrain,
      // ID of the selected branch (number)
      selectedBranch,
      // include staff stats if true
      shouldIncludeStaff,
    ],
    // Function to fetch the shop counts based on the provided parameters
    ({ queryKey }) =>
      VisionAPI.fetchShopsCounterStats({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        date_slice: queryKey[3] as string,
        branch: queryKey[4] as number,
        include_staff: queryKey[5] as boolean,
      }),
    {
      enabled: !!endDate && !!timeGrain && !!selectedBranch,
    }
  )

  // specific shop logs
  const { data: shopCounterLogs, isLoading: shopCounterLogsLoading } = useQuery<EntitiesLogs>(
    // The query key, which is an array containing the parameters used to fetch the data
    [
      // Identifier for the query (useful for debugging and cache management)
      "fetchAvgShopLogs",
      // Start date of the logs (converted to string)
      startDate?.format("YYYY-MM-DD"),
      // End date of the logs (converted to string)
      endDate?.format("YYYY-MM-DD"),
      // Time granularity (e.g., "hour", "day", etc.)
      "hour",
      // Selected branch ID (number)
      selectedBranch,
      // include staff stats if true
      shouldIncludeStaff,
      // ID of the shop (parsed to integer)
      parseInt(params!.id!),
    ],
    // Function to fetch the shop counter statistics based on the query parameters
    ({ queryKey }) =>
      VisionAPI.fetchShopsCounterStats({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        date_slice: queryKey[3] as string,
        branch: queryKey[4] as number,
        include_staff: queryKey[5] as boolean,
        entity_ids: queryKey[6] as number,
      }),
    {
      enabled:
        !!endDate && !!timeGrain && !!interval && (interval!.days! >= 7 || interval!.months! >= 1) && !!selectedBranch,
    }
  )

  // The data returned by the query, representing dwelling logs for all shops
  const { data: shopsDwellingLogs, isLoading: shopsDwellingLogsLoading } = useQuery<DwellingLogs[]>(
    // The query key, which is an array containing the parameters used to fetch the data
    [
      "fetchShopsDwellingLogs",
      // Start date of the logs (converted to string)
      startDate?.format("YYYY-MM-DD"),
      // End date of the logs (converted to string)
      endDate?.format("YYYY-MM-DD"),
      // include staff stats if true
      shouldIncludeStaff,
      // Time granularity (e.g., "hour", "day", etc.)
      timeGrain,
    ],
    // Function to fetch the dwelling statistics based on the provided parameters
    ({ queryKey }) =>
      VisionAPI.fetchShopsDwelling({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        include_staff: queryKey[3] as boolean,
        date_slice: queryKey[4] as string,
      }),
    {
      enabled: !!startDate && !!endDate && !!timeGrain,
    }
  )

  // The data returned by the query, representing the hourly average logs for a specific shop
  const { data: shopHourlyAvgLogs, isLoading: avgLogsLoading } = useQuery<HourlyAVGCounts[]>(
    // The query key, which is an array containing the parameters used to fetch the data
    [
      // Identifier for the query (useful for debugging and cache management)
      "fetchShopHourlyAvgLogs",
      // Start date of the logs (formatted as "YYYY-MM-DD")
      startDate?.format("YYYY-MM-DD"),
      // End date of the logs (formatted as "YYYY-MM-DD")
      endDate?.format("YYYY-MM-DD"),
      // include staff stats if true
      shouldIncludeStaff,
      // ID of the specific shop (parsed to integer)
      parseInt(params!.id!),
    ],
    // Function to fetch the hourly average counts for the specified shop based on the provided parameters
    ({ queryKey }) =>
      VisionAPI.fetchShopHourlyAvgCounts({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        include_staff: queryKey[3] as boolean,
        id: queryKey[4] as number,
      }),
    {
      enabled: !!startDate && !!endDate && !!timeGrain,
    }
  )

  // calculating fair share of category
  // and thereafter formatting table rows (adding ordinal suffix , sorting , slicing to top 10 , removing nan values)
  useEffect(() => {
    if (
      !shopsLogsLoading &&
      !shopsDwellingLogsLoading &&
      shopsLogs?.entities_logs &&
      shopsDwellingLogs &&
      clonedShopsTableData.length > 0
    ) {
      const calculatedTableData: shopData[] = calculateTableData(
        clonedShopsTableData,
        calculateShopsTotalCounts(shopsLogs?.entities_logs),
        calculateShopsAverageDwellingTime(shopsDwellingLogs)
      )
      const fairShareAddedData = calculatedTableData.map((element) => {
        return {
          ...element,
          occupancyRate: element.area ? parseFloat((element["Count In"] / element["area"]).toFixed(2)) : 0,
          fair_share_of_category: element.area
            ? parseFloat(
                (
                  element["Count In"] /
                  element["category_count"] /
                  (element["area"]! / element["area_of_category"])
                ).toFixed(2)
              )
            : 0,
        }
      })
      // then sort table data to pass it sorted to the podium
      const filteredTableData: shopData[] = fairShareAddedData
        .filter((tableEntry) => tableEntry.category === tenantCategory)
        .sort((a, b) => (a["Count In"] > b["Count In"] ? -1 : 1))
      // Tmp -> handle NaN shops
      for (let i = 0; i < filteredTableData.length; i++) {
        if (filteredTableData[i]["Count In"] <= 0 || !filteredTableData[i]["Count In"]) {
          filteredTableData[i].dwelling = "No Data"
          filteredTableData[i].fair_share_of_category = "No Data"
          filteredTableData[i].occupancyRate = "No Data"
        }
        // add ordinal suffix if not added to table rows (1st,2nd...)
        if (!filteredTableData[i]["index"]) {
          filteredTableData[i]["index"] = moment.localeData().ordinal(i + 1)
        }
      }
      // find index of selected tenant if > 10 put it at 10 , if < 10 then leave it there
      const indexOfSelectedTenant = filteredTableData.findIndex((tenant) => tenant.id === parseInt(params!.id!))
      if (indexOfSelectedTenant > 9) {
        filteredTableData[9] = filteredTableData[indexOfSelectedTenant]
      }
      // slice table data from 0 to 10
      filteredTableData.splice(10)

      setTableData(filteredTableData)
    }
  }, [
    clonedShopsTableData,
    shopsLogs,
    shopsDwellingLogsLoading,
    shopsDwellingLogs,
    shopsLogsLoading,
    tenantCategory,
    params,
  ])

  // Use useMemo to format shop entities
  const shopEntities = useMemo(() => {
    return adjustCategoriesAndSubcategories(shopEntitiesData, categories)
  }, [categories, shopEntitiesData])

  useEffect(() => {
    if ([shopsLogsLoading, shopEntitiesLoading, categoriesLoading].some((element) => element === true)) {
      setLoadingState(true)
    } else {
      setLoadingState(false)
    }
  }, [setLoadingState, shopsLogsLoading, shopEntitiesLoading, categoriesLoading])

  //   cloning table data from shop entities
  useEffect(() => {
    if (shopEntities && !shopEntitiesLoading) {
      setClonedShopsTableData(Object.values(JSON.parse(JSON.stringify(shopEntities))))
    }
  }, [shopEntities, shopEntitiesLoading])

  const handleSwitchStaffInclusion = (event: ChangeEvent<HTMLInputElement>) => {
    setShouldIncludeStaff(event.target.checked)
  }

  const tableColumns: any = [
    {
      title: "Place",
      field: "index",
      searchable: false,
      render: (rowData: shopData) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className={rowData.id === id ? styles.highlightedTableRow : styles.ordinaryTableRow}>
            {rowData.index}
          </div>
        </div>
      ),
    },
    {
      title: "Tenant Name",
      field: "name",
      searchable: false,
      render: (rowData: shopData) => (
        <div
          className={`${rowData.id === id ? styles.highlightedTableRow : styles.ordinaryTableRow}`}
          title={rowData.name}
        >
          {rowData?.name && rowData?.name?.length > 19 ? rowData?.name.slice(0, 17) + ".." : rowData.name}
        </div>
      ),
    },
    {
      title: "Visitor Counts",
      field: "Count In",
      searchable: false,
      render: (rowData: shopData) => (
        <div className={rowData.id === id ? styles.highlightedTableRow : styles.ordinaryTableRow}>
          {rowData["Count In"] && rowData["Count In"] > 0 ? rowData["Count In"] : "No Data"}
        </div>
      ),
    },
    {
      title: "Fair Share",
      field: "fair_share_of_category",
      searchable: false,
      render: (rowData: shopData) => (
        <div className={rowData.id === id ? styles.highlightedTableRow : styles.ordinaryTableRow}>
          {rowData.fair_share_of_category}
        </div>
      ),
      cellStyle: {
        textAlign: "left",
      },
      headerStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Sub-category",
      field: "subcategory",
      searchable: false,
      render: (rowData: shopData) => (
        <div
          className={rowData.id === id ? styles.highlightedTableRow : styles.ordinaryTableRow}
          title={rowData.subcategory ? `${rowData.subcategory}` : "subcategory"}
        >
          {rowData.subcategory
            ? `${rowData?.subcategory}`?.length > 19
              ? `${rowData?.subcategory}`.slice(0, 17) + ".."
              : rowData?.subcategory
            : "-"}
        </div>
      ),
    },
  ]

  // converting data to suitable format that nivo bar graph accepts
  // using the appropriate formatting function based on the sent data type
  const lineGraphData = useMemo(() => {
    if (!shopsLogsLoading && shopsLogs && endDate) {
      const lineGraphTableData: OverTimeTableData[] = calculateShopsSumPerTime(
        getEntityLogs(shopsLogs?.entities_logs, tenantId)
      )
      const lineGraphData: LineGraphData[] = convertShopsDataToHourlyLineGraph(
        getEntityLogs(shopsLogs?.entities_logs, tenantId)
      )
      return { lineGraphTableData, lineGraphData }
    }
  }, [endDate, shopsLogs, shopsLogsLoading, tenantId])

  const lineGraphTableColumns: TableColumn[] = [
    {
      title: "Date/Time",
      field: "timestamp",
      searchable: false,
      render: (rowData: OverTimeTableData) => (
        <div>{formatTimestampForTable(rowData.timestamp, timeGrain as string)}</div>
      ),
    },
    {
      title: "Count In",
      field: "Count In",
      searchable: false,
    },
    {
      title: "Count Out",
      field: "Count Out",
      searchable: false,
    },
  ]

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Typography variant="a" variantColor={2}>
          Stats
        </Typography>
        <Switch checked={shouldIncludeStaff} onChange={handleSwitchStaffInclusion} label="Include staff" />
      </div>
      <Grid container spacing={1}>
        <Grid item lg={3} md={4} sm={12} xs={12}>
          {/* Numeric Stat cards  */}
          <NumericStats
            key={`shop-${id}_stats`}
            shopsCounterData={shopsLogs?.entities_logs}
            loading={shopsLogsLoading}
            shopEntities={shopEntities}
            shopEntitiesLoading={shopEntitiesLoading}
            entityGroupsCounts={{
              avg_groups_size: shopCounterLogs?.avg_groups_size,
              groups_count: shopCounterLogs?.total_groups_count,
              counts_without_group: shopCounterLogs?.counts_without_group,
            }}
          />
        </Grid>
        <Grid item lg={9} md={8} sm={12} xs={12}>
          {/* Hourly/Daily Data component */}
          <DataOverTimeCard
            graphProps={{
              data: lineGraphData?.lineGraphData,
              interval: interval,
              shouldDisplayDistribution: true,
              hasCheckbox: false,
            }}
            tableProps={{
              data: lineGraphData?.lineGraphTableData,
              columns: lineGraphTableColumns,
            }}
            timeGrain={timeGrain}
            isLoading={shopsLogsLoading}
            reference={hourlyDataRef}
            contentHeight={490}
            dataType={`Shop gate counts`}
          />
        </Grid>
      </Grid>
      <div style={{ marginTop: 24 }}>
        <LeaderBoard
          isLoading={shopsLogsLoading || shopsDwellingLogsLoading}
          data={tableData}
          columns={tableColumns}
          title="LeaderBoard"
          uniqueRowId={id}
        />
      </div>
      <Grid container spacing={3} style={{ marginTop: 8 }}>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <AvgCountWeek
            logsData={shopCounterLogs?.entities_logs}
            loading={shopCounterLogsLoading}
            interval={interval!}
            key="week-day-average"
          />
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <AvgCountWeek
            logsData={shopHourlyAvgLogs}
            loading={avgLogsLoading}
            interval={interval!}
            hourlyAvg
            key="hourly-average"
          />
        </Grid>
      </Grid>
    </div>
  )
}
export default ShopDetails
