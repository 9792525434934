import React, { FC, useMemo } from "react"

import { Card, Grid } from "@mui/material"

import { Typography, Skeleton } from "@synapse-analytics/synapse-ui"

import LoadingDots from "../../../../components/Loaders/LoadingDots"
import { definitions } from "../../../../types/Generated/apiTypes"
import { sumEvents } from "../../../../utils/securityUtils"
import EventTypeChip from "../EventTypeChip"

import styles from "./NumericStats.module.scss"

type SecurityLogs = definitions["SecurityViolationAnalytics"]

interface Props {
  logs?: SecurityLogs[]
  isLoading: boolean
}

// loading skeletons
let loadingPlaceholders = new Array(6).fill(null).map((_r, i) => (
  <Grid item md={12} key={i}>
    <Skeleton variant="rectangular" height={36} width="auto" />
  </Grid>
))

const NumericStat: FC<Props> = ({ logs, isLoading }) => {
  /**
   * Calculates the total events from the provided logs using the sumEvents utility function.
   * This calculation is memoized to avoid recomputation on every render.
   *
   * @returns The total events object with counts for each event type.
   */
  const totalEvents = useMemo(() => {
    const total = sumEvents(logs)
    return total
  }, [logs])

  return (
    <Card className={styles.cardWrapper}>
      <div className={styles.totalEvents}>
        {isLoading ? (
          <LoadingDots />
        ) : (
          <Typography variant="h1-bold" variantColor={2}>
            {totalEvents.total_events.toLocaleString() || "0"}
          </Typography>
        )}
        <Typography variant="h3-bold">Events in total</Typography>
      </div>
      <Grid container spacing={2}>
        {/* 
        Based on its index in total events object
         Event Type       :: Integer Representation
         FIRE             = 0
         VIOLENCE         = 1
         MOTION           = 2
         INTRUSION        = 3
         BLACKLIST_CAR    = 4
         BLACKLIST_PERSON = 5 
         STRAY_ANIMAL    = 6
         */}
        {isLoading
          ? loadingPlaceholders
          : Object.entries(totalEvents).map(
              ([_key, value], index) =>
                index < 7 && (
                  <Grid item md={12} key={index}>
                    <EventTypeChip isAnalytics type={index} eventCount={value} />
                  </Grid>
                )
            )}
      </Grid>
    </Card>
  )
}
export default NumericStat
