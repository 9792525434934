import { formatDuration } from "date-fns"

import { getApiBaseUrl } from "../lib/axios"
import Auth from "./auth"

// calculating cumulative sum of counts over hours (count in , count out, occupancy)
//                                                 (male count , female count)
//                                                 (adult count, children count)
// used to fill in table of hourly data in HourlyData component that is used for entrance gates and tenants.
export function calculateCarsSumPerTime(counterLogs) {
  const arr = []
  const timeDict = {}
  counterLogs.forEach((elem) => {
    elem.logs.forEach((log) => {
      if (log.timestamp in timeDict) {
        const existingObj = arr[timeDict[log.timestamp]]
        existingObj["Count In"] += log.count_in_sum
        existingObj["Count Out"] += log.count_out_sum
        existingObj["Difference"] += log.count_in_sum - log.count_out_sum
      } else {
        const newObj = { "Count In": 0, "Count Out": 0, Difference: 0 }
        newObj.timestamp = log.timestamp
        newObj["Count In"] = log.count_in_sum
        newObj["Count Out"] = log.count_out_sum
        newObj["Difference"] = log.count_in_sum - log.count_out_sum
        timeDict[newObj.timestamp] = arr.length
        arr.push(newObj)
      }
    })
  })
  // Sort timestamp
  arr.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
  return arr
}

// used in HourlyData in entrance gates stats card and hourly data line graph
// converting data from logs with time stamps
// into a data object suitable to represent count in,count out and occupancy as lines in a line graph
// x => represents hours of the day , y => represents count (in/out/occupancy) number for that hour of the day
// which is ready to be plotted using nivo line graph
export function convertCarsDataToHourlyLineGraph(counterLogs) {
  const arr = calculateCarsSumPerTime(counterLogs)
  let arr2 = [
    { id: "Count Out", data: [{ x: new Date(), y: 0 }] },
    { id: "Count In", data: [{ x: new Date(), y: 0 }] },
    { id: "Occupancy", data: [{ x: new Date(), y: 0 }] },
  ]
  //resetting array after type declaration
  arr2 = [
    { id: "Count Out", data: [] },
    { id: "Count In", data: [] },
    { id: "Occupancy", data: [] },
  ]
  let occupancy = 0
  arr.forEach((elem) => {
    occupancy += elem["Count In"] - elem["Count Out"]
    arr2[2].data.push({ x: new Date(elem.timestamp), y: occupancy > 0 ? occupancy : 0 })
    arr2[1].data.push({ x: new Date(elem.timestamp), y: elem["Count In"] })
    arr2[0].data.push({ x: new Date(elem.timestamp), y: elem["Count Out"] })
  })
  return arr2
}

// formatting logs fetched from backend {date: string , duration:string }
// to be in form of actual date and duration in seconds not just strings,
// to use this duration in seconds in sorting logs and display of bar graph values.
export const formatGraphData = (logs) => {
  let arr = [{ date: "", duration: 0, color: "" }]
  arr = []
  if (logs) {
    logs.forEach((obj) => {
      const totalTimeSpentInSeconds = obj.avg_time_spent
      const row = {
        date: obj.day,
        duration: totalTimeSpentInSeconds,
        color: "var(--indigo-background-2)",
      }
      arr.push(row)
    })
    if (arr.length > 0) {
      // find index of highest duration weekday
      const maxDurationIndex = arr.reduce((maxIndex, obj, index, arr) => {
        return obj.duration > arr[maxIndex].duration ? index : maxIndex
      }, 0)
      // highlight highest duration weekday bar
      arr[maxDurationIndex].color = "var(--indigo-background-1)"
    }
  }
  return arr
}

export function getWeekAgo() {
  const now = new Date()
  const dayStart = new Date()
  const dayEnd = new Date()

  dayStart.setDate(now.getDate() - 7)
  dayStart.setHours(0, 0, 0, 0)
  dayEnd.setHours(23, 59, 59, 999)
  return [dayStart, dayEnd]
}
export function getMonthAgo() {
  const now = new Date()
  const monthStart = new Date()
  const monthEnd = new Date()

  monthStart.setDate(now.getDate() - 30)
  monthStart.setHours(0, 0, 0, 0)
  monthEnd.setHours(23, 59, 59, 999)
  return [monthStart, monthEnd]
}

export function getCurrentDay() {
  const now = new Date()
  const dayStart = new Date()
  const dayEnd = new Date()

  if (now.getHours() < 4) {
    dayStart.setDate(now.getDate() - 1)
    dayStart.setHours(4, 0, 0, 0)
  } else {
    dayStart.setHours(4, 0, 0, 0)
    dayEnd.setDate(now.getDate() + 1)
    dayEnd.setHours(3, 59, 59, 999)
  }
  return [dayStart, dayEnd]
}

export const ExportReport = async (
  startDate,
  endDate,
  licensePlate,
  carColor,
  carState,
  listOfInterest,
  carBrand,
  carType
) => {
  try {
    const target = `${getApiBaseUrl()}/api/cars/logs/list/report/?search=${licensePlate}&car_color=${carColor}&state=${carState}&list_of_interest=${listOfInterest}&brand=${carBrand}&car_type=${carType}&start_dt=${startDate}&end_dt=${endDate}`
    // ?.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
    const res = await fetch(target, {
      method: "get",
      headers: {
        "content-type": "text/csv;charset=UTF-8",
        Authorization: "Bearer " + Auth.getAccessToken(),
      },
    })

    if (res.status === 200) {
      const data = await res.text()
      return data
    } else {
      console.log(`Error code ${res.status} when loading cars CSV`)
      return Promise.reject(new Error("Error fetching cars report"))
    }
  } catch (err) {
    console.log(err)
  }
}

export const carsColors = [
  { value: "", color: "All Colors" },
  { value: "w", color: "White" },
  { value: "k", color: "Black" },
  { value: "bg", color: "Blue-Green" },
  { value: "gs", color: "Gray-Silver" },
  { value: "ro", color: "Red-Orange" },
  { value: "yb", color: "Yellow-Brown" },
]

export const carsBrands = [
  "audi",
  "bmw",
  "brilliance",
  "byd",
  "chery",
  "chevrolet",
  "citroen",
  "fiat",
  "ford",
  "geely",
  "honda",
  "hyundai",
  "jeep",
  "kia",
  "mazda",
  "mercedes",
  "mg",
  "mini",
  "mitsubishi",
  "nissan",
  "peugeot",
  "renault",
  "seat",
  "skoda",
  "subaru",
  "suzuki",
  "toyota",
  "volkswagen",
  "volvo",
  "opel",
]

export const bodyTypes = [
  { value: "", label: "All Types" },
  { value: "car", label: "Car" },
  { value: "trk", label: "Truck" },
  { value: "bus", label: "Bus" },
  { value: "moto", label: "Motorcycle" },
  { value: "bike", label: "Bicycle" },
]

export const carsStates = [
  { value: "", label: "In & Out" },
  { value: "0", label: "In Cars" },
  { value: "1", label: "Out Cars" },
]

export function sortAndColorizeCarCounts(carCounts) {
  if (carCounts && carCounts.length > 0) {
    // Sort the array by the 'count' property in descending order
    carCounts.sort((a, b) => b.count - a.count)

    // Set the color property based on the index in the sorted array
    carCounts.forEach((carCount, index) => {
      carCount.color = index === 0 ? "var(--indigo-background-1)" : "var(--indigo-background-2)"
    })
  }

  return carCounts
}

/**
 * Parses a duration string into an object with hours, minutes, and seconds.
 *
 * @param {string} duration - The duration string to parse (format "HH:mm:ss").
 * @returns {object} - An object with hours, minutes, and seconds.
 */
const parseDuration = (duration) => {
  const [hours, minutes, seconds] = duration.split(":").map(Number)
  return { hours, minutes, seconds }
}

/**
 * Converts a duration string in the format "HH:mm:ss" to a human-readable format like "1 hour and 13 minutes".
 *
 * @param {string} duration - The duration string to convert (format "HH:mm:ss").
 * @returns {string} - The human-readable duration.
 */
export function humanReadableDuration(duration) {
  const parsedDuration = parseDuration(duration)
  return formatDuration(parsedDuration, { format: ["hours", "minutes"] })
}

/**
 * Converts a duration string in the format "HH:mm:ss" to the total number of seconds.
 * If the duration is undefined, it returns 0.
 *
 * @param {string} duration - The duration string to convert (format "HH:mm:ss").
 * @returns {number} - The total number of seconds.
 */
export function visitDurationToSeconds(duration) {
  if (!duration) {
    return 0
  }

  const { hours, minutes, seconds } = parseDuration(duration)
  return hours * 60 * 60 + minutes * 60 + seconds
}

/**
 * Sorts car visits based on duration and formats the data.
 *
 * @param {Array} carData - Array of CarVisit objects.
 * @returns {Array} - Formatted and sorted data.
 */
export function processCarVisitData(carData) {
  if (!carData || !Array.isArray(carData)) {
    return []
  }

  // Sort the car data based on duration in descending order
  const sortedData = carData.sort((a, b) => {
    return visitDurationToSeconds(b?.duration) - visitDurationToSeconds(a?.duration)
  })

  // Handle case when all durations are undefined
  const maxDuration = sortedData.length > 0 ? visitDurationToSeconds(sortedData[0]?.duration) : 0

  // Format the sorted data
  return sortedData.map((c) => {
    return {
      "Count In": visitDurationToSeconds(c.duration),
      entity: c.entity_name,
      color:
        visitDurationToSeconds(c.duration) === maxDuration && maxDuration > 0
          ? "var(--indigo-background-1)"
          : "var(--indigo-background-2)",
    }
  })
}
