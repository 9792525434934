import { Fragment, SyntheticEvent, forwardRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { useInfiniteQuery } from "react-query"

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import CloseIcon from "@mui/icons-material/Close"
import VerticalAlignTopIcon from "@mui/icons-material/VerticalAlignTop"
import { Grid, AlertTitle, IconButton, Snackbar, useMediaQuery, CircularProgress } from "@mui/material"
import MuiAlert, { AlertProps } from "@mui/material/Alert"

import { Button, Skeleton, Tab, Tabs, Typography } from "@synapse-analytics/synapse-ui"
import { StringParam, useQueryParams, withDefault } from "use-query-params"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../API/VisionAPI"
import NoLogs from "../../assets/NoHistoryLogs.svg?react"
import Search from "../../components/Search"
import { useDebounceSearch } from "../../hooks/useDebouncedSearch"
import { useBranchesStore } from "../../store"
import { PaginatedCVCLogs } from "../../types/Custom/Interfaces"
import { definitions } from "../../types/Generated/apiTypes"
import { extractPageFromBackEndPaginationLink } from "../../utils/genericHelpers"
import ResolvedCard from "./components/ResolvedCard"
import UnresolvedCard from "./components/UnresolvedCard"

import styles from "./CVC.module.scss"

type CVCLog = definitions["CameraViewChangeLog"]

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      sx={{ borderRadius: 2, display: "flex", alignItems: "center", background: "var(--green-background-2)" }}
      {...props}
    />
  )
})

const CVC = () => {
  const [query, setQuery] = useQueryParams({
    activeTab: withDefault(StringParam, "unresolved"),
    searchValue: withDefault(StringParam, ""),
  })

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)

  const [selectedBranch] = useBranchesStore(
    (state: { selectedBranch: number | null }) => [state.selectedBranch],
    shallow
  )

  // limit calculation
  const smallScreen = useMediaQuery("(min-width:1400px)")
  const mediumScreen = useMediaQuery("(min-width:1525px)")
  const largeScreen = useMediaQuery("(min-width:1600px)")
  let limit = 4
  switch (query.activeTab) {
    case "resolved":
      if (largeScreen) {
        limit = 12
      } else if (mediumScreen) {
        limit = 8
      } else if (smallScreen) {
        limit = 4
      } else {
        limit = 2
      }
      break
    case "unresolved":
      limit = 4
      break
  }

  const loadingPlaceholders = new Array(limit).fill(null).map((_r, i) => (
    <Grid item sm={6} xs={12} md={query.activeTab === "unresolved" ? 6 : 2.4} key={i}>
      <Skeleton variant="rectangular" height={query.activeTab === "unresolved" ? 436 : 220} width="100%" />
    </Grid>
  ))

  const debouncedSearchValue = useDebounceSearch(query.searchValue, 500)

  const {
    data: paginatedCVCLogs,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useInfiniteQuery<PaginatedCVCLogs>(
    ["fetchCameraViewChangeLogs", selectedBranch, limit, debouncedSearchValue, query.activeTab],
    ({ queryKey, pageParam = 1 }) => {
      const shouldFetchResolvedLogs = (queryKey[4] as "resolved" | "unresolved") === "resolved"
      return VisionAPI.fetchCameraViewChangeLogs({
        branch: queryKey[1] as number,
        limit: queryKey[2] as number,
        search: queryKey[3] as string,
        page: pageParam as number,
        resolved: shouldFetchResolvedLogs,
      })
    },
    {
      getNextPageParam: (lastPage: PaginatedCVCLogs) => {
        return lastPage?.next ? extractPageFromBackEndPaginationLink(lastPage.next) : undefined
      },
      enabled: !!selectedBranch,
    }
  )

  const handleOpenSnackbar = () => {
    setIsSnackbarOpen(true)
  }

  const handleCloseSnackbar = (_event?: SyntheticEvent | Event, reason?: string) => {
    if (reason !== "clickaway") {
      setIsSnackbarOpen(false)
    }
  }

  const handleTabChange = (tab: "resolved" | "unresolved"): void => {
    setQuery({ activeTab: tab })
  }

  const handleNavigateToResolved = () => {
    handleTabChange("resolved")
    handleCloseSnackbar()
  }

  const handleSearch = (value: string) => {
    setQuery({ searchValue: value })
  }

  return (
    <div className={styles.wrapper}>
      <Typography
        variant="h2-regular"
        tooltip="Observe changes occurred to cameras' positions and orientation"
        tooltipPlacement="right"
        tooltipIconSize={22}
        gutterBottom
        variantColor={2}
      >
        Camera view changes
      </Typography>
      <div className={styles.searchAndTabs}>
        <Search handleSearch={handleSearch} searchValue={query.searchValue} placeholder="Search by camera name" />
        <Tabs value={query.activeTab}>
          <Tab
            label="Unresolved cameras"
            onClick={() => handleTabChange("unresolved")}
            value="unresolved"
            selected={query.activeTab === "unresolved"}
            id="unresolved"
          />
          <Tab
            label="Resolved cameras"
            onClick={() => handleTabChange("resolved")}
            value="resolved"
            selected={query.activeTab === "resolved"}
            id="resolved"
          />
        </Tabs>
      </div>

      {/* CVC Cards */}
      {isLoading ? (
        <Grid container spacing={2} className={styles.content}>
          {loadingPlaceholders}
        </Grid>
      ) : paginatedCVCLogs && paginatedCVCLogs?.pages?.length > 0 ? (
        <div className={styles.content}>
          <InfiniteScroll
            dataLength={paginatedCVCLogs?.pages.reduce((acc, page) => acc + page.results.length, 0)}
            hasMore={!!hasNextPage}
            next={fetchNextPage}
            loader={<CircularProgress className={styles.loadingMoreLogs} />}
            scrollableTarget="feed-container"
            endMessage={
              paginatedCVCLogs.pages.length > 1 && paginatedCVCLogs.pages[0].count > 0 ? (
                <div className={styles.scrollEnd}>
                  <Button
                    startIcon={<VerticalAlignTopIcon className={styles.arrowUp} />}
                    onClick={() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })}
                    variant="secondary"
                  >
                    Scroll top
                  </Button>
                  <Typography variant="h3-bold">You reached the bottom</Typography>
                </div>
              ) : (
                paginatedCVCLogs.pages[0].count === 0 && (
                  <div className={styles.logsPlaceholderWrapper}>
                    <NoLogs />
                    <div>
                      <Typography variant="h2-bold" className={styles.boldTitle}>
                        No logs yet
                      </Typography>
                    </div>
                  </div>
                )
              )
            }
            scrollThreshold={0.5}
          >
            <Grid container spacing={2}>
              {paginatedCVCLogs?.pages.map((page) =>
                page.results.map((log: CVCLog, i: number) => (
                  <Fragment key={i}>
                    {query.activeTab === "unresolved" ? (
                      <UnresolvedCard cvc={log} handleOpenSnackbar={handleOpenSnackbar} />
                    ) : (
                      <ResolvedCard cvc={log} />
                    )}
                  </Fragment>
                ))
              )}
            </Grid>
          </InfiniteScroll>
        </div>
      ) : (
        <div className={styles.logsPlaceholderWrapper}>
          <NoLogs />
          <div>
            <Typography variant="h2-bold" className={styles.boldTitle}>
              No logs yet
            </Typography>
          </div>
        </div>
      )}

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={isSnackbarOpen}
        autoHideDuration={7000}
        onClose={handleCloseSnackbar}
      >
        <div>
          <Alert
            onClose={handleCloseSnackbar}
            severity="success"
            icon={<CheckCircleOutlineIcon fontSize="medium" />}
            action={
              <div className={styles.alertActions}>
                <Button onClick={handleNavigateToResolved} className={styles.navigateToResolved} size="large">
                  Open
                </Button>
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
            }
          >
            <AlertTitle className={styles.alertTitle}>Camera has been resolved</AlertTitle>
            To view all resolved cameras, please navigate to the
            <br />
            'Resolved cameras' section on the screen
          </Alert>
        </div>
      </Snackbar>
    </div>
  )
}
export default CVC
