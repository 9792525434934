import { FC, useState, Fragment } from "react"

import TrendingDownIcon from "@mui/icons-material/TrendingDown"
import TrendingUpIcon from "@mui/icons-material/TrendingUp"
import { Grid, SvgIconProps, Box, Card } from "@mui/material"
import { Skeleton } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"

import { Typography } from "@synapse-analytics/synapse-ui"
import moment from "moment"
import { SwiperSlide } from "swiper/react"

import NoInsights from "../assets/noInsights.svg?react"
import { definitions } from "../types/Generated/apiTypes"
import SwiperContainer from "./SwiperContainer"

import styles from "./Insights.module.scss"

type InsightsData = definitions["CounterInsights"]
type FormattedInsight = InsightsData & {
  icon?: SvgIconProps
  variation?: string
}

interface Props {
  data?: any
  loading: boolean
}

const Insights: FC<Props> = ({ data, loading }) => {
  const [hoveredInsight, setHoveredInsight] = useState<number | null>()
  const mediumScreen = useMediaQuery("(max-width:900px)")
  const largeScreen = useMediaQuery("(max-width:1600px)")
  const xLargeScreen = useMediaQuery("(min-width:1900px)")

  // loading skeletons
  let loadingPlaceholders = new Array(xLargeScreen ? 4 : largeScreen ? 3 : mediumScreen ? 1 : 2)
    .fill(null)
    .map((_r, i) => (
      <Grid item xl={3} lg={4} md={6} sm={12} key={i}>
        <Skeleton variant="rectangular" height={80} width="100%" />
      </Grid>
    ))

  // swiper breakpoints
  const breakpoints = {
    1900: {
      slidesPerView: 4,
    },
    1600: {
      slidesPerView: 3,
    },
    900: {
      slidesPerView: 2,
    },
    0: {
      slidesPerView: 1,
    },
  }

  return (
    <Fragment>
      <div className={styles.header}>
        <Typography variant="a" variantColor={2}>
          Insights
        </Typography>
      </div>

      <div
        style={{
          position: "relative",
          height: data && data?.length > 0 ? 92 : undefined,
          zIndex: 999,
        }}
      >
        {loading ? (
          <Grid container spacing={1.5}>
            {loadingPlaceholders}
          </Grid>
        ) : data && data?.length > 0 ? (
          <SwiperContainer slidesPerView={4} spaceBetweenSlides={12} breakpoints={breakpoints}>
            {data?.map((insight: FormattedInsight, i: number) => (
              <SwiperSlide key={i}>
                <div style={{ position: "relative", zIndex: 999 }}>
                  <Card
                    elevation={0}
                    className={styles.contentWrapper}
                    style={{
                      marginRight:
                        data.length <= (xLargeScreen ? 4 : mediumScreen ? 1 : 3) && i === data.length - 1 ? 0 : "",
                    }}
                    onMouseEnter={() => setHoveredInsight(insight.id)}
                    onMouseLeave={() => setHoveredInsight(null)}
                  >
                    <Box sx={{ position: "relative", display: { xs: "none", sm: "block" } }}>
                      <>
                        {insight?.icon}
                        <div
                          className={styles.variationCircle}
                          style={{
                            background:
                              insight.variation === "increase"
                                ? "var(--positive-background-default)"
                                : insight.variation === "decrease"
                                ? "var(--red-background-1)"
                                : "var(--warning-background-default)",
                          }}
                        >
                          {insight.variation === "increase" ? (
                            <TrendingUpIcon />
                          ) : insight.variation === "decrease" ? (
                            <TrendingDownIcon />
                          ) : (
                            <Typography variant="h1-bold">±</Typography>
                          )}
                        </div>
                      </>
                    </Box>

                    <div className={styles.content}>
                      <div className={styles.headTitle}>
                        <Typography variant="a" noWrap gutterBottom>
                          {/* title */}
                          {insight?.description}
                        </Typography>
                        <Typography variant="a" variantColor={2} className={styles.creationDate} noWrap>
                          {moment(insight.created_at).format("DD/MM/YY")}
                        </Typography>
                      </div>

                      <Typography variant="p" gutterBottom noWrap={hoveredInsight === insight.id ? false : true}>
                        {/* insight text (description) */}
                        {insight?.text.length > 30 && hoveredInsight !== insight.id
                          ? insight?.text.slice(0, 30) + "..."
                          : insight?.text}
                      </Typography>
                    </div>
                  </Card>
                </div>
              </SwiperSlide>
            ))}
          </SwiperContainer>
        ) : (
          <div className={styles.noInsights}>
            <NoInsights />
            <Typography variant="a" variantColor={2}>
              No insights yet
            </Typography>
          </div>
        )}
      </div>
    </Fragment>
  )
}

export default Insights
